import { Flex, Button, Divider, rem, createStyles, Text, Modal } from "@mantine/core";
import { useRequest } from "ahooks";
import { useState } from "react";
import { NOTIFICATION_SERVICE } from "../../../components/notifications/NotificationService";
import { useStore } from "../../../hooks/use-store";
import { Widget } from "@uploadcare/react-widget";
import ImageObserved from "./Image";

const useStyles = createStyles((theme) => ({
    main: {
        "> div > button": {
            fontSize: '13px !important',
            textAlign: 'center',
            width: '100%',
            textDecoration: 'none',
            fontWeight: '600 !important',
            height: '2.25rem',
            paddingLeft: '1.125rem',
            paddingRight: '1.125rem',
            borderRadius: '0.25rem',
            border: '0.0625rem solid #ced4da',
            backgroundColor: '#fff !important',
            fontFamily: 'Mulish',
        }
    },
}));
export const PromptGeneratorButton = (model: {
    image: string,
    images?: {
        url: string;
        preview: string;
    }[],
    onSetPrompt: (prompt: string) => void,
}) => {

    const { classes } = useStyles();

    const [modalOpened, setModalOpened] = useState<boolean>(false)
    const [localImage, setLocalImage] = useState<string | null>(null)
    const [selectedImage, setSelectedImage] = useState<string>('')
    const {
        dataStore: { appStore },
    } = useStore();

    const { loading, runAsync: runAsyncVision } = useRequest(
        async (model: { url: string }) => appStore.vision({
            url: model.url,
        }),
        {
            manual: true,
            onSuccess: (res) => {
                if (res.error) {
                    NOTIFICATION_SERVICE.errorNotification(
                        "Sorry, service is unavailable due to high demand. Please try later"
                    );
                } else {
                    return res.url
                }
            },
        }
    );

    // const { loading: runAsyncConvertLoading, runAsync: runAsyncConvert } = useRequest(
    //     async (model: { url: string, removeBackground?: boolean }) => appStore.convertImage(model),
    //     {
    //         manual: true,
    //         onSuccess: (res) => {
    //             if (res.error) {
    //                 NOTIFICATION_SERVICE.errorNotification(
    //                     "Sorry, service is unavailable due to high demand. Please try later"
    //                 );
    //             } else {
    //                 return res.url
    //             }
    //         },
    //     }
    // );

    const doLoad = async (image: string) => {
        setModalOpened(false)
        const visionRes = await runAsyncVision({ url: image })

        if (visionRes?.prompt) {
            setSelectedImage('')
            model.onSetPrompt(visionRes.prompt)
        } else {
            NOTIFICATION_SERVICE.errorNotification(
                "Sorry, service is unavailable due to high demand. Please try later"
            );
        }
    }

    return (
        <Flex direction={'column'}>
            <Modal opened={modalOpened} onClose={() => setModalOpened(false)} title="Generated prompt from image"
            size={'auto'} styles={{
                content: {
                    minWidth: '800px'
                }
            }}
            >
                <Flex w={'100%'} gap={4} wrap={'wrap'} justify={'center'}>

                    {
                        model.images ? model.images.map(itm => (
                            <Flex key={itm.url} justify={'center'} direction={'column'} align={'center'} w={'32%'}
                                style={{
                                    border: selectedImage === itm.url ? '2px solid #f53c02' : '2px solid transparent'
                                }}
                                onClick={() => {
                                    setSelectedImage(itm.url)
                                }}

                            >
                                <ImageObserved
                                    width={'100%'}
                                    height={'200px'}
                                    dark
                                    src={itm.preview}
                                />
                            </Flex>
                        )) : null
                    }
                    <Button
                        disabled={!selectedImage}
                        mt={12}
                        size="lg"
                        style={{
                            border: '1px solid black',

                            fontSize: '14px',
                        }}
                        color="rgba(255, 255, 255, 1)"
                        variant={'outline'}
                        c={'black'}
                        onClick={() => {
                            doLoad(selectedImage)
                        }}>
                        Choose the image
                    </Button>

                </Flex>

            </Modal>
            <Button
                variant="default"
                size={'sm'}
                onClick={async () => {
                    if (model.images) {
                        setModalOpened(true)
                    } else {
                        const visionRes = await runAsyncVision({ url: model.image })

                        if (visionRes?.prompt) {
                            model.onSetPrompt(visionRes.prompt)
                        } else {
                            NOTIFICATION_SERVICE.errorNotification(
                                "Sorry, service is unavailable due to high demand. Please try later"
                            );
                        }
                    }

                }}
                loading={loading}
            >
                Generate prompt from the original
            </Button>
            <Divider my="xs" label="or" labelPosition="center" />
            {
                !localImage ? (
                    <Flex w="100%" justify={'center'} className={classes.main}>
                        <Widget
                            imagesOnly
                            localeTranslations={{
                                buttons: {
                                    choose: {
                                        images: {
                                            one: 'Get prompt from local file'
                                        },
                                    },
                                },
                            }}
                            clearable={false}
                            // @ts-ignore
                            onChange={async (info: {
                                crop?: {
                                    width: number
                                    height: number
                                },
                                cdnUrl: string,
                                originalImageInfo: {
                                    width: number
                                    height: number
                                }
                            }) => {

                                // const res = await runAsyncConvert({ url: info.cdnUrl, removeBackground: false })
                                const visionRes = await runAsyncVision({ url: info.cdnUrl })

                                if (visionRes?.prompt) {
                                    model.onSetPrompt(visionRes.prompt)
                                    setLocalImage(info.cdnUrl)
                                } else {
                                    NOTIFICATION_SERVICE.errorNotification(
                                        "Sorry, service is unavailable due to high demand. Please try later"
                                    );
                                }
                            }}
                            tabs={"file camera url"}
                            publicKey="35626f59762b63c4244c"
                            previewStep={false}
                        />
                    </Flex>
                ) : (
                    <Flex
                        h="100px"
                        w={'100%'}
                        justify={'space-between'}
                        align={'center'}
                    >
                        <Flex direction={'column'} justify={'left'} w={'145px'}>
                            <Text fz={12}>Uploaded image</Text>
                            <Button
                                styles={{
                                    root: {
                                        color: '#f53c02',
                                        height: rem(28),
                                        fontSize: '10px',
                                        background: 'transparent !important',
                                        border: 'none',
                                        position: 'relative',
                                    }
                                }}
                                variant="default"
                                onClick={async () => {
                                    setLocalImage(null)
                                }}
                            >
                                Delete
                            </Button>
                        </Flex>

                        <ImageObserved
                            width={'100px'}
                            height={'100px'}
                            src={localImage!}
                        />
                    </Flex>
                )
            }


        </Flex>

    )
}