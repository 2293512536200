import {
    Modal,
    Text,
    Flex,
    Button,
    Image,
    NumberInput
} from '@mantine/core';

import { useStore } from '../../hooks/use-store';
import { observer } from 'mobx-react';
import { NOTIFICATION_SERVICE } from '../../components/notifications/NotificationService';
import image from './creditsteaser.jpg'
import { useState } from 'react';
export const Element = (props: {
    opened: boolean
    close: () => void
}) => {

    const {
        dataStore: { appStore },
    } = useStore();

    const [credits, setCredits] = useState('200')
    // const [boosted, setBoosted] = useState(true)
    // const checks = spaceStore.checkBeforePublish()
    return (
        <Modal
            opened={props.opened}
            styles={{
                // body: {
                //     background: '#f0f2f1'
                // },
                // root: {
                //     background: '#f0f2f1 !important'
                // },
            }} onClose={props.close} title={' '} size={'xl'} padding={20}>
            <Flex
                gap={32}
                m={12}
            >
                <div style={{ width: '60%' }}>
                    <Image bg={'#f0f2f1'} src={image} w={'100%'} style={{ borderRadius: '10px' }}></Image>
                </div>
                <div style={{ width: '40%' }}>
                    <Flex direction={'column'} gap={2}>
                        <Text fz={16}>Buy credits</Text>
                        <Text fz={12}>You can use generated images for any purposes, personal or commercial</Text>
                        <Flex bg={'#f0f2f1'} w={'100%'} style={{ borderRadius: '4px' }} p={12} mt={6} mb={6} direction={'column'} gap={8}>
                            <Text fz={16}>Number of credits</Text>
                            {/* <Text fz={12}>Generation/Upscale/Relight: each cost 10 credits</Text> */}
                            <NumberInput
                                // label="Input label"
                                // description="Input description"
                                // placeholder="Input placeholder"
                                value={+credits}
                                step={100}
                                min={100}
                                onChange={(e) => {
                                    let value = 100
                                    if (e < 100) {
                                        value = 100
                                    } else if (e % 100 !== 0) {
                                        value = Math.floor(e / 100) * 100
                                    } else {
                                        value = e
                                    }
                                    setCredits(value)
                                }}
                            />
                            {/* <TextInput
                                w="100%"

                                size="sm"
                                type={'number'}

                                value={credits}
                                placeholder="100"
                                onChange={(e) => {
                                    setCredits(e.currentTarget.value)
                                }}
                            /> */}
                        </Flex>
                        {/* <Flex bg={'#f0f2f1'} w={'100%'} style={{ borderRadius: '4px' }} p={12} mt={6} mb={6} direction={'column'} gap={8}>
                            <Text  fz={16}>Speed Option</Text>
                            <Text fz={12}>Boosted option gives you 4x faster generation speed instead of regular 60-80 seconds</Text>
                            <Button
                                size="xs"
                                style={{
                                    border: '1px solid black',
                                    color: 'rgba(255, 255, 255, 1)',
                                    background: boosted ? '#f53c02' : 'transparent',
                                    fontSize: '14px',
                                }}
                                color="rgba(255, 255, 255, 1)"
                                variant={boosted ? "default" : 'outline'}
                                onClick={() => {
                                    setBoosted(true)
                                }}
                            >Boosted</Button>
                            <Button
                                size="xs"
                                style={{
                                    border: '1px solid black',
                                    color: 'rgba(255, 255, 255, 1)',
                                    background: !boosted ? '#f53c02' : 'transparent',
                                    fontSize: '14px',
                                }}
                                color="rgba(255, 255, 255, 1)"
                                variant={!boosted ? "default" : 'outline'}
                                onClick={() => {
                                    setBoosted(false)
                                }}
                            >Regular</Button>
                        </Flex> */}
                        <Flex justify={'space-between'}>
                            <Text fz={16}>Subtotal:</Text>
                            <Text fz={16}>€{`${(+credits * 0.1).toFixed(2)}`}</Text>
                        </Flex>
                        <Button
                            mt={20}
                            fullWidth={true}
                            loading={false}
                            styles={{
                                root: {
                                    color: 'background: rgba(230, 230, 230, 1)',
                                    height: '42px',
                                    fontSize: '16px',
                                    background: '#f53c02',
                                    border: 'none',
                                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                }
                            }}
                            variant="default"
                            onClick={async () => {
                                const res = await appStore.getCheckoutLink({
                                    credits: +credits,
                                    boosted: true
                                })

                                console.log(res)
                                if (res.url) {

                                    let a = document.createElement('a');
                                    a.target = '_blank';
                                    a.href = res.url;
                                    a.click();
                                    props.close()
                                } else {
                                    NOTIFICATION_SERVICE.errorNotification('Something went wrong')
                                }
                            }}
                        >
                            Buy
                        </Button>
                    </Flex>
                </div>

            </Flex>


        </Modal >
    )
}

export const BillingModal = observer(Element);