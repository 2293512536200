import { useCallback, useEffect, useState } from "react";
import { DateTime } from "luxon";
let interval
function RelativeTime(model: { time: string }) {

  const [currentTime, setCurrentTime] = useState<string | null>(null)

  const getActualStatus = useCallback(async () => {
    return setCurrentTime(DateTime.fromISO(model.time).toRelative());
  }, [model.time]);

  useEffect(() => {
    // recursive get interval 
    async function recursiveStatusCheck() {
      await getActualStatus();
      interval = setTimeout(recursiveStatusCheck, 10000);
    }

    // Start the recursive status check
    recursiveStatusCheck();
    return () => clearTimeout(interval!);
  }, [getActualStatus])


  return (
    <span>
      {currentTime}
    </span>
    
  )
}

export default RelativeTime