import { Flex, Text, Menu, ActionIcon, Spoiler, Badge, Progress, Button, rem } from "@mantine/core";
import { useStore } from "../../../hooks/use-store";
import { useCallback, useEffect } from "react";
import { useRequest } from "ahooks";
import { PipelineData, PipelineParameters } from "./Pipeline";
// import Lightbox from "yet-another-react-lightbox";
// import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import ImageObserved from "./Image";
import { RateComponent } from "./RateComponent";
const image = '240px'
const bigImage = '440px'

let interval
export const PipelineItem = (model: {
    secretModeEnabled: boolean
    dark: boolean,
    pipeline: PipelineData,
    update: (pipeline: PipelineData) => void,
    create: (pipeline: PipelineParameters) => void,
    onEnlarge: (image: string) => void,
    onUpscale: (image: string) => void,
    pickImage: (image: string) => void,
    makeVariations: (image: string) => void,
    addFeatured: (id: string) => void,
    searchInMaground: (image: string) => void,
    create360: (image: string) => void,
    create360V2: (image: string) => void,
    create360DHR: (image: string) => void,
    create360Blockade: (image: string) => void,
    fixWhiteBalance: (image: string) => void,
    relightGradient: (image: string) => void,
    relightMask: (image: string) => void,
    placeCar: (image: string) => void,
    openBillingModal: () => void,
    openCustomModelVersion: (version: string) => void,
    big?: boolean
}) => {

    const {
        dataStore: { appStore },
    } = useStore();

    const { runAsync } = useRequest(
        async (model: { jobId: string }) =>
            appStore.getPipelineById(model.jobId),
        {
            loadingDelay: 20,
            manual: true,
            onSuccess: (model) => { },
        }
    );

    const getActualStatus = useCallback(async () => {
        if (model.pipeline.status === 'pending' || model.pipeline.status === 'ending') {
            try {
                const res = await runAsync({ jobId: model.pipeline.jobId })
                if (res && (res.status === 'completed' || res.status === 'failed') && model.pipeline.status === 'pending') {
                    model.update({
                        ...model.pipeline,
                        ...res.data,
                        status: res.status,
                    })
                }
            } catch (error) {
                console.log(error)
            }
        }
    }, [model, runAsync]);

    useEffect(() => {
        // recursive get interval 
        async function recursiveStatusCheck() {
            await getActualStatus();
            interval = setTimeout(recursiveStatusCheck, 2000);
        }

        // Start the recursive status check
        recursiveStatusCheck();
        return () => clearTimeout(interval!);
    }, [getActualStatus])


    const renderMenu = (size: string, src: string) => {
        return (
            <Flex
                justify={'space-between'}
                align={'center'}>
                <Text fz={12}>{size ? `Size: ${size}` : ''}</Text>
                <Menu shadow="md">
                    <Menu.Target>
                        <ActionIcon>
                            <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" stroke="#828282" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M1.33301 8.58679V7.41345C1.33301 6.72012 1.89967 6.14679 2.59967 6.14679C3.80634 6.14679 4.29967 5.29345 3.69301 4.24679C3.34634 3.64679 3.55301 2.86679 4.15967 2.52012L5.31301 1.86012C5.83967 1.54679 6.51967 1.73345 6.83301 2.26012L6.90634 2.38679C7.50634 3.43345 8.49301 3.43345 9.09967 2.38679L9.17301 2.26012C9.48634 1.73345 10.1663 1.54679 10.693 1.86012L11.8463 2.52012C12.453 2.86679 12.6597 3.64679 12.313 4.24679C11.7063 5.29345 12.1997 6.14679 13.4063 6.14679C14.0997 6.14679 14.673 6.71345 14.673 7.41345V8.58679C14.673 9.28012 14.1063 9.85345 13.4063 9.85345C12.1997 9.85345 11.7063 10.7068 12.313 11.7535C12.6597 12.3601 12.453 13.1335 11.8463 13.4801L10.693 14.1401C10.1663 14.4535 9.48634 14.2668 9.17301 13.7401L9.09967 13.6135C8.49967 12.5668 7.51301 12.5668 6.90634 13.6135L6.83301 13.7401C6.51967 14.2668 5.83967 14.4535 5.31301 14.1401L4.15967 13.4801C3.55301 13.1335 3.34634 12.3535 3.69301 11.7535C4.29967 10.7068 3.80634 9.85345 2.59967 9.85345C1.89967 9.85345 1.33301 9.28012 1.33301 8.58679Z" stroke="#828282" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Label>Actions</Menu.Label>
                        <Menu.Item icon={
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 6V2H10" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M2 10V14H6" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M14 2L9 7" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7 9L2 14" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        }

                            onClick={() => model.onUpscale(src)}
                        >
                            Upscale
                        </Menu.Item>
                        <Menu.Item icon={
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.33301 6.65325V5.99992C1.33301 2.66659 2.66634 1.33325 5.99967 1.33325H9.99967C13.333 1.33325 14.6663 2.66659 14.6663 5.99992V9.99992C14.6663 13.3333 13.333 14.6666 9.99967 14.6666H9.33301" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.66699 7.33324L12.007 3.98657H9.33366" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12.0068 3.98657V6.65991" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.33301 10.7667V12.5667C7.33301 14.0667 6.73301 14.6667 5.23301 14.6667H3.43301C1.93301 14.6667 1.33301 14.0667 1.33301 12.5667V10.7667C1.33301 9.26675 1.93301 8.66675 3.43301 8.66675H5.23301C6.73301 8.66675 7.33301 9.26675 7.33301 10.7667Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        }

                            onClick={() => model.onEnlarge(src)}
                        >
                            Enlarge
                        </Menu.Item>
                        <Menu.Item
                            component={"a"}
                            href={src}
                            target={"_blank"}
                            download='test.png'
                            icon={
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 7.33325V11.3333L7.33333 9.99992" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M6.00033 11.3333L4.66699 10" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M14.6663 6.66659V9.99992C14.6663 13.3333 13.333 14.6666 9.99967 14.6666H5.99967C2.66634 14.6666 1.33301 13.3333 1.33301 9.99992V5.99992C1.33301 2.66659 2.66634 1.33325 5.99967 1.33325H9.33301" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M14.6663 6.66658H11.9997C9.99967 6.66658 9.33301 5.99992 9.33301 3.99992V1.33325L14.6663 6.66658Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            }
                        >
                            Download
                        </Menu.Item>
                        <Menu.Item
                            icon={
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.99967 14.6666H9.99967C13.333 14.6666 14.6663 13.3333 14.6663 9.99992V5.99992C14.6663 2.66659 13.333 1.33325 9.99967 1.33325H5.99967C2.66634 1.33325 1.33301 2.66659 1.33301 5.99992V9.99992C1.33301 13.3333 2.66634 14.6666 5.99967 14.6666Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M5.16699 7.99995L7.05366 9.88661L10.8337 6.11328" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            }
                            onClick={() => {
                                model.pickImage(src)
                            }}
                        >
                            Pick image
                        </Menu.Item>       
                        <Menu.Item
                            icon={
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.99967 14.6666H9.99967C13.333 14.6666 14.6663 13.3333 14.6663 9.99992V5.99992C14.6663 2.66659 13.333 1.33325 9.99967 1.33325H5.99967C2.66634 1.33325 1.33301 2.66659 1.33301 5.99992V9.99992C1.33301 13.3333 2.66634 14.6666 5.99967 14.6666Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M5.16699 7.99995L7.05366 9.88661L10.8337 6.11328" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            }
                            onClick={() => {
                                model.makeVariations(src)
                            }}
                        >
                            Make variations
                        </Menu.Item>

                        <Menu.Item
                            icon={
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.99967 14.6666H9.99967C13.333 14.6666 14.6663 13.3333 14.6663 9.99992V5.99992C14.6663 2.66659 13.333 1.33325 9.99967 1.33325H5.99967C2.66634 1.33325 1.33301 2.66659 1.33301 5.99992V9.99992C1.33301 13.3333 2.66634 14.6666 5.99967 14.6666Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M5.16699 7.99995L7.05366 9.88661L10.8337 6.11328" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            }
                            onClick={() => {
                                model.searchInMaground(src)
                            }}
                        >
                            Find similar photography on maground.com
                        </Menu.Item>
                        <Menu.Item
                            icon={
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.6468 7.99994C14.6468 9.95994 13.7935 11.7333 12.4335 12.9399C11.2602 14.0066 9.70685 14.6466 8.00018 14.6466C4.33352 14.6466 1.35352 11.6666 1.35352 7.99994C1.35352 4.33327 4.33352 1.35327 8.00018 1.35327C9.70685 1.35327 11.2602 1.99327 12.4335 3.05994C13.7935 4.2666 14.6468 6.03994 14.6468 7.99994Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M7.97986 10.9267C7.96652 12.0267 7.14652 12.2933 6.37986 11.9867C4.79986 11.3533 3.68652 9.80666 3.68652 8C3.68652 6.19333 4.79986 4.64666 6.37986 4.00666C7.14652 3.7 7.96652 3.97333 7.97986 5.06666V10.9267Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            }
                            onClick={() => {
                                model.placeCar(src)
                            }}
                        >
                            Place your car
                        </Menu.Item>
                        {
                            model.secretModeEnabled && (
                                <Menu.Item
                                    icon={
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.99967 14.6666H9.99967C13.333 14.6666 14.6663 13.3333 14.6663 9.99992V5.99992C14.6663 2.66659 13.333 1.33325 9.99967 1.33325H5.99967C2.66634 1.33325 1.33301 2.66659 1.33301 5.99992V9.99992C1.33301 13.3333 2.66634 14.6666 5.99967 14.6666Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5.16699 7.99995L7.05366 9.88661L10.8337 6.11328" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    }
                                    onClick={() => {
                                        model.create360(src)
                                    }}
                                >
                                    [beta] Create 360 image-to-image
                                </Menu.Item>
                            )
                        }
                        {
                            model.secretModeEnabled && (
                                <Menu.Item
                                    icon={
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.99967 14.6666H9.99967C13.333 14.6666 14.6663 13.3333 14.6663 9.99992V5.99992C14.6663 2.66659 13.333 1.33325 9.99967 1.33325H5.99967C2.66634 1.33325 1.33301 2.66659 1.33301 5.99992V9.99992C1.33301 13.3333 2.66634 14.6666 5.99967 14.6666Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5.16699 7.99995L7.05366 9.88661L10.8337 6.11328" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    }
                                    onClick={() => {
                                        model.create360V2(src)
                                    }}
                                >
                                    [beta] Create 360 text-to-image
                                </Menu.Item>
                            )
                        }
                        {
                            model.secretModeEnabled && (
                                <Menu.Item
                                    icon={
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.99967 14.6666H9.99967C13.333 14.6666 14.6663 13.3333 14.6663 9.99992V5.99992C14.6663 2.66659 13.333 1.33325 9.99967 1.33325H5.99967C2.66634 1.33325 1.33301 2.66659 1.33301 5.99992V9.99992C1.33301 13.3333 2.66634 14.6666 5.99967 14.6666Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5.16699 7.99995L7.05366 9.88661L10.8337 6.11328" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    }
                                    onClick={() => {
                                        model.create360DHR(src)
                                    }}
                                >
                                    [beta] Create HDRi depix
                                </Menu.Item>
                            )
                        }
                        {
                            model.secretModeEnabled && (
                                <Menu.Item
                                    icon={
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.99967 14.6666H9.99967C13.333 14.6666 14.6663 13.3333 14.6663 9.99992V5.99992C14.6663 2.66659 13.333 1.33325 9.99967 1.33325H5.99967C2.66634 1.33325 1.33301 2.66659 1.33301 5.99992V9.99992C1.33301 13.3333 2.66634 14.6666 5.99967 14.6666Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5.16699 7.99995L7.05366 9.88661L10.8337 6.11328" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    }
                                    onClick={() => {
                                        model.create360Blockade(src)
                                    }}
                                >
                                    [beta] Create HDRi blockade
                                </Menu.Item>
                            )
                        }
                        {/* {
                            model.secretModeEnabled && (
                                <Menu.Item
                                    icon={
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.99967 14.6666H9.99967C13.333 14.6666 14.6663 13.3333 14.6663 9.99992V5.99992C14.6663 2.66659 13.333 1.33325 9.99967 1.33325H5.99967C2.66634 1.33325 1.33301 2.66659 1.33301 5.99992V9.99992C1.33301 13.3333 2.66634 14.6666 5.99967 14.6666Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5.16699 7.99995L7.05366 9.88661L10.8337 6.11328" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    }
                                    onClick={() => {
                                        model.fixWhiteBalance(src)
                                    }}
                                >
                                    [beta] Fix white balance
                                </Menu.Item>
                            )
                        } */}

                    </Menu.Dropdown>
                </Menu>
            </Flex>
        )

    }
    return (
        <Flex
            pb={30}
            pt={10}
            style={{ borderRadius: '8px', overflow: 'scroll' }}
            bg={'#ffffff'}
            pl={'0.8rem'}
            pr={'0.8rem'}
            direction={'column'}
            w={'100%'}
            miw={'100%'}>
            {
                model.pipeline.status === 'pending' || model.pipeline.status === 'ending' ?
                    <Flex h="468px" align={'center'}>
                        <Flex gap={12} direction={'column'}>
                            <Text>Generation {model.pipeline.jobId} is running </Text>
                            <Progress style={{ width: '300px' }} radius="lg" size="lg" color="#f53c02" value={100} striped animate={true} w={'100%'} />
                        </Flex>
                    </Flex> : null
            }
            {
                model.pipeline.status === 'failed' ?
                    <Flex pt={20} pb={0} align={'center'}>
                        <Flex gap={12} direction={'column'}>
                            <Text>Generation {model.pipeline.jobId} failed to run. Credits for this generation will be refunded. </Text>
                        </Flex>
                    </Flex> : null
            }
            {
                model.pipeline.status === 'completed' ? (
                    <Flex direction={'column'}>
                        {
                            model.pipeline.params.type === 'customModelV1' ? (
                                <>
                                    <Flex align={'center'} gap={12} mb={6}>
                                        <Text fz={20} >GENERATION: {model.pipeline.jobId} </Text>
                                        <Badge
                                            style={{
                                                color: "#e0e0e0"
                                            }}
                                            size="lg"
                                            radius="sm"
                                            bg="#464646"
                                        >Custom Model {model.pipeline.params.modelName}</Badge>
                                    </Flex>

                                    <Flex>

                                        <Flex direction={'column'} miw={'300px'} w={'300px'} mr={20} gap={6}>
                                            <Spoiler maxHeight={120} showLabel="Show more" hideLabel="Hide">
                                                <Text fz={12} style={{ color: 'BDBDBD' }}>Prompt:</Text>
                                                <Text fz={12} >
                                                    {model.pipeline.params.prompt}
                                                </Text>

                                            </Spoiler>

                                            {
                                                model.pipeline.params.modelVersion && (
                                                    <Button
                                                        variant="default"
                                                        size={'xs'}
                                                        onClick={async () => {
                                                            model.openCustomModelVersion(model.pipeline.params.modelVersion)
                                                        }}
                                                    >
                                                        Generate from this model
                                                    </Button>
                                                )
                                            }

                                            <RateComponent
                                                generationId={model.pipeline.jobId}
                                                srcImage={model.pipeline.params.srcset[0]}
                                            />
                                            <Text fz={12} style={{ color: 'BDBDBD' }}>Credits spent:</Text>
                                            <Flex gap={6} align={'center'}>
                                                <Text fz={12}  >
                                                    {model.pipeline.params.credits}
                                                </Text>
                                                <Badge
                                                    size="xs"
                                                    variant="gradient"
                                                    radius="xs"
                                                    p={'1'}
                                                    onClick={() => model.openBillingModal()}
                                                    gradient={{ from: 'yellow', to: 'orange', deg: 258 }}
                                                >
                                                    BUY CREDITS
                                                </Badge>
                                            </Flex>
                                            {
                                                model.secretModeEnabled && (
                                                    <Text fz={10} color="red" onClick={() => {
                                                        model.addFeatured(model.pipeline.jobId)
                                                    }}>[beta] Add to featured </Text>
                                                )
                                            }
                                        </Flex>
                                        <Flex
                                            gap={20}
                                            direction={model.big ? "column" : undefined}
                                            w={model.big ? '100%' : undefined}
                                        >
                                            {model.pipeline.params.srcset.map((src, index) => (
                                                <Flex direction={'column'}>
                                                    {renderMenu('', src)}
                                                    <ImageObserved
                                                        width={model.big ? '100%' : bigImage}
                                                        height={model.big ? '100%' : bigImage}
                                                        dark={true}
                                                        src={src}
                                                    />
                                                </Flex>
                                            ))}
                                        </Flex>
                                    </Flex>
                                </>
                            ) : null
                        }
                        {
                            model.pipeline.params.type === 'img2img' || model.pipeline.params.type === 'img2imgflux' || model.pipeline.params.type === 'fluxControlnet' || model.pipeline.params.type === 'customModel360V1' || model.pipeline.params.type === 'customModelV2' || model.pipeline.params.type === 'fixWhiteBalance' ? (
                                <>
                                    <Flex align={'center'} gap={12} mb={6}>
                                        <Text fz={20} >GENERATION: {model.pipeline.jobId} </Text>
                                        <Badge
                                            style={{
                                                color: "#e0e0e0"
                                            }}
                                            size="lg"
                                            radius="sm"
                                            bg="#464646"
                                        >Generation</Badge>
                                    </Flex>

                                    <Flex>

                                        <Flex direction={'column'} miw={'300px'} w={'300px'} mr={20} gap={6}>
                                            <Flex direction={'column'} gap={8}>
                                                <Text fz={12} style={{ color: 'BDBDBD' }}>Reference image:</Text>
                                                <ImageObserved
                                                    dark
                                                    width={image}
                                                    height={image}
                                                    src={model.pipeline.params.inputSrc}
                                                />
                                            </Flex>
                                            <Spoiler maxHeight={120} showLabel="Show more" hideLabel="Hide">
                                                <Text fz={12} style={{ color: 'BDBDBD' }}>Prompt:</Text>
                                                <Text fz={12} >
                                                    {model.pipeline.params.prompt}
                                                </Text>

                                            </Spoiler>

                                            <Spoiler maxHeight={120} showLabel="Show more" hideLabel="Hide">
                                                <Text fz={12} style={{ color: 'BDBDBD' }}>Negative prompt:</Text>
                                                <Text fz={12} >
                                                    {model.pipeline.params.negativePrompt}
                                                </Text>
                                            </Spoiler>



                                            <RateComponent
                                                generationId={model.pipeline.jobId}
                                                srcImage={model.pipeline.params.srcset[0]}
                                            />
                                            <Text fz={12} style={{ color: 'BDBDBD' }}>Credits spent:</Text>
                                            <Flex gap={6} align={'center'}>
                                                <Text fz={12}  >
                                                    {model.pipeline.params.credits}
                                                </Text>
                                                <Badge
                                                    size="xs"
                                                    variant="gradient"
                                                    radius="xs"
                                                    p={'1'}
                                                    onClick={() => model.openBillingModal()}
                                                    gradient={{ from: 'yellow', to: 'orange', deg: 258 }}
                                                >
                                                    BUY CREDITS
                                                </Badge>
                                            </Flex>
                                            {
                                                model.secretModeEnabled && (
                                                    <Text fz={10} color="red" onClick={() => {
                                                        model.addFeatured(model.pipeline.jobId)
                                                    }}>[beta] Add to featured </Text>
                                                )
                                            }
                                        </Flex>
                                        <Flex
                                            gap={20}
                                            direction={model.big ? "column" : undefined}
                                            w={model.big ? '100%' : undefined}
                                        >
                                            {model.pipeline.params.srcset.map((src, index) => (
                                                <Flex direction={'column'}>
                                                    {renderMenu('', src)}
                                                    <ImageObserved
                                                        width={model.big ? '100%' : bigImage}
                                                        height={model.big ? '100%' : bigImage}
                                                        dark={true}
                                                        src={src}
                                                    />
                                                </Flex>
                                            ))}
                                        </Flex>
                                    </Flex>
                                </>
                            ) : null
                        }
                        {
                            model.pipeline.params.type === 'customModel360HDRV2' || model.pipeline.params.type === 'customModel360HDRV3' ? (
                                <>
                                    <Flex align={'center'} gap={12} mb={6}>
                                        <Text fz={20}>GENERATION: {model.pipeline.jobId} </Text>
                                        <Badge
                                            style={{
                                                color: "#e0e0e0"
                                            }}
                                            size="lg"
                                            radius="sm"
                                            bg="#464646"
                                        >HDRI</Badge>
                                    </Flex>

                                    <Flex>

                                        <Flex direction={'column'} miw={'300px'} w={'300px'} mr={20} gap={6}>
                                            <Spoiler maxHeight={120} showLabel="Show more" hideLabel="Hide">
                                                <Text fz={12} style={{ color: 'BDBDBD' }}>Prompt:</Text>
                                                <Text fz={12} >
                                                    {model.pipeline.params.prompt}
                                                </Text>

                                            </Spoiler>
                                            <RateComponent
                                                generationId={model.pipeline.jobId}
                                                srcImage={model.pipeline.params.srcset[0]}
                                            />
                                            <Text fz={12} style={{ color: 'BDBDBD' }}>Credits spent:</Text>
                                            <Flex gap={6} align={'center'}>
                                                <Text fz={12}  >
                                                    {model.pipeline.params.credits}
                                                </Text>
                                                <Badge
                                                    size="xs"
                                                    variant="gradient"
                                                    radius="xs"
                                                    p={'1'}
                                                    onClick={() => model.openBillingModal()}
                                                    gradient={{ from: 'yellow', to: 'orange', deg: 258 }}
                                                >
                                                    BUY CREDITS
                                                </Badge>
                                            </Flex>
                                        </Flex>
                                        <Flex
                                            gap={20}
                                            direction={model.big ? "column" : undefined}
                                            w={model.big ? '100%' : undefined}
                                        >
                                            {model.pipeline.params.srcset.map((src, index) => (
                                                <Flex direction={'column'} key={src}>
                                                    <Flex
                                                        p={"xs"}
                                                        bg={"#f0f2f1"}
                                                        align={'center'}
                                                        justify={'center'}
                                                        h={'400px'}

                                                        style={{
                                                            borderRadius: '4px'
                                                        }}
                                                        gap={12}
                                                        w="400px"
                                                    // w="max-content"
                                                    >
                                                        <Text>HDRi ready</Text>
                                                        {' '}
                                                        <Button
                                                            styles={{
                                                                root: {
                                                                    color: 'rgba(230, 230, 230, 1)',
                                                                    height: rem(36),

                                                                    fontSize: '14px',
                                                                    background: '#f53c02 !important',
                                                                    border: 'none',
                                                                    position: 'relative',
                                                                }
                                                            }}
                                                            variant="default"
                                                            component={"a"}
                                                            href={src}
                                                            target={"_blank"}
                                                            download='test.hdr'
                                                        // icon={
                                                        //     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        //         <path d="M6 7.33325V11.3333L7.33333 9.99992" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                                        //         <path d="M6.00033 11.3333L4.66699 10" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                                        //         <path d="M14.6663 6.66659V9.99992C14.6663 13.3333 13.333 14.6666 9.99967 14.6666H5.99967C2.66634 14.6666 1.33301 13.3333 1.33301 9.99992V5.99992C1.33301 2.66659 2.66634 1.33325 5.99967 1.33325H9.33301" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                                        //         <path d="M14.6663 6.66658H11.9997C9.99967 6.66658 9.33301 5.99992 9.33301 3.99992V1.33325L14.6663 6.66658Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                                        //     </svg>
                                                        // }
                                                        >
                                                            Download
                                                        </Button>
                                                    </Flex>

                                                </Flex>
                                            ))}
                                        </Flex>
                                    </Flex>
                                </>
                            ) : null
                        }
                        {
                            model.pipeline.params.type === 'changeBackground2' || model.pipeline.params.type === 'changeBackground3' ? (
                                <>
                                    <Flex align={'center'} gap={12} mb={6}>
                                        <Text fz={20} >GENERATION: {model.pipeline.jobId} </Text>
                                        <Badge
                                            style={{
                                                color: "#e0e0e0"
                                            }}
                                            size="lg"
                                            radius="sm"
                                            bg="#464646"
                                        >Generation</Badge>
                                    </Flex>

                                    <Flex>

                                        <Flex direction={'column'} miw={'300px'} w={'300px'} mr={20} gap={6}>
                                            <Flex direction={'column'} gap={8}>
                                                <Text fz={12} style={{ color: 'BDBDBD' }}>Reference image:</Text>
                                                <ImageObserved
                                                    dark
                                                    width={image}
                                                    height={image}
                                                    src={model.pipeline.params.inputSrc}
                                                />
                                            </Flex>
                                            <Flex direction={'column'} gap={8}>
                                                <Text fz={12} style={{ color: 'BDBDBD' }}>Background image:</Text>
                                                <ImageObserved
                                                    dark
                                                    width={image}
                                                    height={image}
                                                    src={model.pipeline.params.backgroundSrc}
                                                />
                                            </Flex>
                                            <Spoiler maxHeight={120} showLabel="Show more" hideLabel="Hide">
                                                <Text fz={12} style={{ color: 'BDBDBD' }}>Prompt:</Text>
                                                <Text fz={12} >
                                                    {model.pipeline.params.prompt}
                                                </Text>

                                            </Spoiler>

                                            <Spoiler maxHeight={120} showLabel="Show more" hideLabel="Hide">
                                                <Text fz={12} style={{ color: 'BDBDBD' }}>Negative prompt:</Text>
                                                <Text fz={12} >
                                                    {model.pipeline.params.negativePrompt}
                                                </Text>
                                            </Spoiler>

                                            <RateComponent
                                                generationId={model.pipeline.jobId}
                                                srcImage={model.pipeline.params.srcset[0]}
                                            />
                                            <Text fz={12} style={{ color: 'BDBDBD' }}>Credits spent:</Text>
                                            <Flex gap={6} align={'center'}>

                                                <Text fz={12}  >
                                                    {model.pipeline.params.credits}
                                                </Text>
                                                <Badge
                                                    size="xs"
                                                    variant="gradient"
                                                    radius="xs"
                                                    p={'1'}
                                                    onClick={() => model.openBillingModal()}
                                                    gradient={{ from: 'yellow', to: 'orange', deg: 258 }}
                                                >
                                                    BUY CREDITS
                                                </Badge>
                                            </Flex>

                                        </Flex>
                                        <Flex gap={20}
                                            direction={model.big ? "column" : undefined}
                                            w={model.big ? '100%' : undefined}
                                        >
                                            {model.pipeline.params.srcset.map((src, index) => (
                                                <Flex direction={'column'}>
                                                    {renderMenu('', src)}
                                                    <ImageObserved
                                                        width={model.big ? '100%' : bigImage}
                                                        height={model.big ? '100%' : bigImage}
                                                        dark={true}
                                                        src={src}
                                                    />
                                                </Flex>
                                            ))}
                                        </Flex>
                                    </Flex>
                                </>
                            ) : null
                        }
                        {
                            model.pipeline.params.type === 'relightMask' ? (
                                <>
                                    <Flex align={'center'} gap={12} mb={6}>
                                        <Text fz={20} >GENERATION: {model.pipeline.jobId} </Text>
                                        <Badge
                                            style={{
                                                color: "#e0e0e0"
                                            }}
                                            size="lg"
                                            radius="sm"
                                            bg="#464646"
                                        >Relight</Badge>
                                    </Flex>

                                    <Flex>

                                        <Flex direction={'column'} miw={'300px'} w={'300px'} mr={20} gap={6}>
                                            <Flex direction={'column'} gap={8}>
                                                <Text fz={12} style={{ color: 'BDBDBD' }}>Reference image:</Text>
                                                <ImageObserved
                                                    dark
                                                    width={image}
                                                    height={image}
                                                    src={model.pipeline.params.inputSrc}
                                                />
                                            </Flex>
                                            <Spoiler maxHeight={120} showLabel="Show more" hideLabel="Hide">
                                                <Text fz={12} style={{ color: 'BDBDBD' }}>Prompt:</Text>
                                                <Text fz={12} >
                                                    {model.pipeline.params.prompt}
                                                </Text>

                                            </Spoiler>

                                            <Spoiler maxHeight={120} showLabel="Show more" hideLabel="Hide">
                                                <Text fz={12} style={{ color: 'BDBDBD' }}>Negative prompt:</Text>
                                                <Text fz={12} >
                                                    {model.pipeline.params.negativePrompt}
                                                </Text>
                                            </Spoiler>

                                            <RateComponent
                                                generationId={model.pipeline.jobId}
                                                srcImage={model.pipeline.params.srcset[0]}
                                            />
                                            <Text fz={12} style={{ color: 'BDBDBD' }}>Credits spent:</Text>
                                            <Flex gap={6} align={'center'}>

                                                <Text fz={12}  >
                                                    {model.pipeline.params.credits}
                                                </Text>
                                                <Badge
                                                    size="xs"
                                                    variant="gradient"
                                                    radius="xs"
                                                    p={'1'}
                                                    onClick={() => model.openBillingModal()}
                                                    gradient={{ from: 'yellow', to: 'orange', deg: 258 }}
                                                >
                                                    BUY CREDITS
                                                </Badge>
                                            </Flex>

                                        </Flex>
                                        <Flex gap={20}
                                            direction={model.big ? "column" : undefined}
                                            w={model.big ? '100%' : undefined}
                                        >

                                            {model.pipeline.params.srcset.map((src, index) => (
                                                <Flex direction={'column'}>
                                                    {renderMenu('', src)}
                                                    <ImageObserved
                                                        width={model.big ? '100%' : bigImage}
                                                        height={model.big ? '100%' : bigImage}
                                                        dark={true}
                                                        src={src}
                                                    />
                                                </Flex>
                                            ))}
                                        </Flex>
                                    </Flex>
                                </>
                            ) : null
                        }
                        {
                            model.pipeline.params.type === 'enlarge' ? (
                                <>
                                    <Flex align={'center'} gap={12} mb={6}>
                                        <Text fz={20} >GENERATION: {model.pipeline.jobId} </Text>
                                        <Badge
                                            style={{
                                                color: "#e0e0e0"
                                            }}
                                            size="lg"
                                            radius="sm"
                                            bg="#464646"
                                        >Enlarging</Badge>
                                    </Flex>

                                    <Flex>

                                        <Flex direction={'column'} w={'300px'} miw={'300px'} mr={20} gap={6}>
                                            <Flex direction={'column'} gap={8}>
                                                <Text fz={12} style={{ color: 'BDBDBD' }}>Reference image:</Text>
                                                <ImageObserved
                                                    dark
                                                    width={image}
                                                    height={image}
                                                    src={model.pipeline.params.inputSrc}
                                                />
                                            </Flex>

                                            <RateComponent
                                                generationId={model.pipeline.jobId}
                                                srcImage={model.pipeline.params.outputSrc}
                                            />
                                            <Text fz={12} style={{ color: 'BDBDBD' }}>Credits spent:</Text>
                                            <Flex gap={6} align={'center'}>

                                                <Text fz={12}  >
                                                    {model.pipeline.params.credits}
                                                </Text>
                                                <Badge
                                                    size="xs"
                                                    variant="gradient"
                                                    radius="xs"
                                                    p={'1'}
                                                    onClick={() => model.openBillingModal()}
                                                    gradient={{ from: 'yellow', to: 'orange', deg: 258 }}
                                                >
                                                    BUY CREDITS
                                                </Badge>
                                            </Flex>


                                        </Flex>
                                        <Flex gap={20}>

                                            <Flex direction={'column'}>
                                                {renderMenu('', model.pipeline.params.outputSrc)}
                                                <ImageObserved
                                                    width={model.big ? '100%' : bigImage}
                                                    height={model.big ? '100%' : bigImage}
                                                    dark={true}
                                                    src={model.pipeline.params.outputSrc}
                                                />
                                            </Flex>

                                        </Flex>
                                    </Flex>
                                </>
                            ) : null
                        }

                        {
                            model.pipeline.params.type === 'upscale' || model.pipeline.params.type === 'upscaleV1' ? (
                                <>
                                    <Flex align={'center'} gap={12} mb={6}>
                                        <Text fz={20} >GENERATION: {model.pipeline.jobId} </Text>
                                        <Badge
                                            style={{
                                                color: "#e0e0e0"
                                            }}
                                            size="lg"
                                            radius="sm"
                                            bg="#464646"
                                        >Upscaling</Badge>
                                    </Flex>

                                    <Flex>

                                        <Flex direction={'column'} w={'300px'} miw={'300px'} mr={20} gap={6}>
                                            <Flex direction={'column'} gap={8}>
                                                <Text fz={12} style={{ color: 'BDBDBD' }}>Reference image:</Text>
                                                <ImageObserved
                                                    dark
                                                    width={image}
                                                    height={image}
                                                    src={model.pipeline.params.inputSrc}
                                                />
                                            </Flex>

                                            <RateComponent
                                                generationId={model.pipeline.jobId}
                                                srcImage={model.pipeline.params.outputSrc}
                                            />
                                            <Text fz={12} style={{ color: 'BDBDBD' }}>Credits spent:</Text>
                                            <Flex gap={6} align={'center'}>
                                                <Text fz={12}  >
                                                    {model.pipeline.params.credits}
                                                </Text>
                                                <Badge
                                                    size="xs"
                                                    variant="gradient"
                                                    radius="xs"
                                                    p={'1'}
                                                    onClick={() => model.openBillingModal()}
                                                    gradient={{ from: 'yellow', to: 'orange', deg: 258 }}
                                                >
                                                    BUY CREDITS
                                                </Badge>
                                            </Flex>


                                        </Flex>
                                        <Flex gap={20}>

                                            <Flex direction={'column'}>
                                                {renderMenu('', model.pipeline.params.outputSrc)}
                                                <ImageObserved
                                                    width={model.big ? '100%' : bigImage}
                                                    height={model.big ? '100%' : bigImage}
                                                    dark={true}
                                                    src={model.pipeline.params.outputSrc}
                                                />
                                            </Flex>

                                        </Flex>
                                    </Flex>
                                </>

                            ) : null
                        }
                    </Flex>
                ) : null
            }
        </Flex>
    );
}