import { makeObservable, observable, toJS } from "mobx";
import { AnimationType, ParralaxEffect } from "../../../features/workspace/Site/helper";

export interface ImageElementProps {
  src: string;
  alt: string | null;
  type: "IMAGE" | "SLIDER" | "VIDEO"
  set: string[];
  sliderDuration: number;
  transitionDuration: number;

  parentId: string | null
  id: string;
  x: number;
  y: number;
  width:  string | number;
  height:  string | number;
  position: 'absolute' | 'fixed',
  zIndex: number;
  opacity: number;
  bgColor: string | null;
  borderRadius: number;
  borderWidth: number | string | null;
  borderColor: string | null;
  boxShadow: string | null;
  objectFit: string;
  animation: AnimationType
  parralax: ParralaxEffect | null
  
  link: string | null;
  name: string | null;
  rotate: number;

  anchor: string | null
}

export default class ImageElement {
  @observable anchor: string | null
  @observable src: string;
  @observable alt: string| null;
  @observable type: "IMAGE" | "SLIDER" | "VIDEO"
  @observable set: string[];
  @observable sliderDuration: number;
  @observable transitionDuration: number;


  @observable parentId: string | null

  
  @observable id: string;
  @observable x: number; 
  @observable y: number;
  @observable width:  string | number;
  @observable height:  string | number;
  @observable position: 'absolute' | 'fixed';
  @observable zIndex: number;
  @observable opacity: number;
  @observable bgColor: string | null;
  @observable borderRadius: number;
  @observable borderWidth: number | string | null;
  @observable borderColor: string | null;
  @observable boxShadow: string | null;
  @observable objectFit: string;
  @observable animation: AnimationType
  @observable parralax: ParralaxEffect | null

  @observable link: string | null;
  @observable name: string | null;
  @observable rotate: number;

  

  constructor(props: ImageElementProps) {
    Object.assign(this, props);
    makeObservable(this);
  }

  toJSON() {
    return toJS(this);
  }
}
