import { observer } from "mobx-react";
import {
  AppShell,
  createStyles,
  rem,
  Header,
  Burger,
  useMantineTheme,
  Flex,
  Text,
  Button,
  Progress,
  LoadingOverlay,
  Loader,
  Textarea,
  Image,
  // Checkbox,
  Select,
  Modal,
  Slider,
  Spoiler,
  ColorInput,
  Accordion,
  Checkbox,
} from "@mantine/core";
import User from "../User";
import { useCallback, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import Logo from "../../../components/Logo";
import { useStore } from "../../../hooks/use-store";
import useIsMobile from "../../../hooks/isMobile";
import { defaultNegative, PipelineData, PipelineParameters } from "./Pipeline";
import { useRequest } from "ahooks";
import { NOTIFICATION_SERVICE } from "../../../components/notifications/NotificationService";
import { PipelineItem } from "./PipelineItem";
import { Steps } from "../../../pages/public/gen/Steps";
import { FilesUpload, Widget } from "@uploadcare/react-widget";
import ImageObserved from "./Image";
import CanvasApp from "./Canvas";
import RelativeTime from "./RelativeTime";
import { BillingModal } from "../BillingModal";
import { Stage, Layer, Rect } from 'react-konva';
import { Rectangle } from "./Resize";
import { transformArray } from "./images";
import { CustomModelLoader } from "./CustomModelLoader";
import { modals } from "@mantine/modals";
import { PromptGeneratorButton } from "./PromptGeneratorButton";
import { GearMenu } from "./GearMenu";
import { getLoraOptons } from "../../../types";

const priceMap = {
  "UPSCALE": 10,
  "CREATE_NEW": 2,
  "PLACE_CAR": 10,
  "OUTPAINT": 8,
  "CUSTOM_MODEL_GEN": 8,
  "VARIATIONS": 8
}
const useStyles = createStyles((theme) => ({
  logo: {
    display: "flex",
    "> svg": {
      height: "24px",
    },
  },

  cardItem: {
    cursor: "pointer",
    // "img": {
    //   objectFit: "cover",
    // },
    // '&:hover': {
    //   '> div': {
    //     display: 'block !important'
    //   }
    // }
  },

  // hiden: {
  //   display: 'none'
  // },
  card: {
    // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    border: '1px solid rgba(70, 70, 70, 1)'
  },

  cardTitle: {
    fontWeight: 700,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1.2,
  },

  body: {
    padding: theme.spacing.md,
    background: '#ffffff',
  },

  wrapper: {
    height: "calc(100vh - 84px)",
    maxWidth: "100%",
    width: "100%",
    padding: '0 1rem',
  },

  title: {
    marginBottom: theme.spacing.sm,
    paddingLeft: theme.spacing.sm,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    // fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  item: {
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
  },

  center: {
    display: "flex",
    justifyContent: "center",
  },
  video: {
    margin: "0 auto",

    "> div": {
      position: "absolute!important" as any,
      transformOrigin: "left top",
      borderRadius: "8px",
      overflow: "hidden",
    },
  },
}));

enum MODE {
  GALLERY,
  SELECT_MODE,
  PIPELINES,
  DEPTH_MAP,
  ENLARGE,
  RELIGHT,
  UPSCALE,
  SELECT_ANGLE,
  CHANGE_BACKGROUND,
  SELECT_ANGLE_VIEWER,
  IMPORT_CAR,
  IMG2IMGFLUX,
  GEN_SIMILAR,
  PICK_IMAGE,
  SELECTED_MODEL,
  ADD_CUSTOM_MODEL,
  IMAGE_360,
  IMAGE_360_DEEPIX,
  IMAGE_360_BLOCKADE,
  ANY_CUSTOM_MODEL,
  FEATURED,
}

const initialRectangles = [
  {
    x: 30,
    y: 30,
    width: 340,
    height: 340,
    fill: 'transparent',
    id: 'rect',
  },
];

const RELIGHT_PROMPS = {
  'sunny': "Sunny day",
  'susent': "Sunset",
  'night': "Night",
  'day': "Day",
  'cloudy': "Cloudy day",
  'sinrise': "Sunrise",
}

export interface Model {
  name: string;
  version: string;
  status: 'starting' | 'completed' | 'ending' | 'failed';
  data: any;
}

const Element = () => {

  const {
    uiStore: { authStore },
  } = useStore();

  const isMobile = useIsMobile()

  const theme = useMantineTheme();

  const { classes } = useStyles();

  const [opened, setOpened] = useState(false);
  const [big, setBig] = useState(false);

  const openConfirmUploadCustomImage = (src: string) => modals.openConfirmModal({
    title: 'Upload local file',
    children: (
      <Text size="sm">
        I confirm that I own the rights to the image used AI generation.
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => {
      setImageSrc(null)
    },
    onConfirm: () => {
      setImageSrc({
        ...imageSrc!,
        url: src,
      })
      setMode(MODE.SELECT_MODE)
    },
  });

  // const tiguanLoraUsers = (
  //   authStore.currentUser?.id === 'user_gbiIir1nLvbrfkiNI_pYo'
  //   || authStore.currentUser?.id === 'user_jKO4RFY8WyAV72jLWFg7g'
  //   || authStore.currentUser?.id === 'user_jq3MVJrq1AsUU_nDD4Mas'
  //   || authStore.currentUser?.id === 'user_vKpNcEB-RDH-QZH6bArzv'
  // )

  // const volvoLoraUsers = (
  //   authStore.currentUser?.id === 'user_k_McJZ0_LViQXlqY4S0pi'
  // )

  const secretModeEnabled = authStore.currentUser?.id === 'user_ncW1SxIG5X3mwQGtmPp5Z' || authStore.currentUser?.id === 'user_ztvePOlS8Ylq0N0c5wFhK' || authStore.currentUser?.id === 'user_RzgcIMD1nu3VgXxx1ohCx'


  const [models, setModels] = useState<Model[]>([]);
  const [gallery, setGallery] = useState<{
    images: { url: string, preview: string }[];
    previewHdrl: string;
    enhancedHdrlFile: string;
    modelVersion?: string;
  }[]>([]);
  const [selectedModel, setSelectedModel] = useState<null | Model>(null);
  // const [latestCustomModelRun, setLatestCustomModelRun] = useState<null | { jobId: string, srcset: string[] }>(null);
  const [latestCustomModelsRun, setLatestCustomModelsRun] = useState<{ jobId: string, srcset: string[] }[]>([]);
  const [modelParams, setModelParams] = useState({
    prompt: '',
    aspect_ratio: "1:1",
    lora_scale: '1',
    secondLoraName: '',
    secondLoraScale: '1',
    motionBlur: '',
    carPerspective: [
      'Front View',
      'Rear View',
      'Side View (Left)',
      'Side View (Right)',
      'Front 3/4 View (Left)',
      'Front 3/4 View (Right)',
      'Rear 3/4 View (Left)',
      'Rear 3/4 View (Right)'
    ],
    color: '#004f18',
    steps: 28,
    cfg: 3.5,
  });

  const [canvasBig, setCanvasBig] = useState(false);
  const [rectangles, setRectangles] = useState(initialRectangles);
  const [selectedId, selectShape] = useState<null | string>('rect');
  const stageRef = useRef(null);
  const [latestRunId, setLatestRunId] = useState<null | { url: string, jobId: string }>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [size, setSize] = useState({
    height: 952,
    width: 952,
  });

  const checkDeselect = (e: any) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      // selectShape(null);
    }
  };
  // const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  const isDesktop = useMediaQuery(`(min-width: 500px)`);



  const [pipelines, setPipelines] = useState<PipelineData[]>([]);
  const [featured, setFeatured] = useState<PipelineData[]>([]);
  const [mode, setMode] = useState(MODE.GALLERY);
  // const [mode, setMode] = useState(MODE.IMPORT_CAR);
  const [depthMapParams, setDepthMapParams] = useState({
    prompt: '',
    negativePrompt: defaultNegative,
    url: '',
    url3d: '',
    repairedUrl3d: '',
    step: 1,
  });

  const [image360Params, setImage360Params] = useState({
    prompt: '',
    aspect_ratio: '16:9',
    url: '',
  });

  const [image360DeepixParams, setImage360DeepixParams] = useState({
    prompt: '',
    aspect_ratio: '16:9',
    url: '',
  });

  const [img2imgFluxParams, setImg2imgFluxParams] = useState({
    prompt: '',
    url: '',
  });
  const [genSimilarParams, setGenSimilarParams] = useState({
    prompt: '',
    negativePrompt: '',
    url: '',
    url3d: '',
    repairedUrl3d: '',
    step: 1,
  });

  const [changeBackgroundParams, setChangeBackgroundParams] = useState({
    prompt: 'sportcar, day natural light',
    negativePrompt: defaultNegative,
    objectSrc: '',
    backgroundSrc: '',
    gradientSide: 'left',
  });

  const [enlargeParams, setEnlargeParams] = useState({
    url: '',
    left: 250,
    top: 0,
  });
  const [relightParams, setRelightParams] = useState({
    url: '',
    preset: 'sunny',
    mask: 1,
  });

  const [imageSrc, setImageSrc] = useState<null | { panoramaImage: string | null, url: string, modelVersion: string | null }>(null);

  const [customLoading, setCustomLoading] = useState(false);
  const [billingModalOpen, setBillingModalOpen] = useState(false);

  const [trainModel, setTrainModel] = useState<{ open: boolean, step: number, images: string[], name: string, description: string, consent: boolean }>({
    open: false,
    step: 1,
    images: [
    ],
    name: '',
    consent: true,
    description: ''
  });

  const [anyCustomModel, setAnyCustomModel] = useState<{ parameters: any, prompt: string, adding: string }>({
    parameters: '',
    prompt: '',
    adding: '',
  });
  const [blockade, setBlockade] = useState<{ prompt: string }>({
    prompt: '',
  });

  const {
    dataStore: { appStore },
  } = useStore();



  const { loading, runAsync } = useRequest(
    async () =>
      appStore.getPipelines(),
    {
      loadingDelay: 20,
      manual: true,
      onSuccess: (model) => { },
    }
  );
  const { loading: featuredDataLoading, runAsync: getFeaturedData } = useRequest(
    async () =>
      appStore.getFeaturedData(),
    {
      loadingDelay: 20,
      manual: true,
      onSuccess: (model) => { },
    }
  );
  const { loading: createPipelineLoading, runAsync: runCreatePipeline } = useRequest(
    async (pipelineParams: PipelineParameters) =>
      appStore.createPipeline({
        pipelineParams: pipelineParams
      }),
    {
      loadingDelay: 20,
      manual: true,
      onSuccess: (model) => {
        authStore.syncUser();
      },
    }
  );

  const { loading: fetchModelsLoading, runAsync: fetchModels } = useRequest(
    async () =>
      appStore.fetchModels(),
    {
      loadingDelay: 20,
      manual: true,
      onSuccess: (model) => { },
    }
  );
  const { loading: fetchGalleryLoading, runAsync: fetchGallery } = useRequest(
    async () =>
      appStore.fetchGallery(),
    {
      loadingDelay: 20,
      manual: true,
      onSuccess: (model) => { },
    }
  );

  const { loading: trainLoading, runAsync: train } = useRequest(
    async (model: { images: string[], name: string, description: string }) => appStore.train({
      images: model.images,
      name: model.name,
      description: model.description
    }),
    {
      manual: true,
      onSuccess: (res) => {
        if (res.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
        } else {
          authStore.syncUser();
          return res.url
        }
      },
    }
  );

  const { loading: searchInMagroundLoading, runAsync: searchInMaground } = useRequest(
    async (model: { src: string }) => appStore.searchInMaground({
      src: model.src,
    }),
    {
      manual: true,
      onSuccess: (res) => {
        if (res.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
        } else {
          authStore.syncUser();
          return res.url
        }
      },
    }
  );

  const { loading: addToFeaturedLoading, runAsync: addToFeatured } = useRequest(
    async (model: { id: string }) => appStore.addToFeatured({
      id: model.id,
    }),
    {
      manual: true,
      onSuccess: (res) => {
        if (res.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
        } else {
          authStore.syncUser();
          return res.url
        }
      },
    }
  ); const { runAsync: removeFromFeatured } = useRequest(
    async (model: { recordId: number }) => appStore.removeFromFeatured({
      recordId: model.recordId,
    }),
    {
      manual: true,
      onSuccess: (res) => {
        if (res.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
        } else {
          authStore.syncUser();
          return res.url
        }
      },
    }
  );

  const syncBilling = useCallback(async (event: any) => {
    if (event.key === "sync") {
      authStore.syncUser();
      setTimeout(async () => {
        authStore.syncUser();
      }, 4000)
    }
  }, [authStore]);

  const onPipelineUpdate = useCallback((pipeline: PipelineData, index: number,) => {
    setPipelines(oldPP => oldPP.map((p, i) => i === index ? pipeline : p))

    if (latestRunId?.jobId === pipeline.jobId) {
      setLatestRunId({
        jobId: pipeline.jobId,
        url: pipeline.params.srcset[0]
      })
    }

    if (pipeline.params.type === 'customModelV1') {
      // replace with exact order
      setLatestCustomModelsRun(old => {
        const run = old.findIndex(itm => itm.jobId === pipeline.jobId)

        console.log('oldlatestCustomModelsRun', old, run)
        if (run !== -1) {
          // NOTIFICATION_SERVICE.successNotification('Generation status updated')
          return old.map((itm, idx) => idx === run ? {
            jobId: pipeline.jobId,
            srcset: pipeline.params.srcset
          } : itm)
        } else {
          return old
        }
      })
    } else {
      NOTIFICATION_SERVICE.successNotification('Generation status updated')
    }


  }, [latestRunId?.jobId]);

  useEffect(() => {
    window.addEventListener("storage", syncBilling);

    return () => {
      window.removeEventListener("storage", syncBilling);
    };
  }, [syncBilling]);

  useEffect(() => {
    async function fetchData() {
      const res: {
        data: PipelineData
        status: 'pending' | 'completed' | 'ending' | 'failed';
      }[] = await runAsync();
      console.log(res);
      setPipelines(res.map((r) => ({
        ...r.data,
        status: r.status
      })));
    }

    async function fetchFeatured() {
      const res: {
        record_id: number
        data: PipelineData
      }[] = await getFeaturedData();
      setFeatured(
        res.map((r) => ({
          ...r.data,
          recordId: r.record_id,
        })).reverse()
      );
    }

    async function fetchModelsData() {
      const res: Model[] = await fetchModels();
      console.log(res);
      setModels(res)
    }

    async function fetchGalleryData() {
      const res = await fetchGallery();
      setGallery(res as any)
    }
    fetchModelsData()
    fetchData();
    fetchGalleryData();
    fetchFeatured();
  }, [fetchGallery, fetchModels, runAsync, getFeaturedData]);

  const { loading: runAsyncConvertBase64Loading, runAsync: runAsyncConvertBase64 } = useRequest(
    async (model: { url: string }) => appStore.convertImageBase64({
      url: model.url,
    }),
    {
      manual: true,
      onSuccess: (res) => {
        if (res.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
        } else {
          return res.url
        }
      },
    }
  );


  useEffect(() => {
    const handler = async (event: any) => {
      try {
        let data
        try {
          data = JSON.parse(event.data)
        } catch (e) {
          return
        }

        if (data.name === 'GET_SCREENSHOT') {
          const url = await runAsyncConvertBase64({ url: data.image })

          if (mode === MODE.DEPTH_MAP) {
            setDepthMapParams({
              ...depthMapParams,
              url3d: url.url,
              step: 2,
            })
          } else if (mode === MODE.GEN_SIMILAR) {
            setGenSimilarParams({
              ...genSimilarParams,
              url3d: url.url,
              step: 2,
            })

          }
        }
        console.log(data)
      } catch (e) {
        console.log('portfolio unhandled', e)
      }
    }

    window.addEventListener('message', handler)

    // clean up
    return () => window.removeEventListener('message', handler)
  }, [depthMapParams, genSimilarParams, mode, runAsyncConvertBase64])

  const { loading: runAsyncConvertLoading, runAsync: runAsyncConvert } = useRequest(
    async (model: { url: string, removeBackground?: boolean }) => appStore.convertImage(model),
    {
      manual: true,
      onSuccess: (res) => {
        if (res.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
        } else {
          return res.url
        }
      },
    }
  );

  const { loading: runAsyncRepairLoading, runAsync: runAsyncRepair } = useRequest(
    async (model: { url: string }) => appStore.repairImage({
      url: model.url,
    }),
    {
      manual: true,
      onSuccess: (res) => {
        if (res.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
        } else {
          return res.url
        }
      },
    }
  );
  const { loading: addCustomModelLoading, runAsync: addCustomModel } = useRequest(
    async (model: { version: string }) => appStore.addCustomModel({
      version: model.version,
    }),
    {
      manual: true,
      onSuccess: (res) => {
        if (res.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
        } else {
          return res.url
        }
      },
    }
  );

  const { loading: runAsyncVisionLoading, runAsync: runAsyncVision } = useRequest(
    async (model: { url: string }) => appStore.vision({
      url: model.url,
    }),
    {
      manual: true,
      onSuccess: (res) => {
        if (res.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
        } else {
          return res.url
        }
      },
    }
  );



  if (isMobile) {
    return (
      <Flex h="100%" w={'100%'} align={'center'} justify={'center'} p={24}>
        <Text align="center">
          <b style={{ marginBottom: '4px', fontSize: '18px' }}>MAGROUND.AI Best Viewed on Larger Screens</b>
          <br />
          Thank you for signing up! MAGROUND.AI is currently optimized for desktop and laptop use. For the best experience, please access the platform on a larger screen. Mobile support is coming soon!
        </Text>
      </Flex>
    )
  }

  // // TODO remove after the wailist is removed
  // useEffect(() => {
  //   if (authStore.currentUser?.status.name === 'Inactive') {
  //     window.location.href = '/waitlist';
  //   }
  // }, [authStore]);

  // if (authStore.currentUser?.status.name === 'Inactive') {
  //   return null
  // }


  const generateButtton = (model: { credits: number, text?: string, intercom?: string }) => {
    return <Flex
      gap={12}
      align={'center'}
      data-intercom-target={
        model.intercom
      }>
      <Text>
        {typeof model.text === 'string' ? model.text : 'Generate'}
      </Text>
      <Flex align={'center'} style={{
        borderRadius: '12px', background: '#f85e39',
        padding: '0 8px'
      }}>

        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.98014 1.33337C4.30014 1.33337 1.31348 4.32004 1.31348 8.00004C1.31348 11.68 4.30014 14.6667 7.98014 14.6667C11.6601 14.6667 14.6468 11.68 14.6468 8.00004C14.6468 4.32004 11.6668 1.33337 7.98014 1.33337ZM10.4801 8.23337L8.00014 11.0534L7.70681 11.3867C7.30014 11.8467 6.96681 11.7267 6.96681 11.1067V8.46671H5.83348C5.32014 8.46671 5.18014 8.15337 5.52014 7.76671L8.00014 4.94671L8.29348 4.61337C8.70014 4.15337 9.03348 4.27337 9.03348 4.89337V7.53337H10.1668C10.6801 7.53337 10.8201 7.84671 10.4801 8.23337Z" fill="white" />
        </svg>
        <Text >{model.credits}</Text>
      </Flex >
    </Flex>
  }

  const canvasSize = {
    small: {
      // width: 400,
      // height:  400 * size.height / size.width 
      width: size.height > size.width ? 400 * size.width / size.height : 400,
      height: size.height > size.width ? 400 : 400 * size.height / size.width
    },
    big: {
      width: size.height > size.width ? 2000 * size.width / size.height : 2000,
      height: size.height > size.width ? 2000 : 2000 * size.height / size.width
    }
  }


  function getRandomizedArrayStart<T>(array: T[]): T[] {
    const now = Date.now();
    const intervalMs = 30 * 60 * 1000; // 30 minutes in milliseconds
    const intervalStart = Math.floor(now / intervalMs) * intervalMs;

    // Use the start of the current 30-minute interval as the seed
    let seed = intervalStart;

    // Simple hash function to generate a more distributed seed
    seed = ((seed << 5) - seed) + array.length;
    seed = (seed & seed) >>> 0;

    // Use the seed to determine the start index
    const startIndex = seed % array.length;

    return [...array.slice(startIndex), ...array.slice(0, startIndex)];
  }

  const getImageSet = (image: string) => {
    return gallery.find(itm => itm.images.find(i => i.url === image))
  }

  return (
    <AppShell
      padding={0}
      header={
        isDesktop ? (
          <Header height={{ base: '84px' }} p="md" bg={'#ffffff'} withBorder={false}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>

              <div className={classes.logo} onClick={() => setMode(MODE.GALLERY)}>
                <Logo size={140} />
              </div>
              <Flex gap={12}>
                {
                  secretModeEnabled && <Button
                    h="53.8px"
                    bg="#f53c02"
                    c="white"
                    fw={700}
                    variant="light"
                    color="#b4e45f"

                    onClick={async () => {

                      setMode(MODE.FEATURED)
                    }}>[beta] Featured</Button>
                }

                {/* <Button
                  h="53.8px"
                  bg="#f53c02"
                  c="white"
                  fw={700}
                  variant="light"
                  color="#b4e45f"

                  onClick={async () => {
                    setTrainModel({
                      open: true,
                      step: 1,
                      images: [

                      ],
                      name: '',
                      consent: true,
                      description: ''
                    })
                  }}>Train</Button> */}
                <Button
                  h="53.8px"
                  // bg="#f53c02"
                  // c="white"
                  variant="default"

                  styles={{
                    root: {
                      // color: 'background: rgba(230, 230, 230, 1)',
                      background: '#f53c02',
                      border: 'none',
                      color: 'white',
                      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.05) inset',
                      ":hover": {
                        color: 'black',
                      }
                    }
                  }}
                  onClick={() => {
                    setMode(MODE.GALLERY)
                  }}>Gallery</Button>
                <User
                  buyCredits={() => setBillingModalOpen(true)}
                  billingHistory={async () => {
                    const res = await appStore.getPortalLink()
                    if (res.url) {

                      let a = document.createElement('a');
                      a.target = '_blank';
                      a.href = res.url;
                      a.click();
                    } else {
                    }
                  }}
                />
              </Flex>
            </div>
          </Header>
        ) :
          (
            <Header height={{ base: 50, md: 70 }} p="md" bg={'#ffffff'}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>

                <div className={classes.logo}>
                  <Logo size={40} />
                </div>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  color={theme.colors.gray[6]}
                />
              </div>
            </Header>
          )
      }


      styles={(theme) => ({
        main: {
          backgroundColor:
            '#ffffff'
        },
      })}
    >
      <Flex className={classes.wrapper} gap={12}>

        <Modal
          styles={{
            content: {
              '> div': {
                minHeight: '100vh',
              }
            },
            header: {
              // background: '#000000 !important'
            },
            body: {
              minHeight: '100vh',
              // background: '#000000'
            }
          }}
          opened={trainModel.open}
          onClose={() => {
            setTrainModel({
              open: false,
              step: 1,
              images: [

              ],
              name: '',
              consent: true,
              description: ''
            })
          }} title={'Train your own model'} fullScreen>
          <Flex justify={'center'}>
            <Flex maw={'800px'}>
              {
                trainModel.step === 1 ? <Flex direction={'column'} justify='center' align="center" gap={24}>
                  <Text >Step 1/2</Text>
                  <Text >You need at least 10 images in a consistent style to train your model. </Text>
                  {trainModel.images.length > 0 ?
                    <Button
                      styles={{
                        root: {
                          color: 'rgba(230, 230, 230, 1)',
                          height: rem(36),

                          fontSize: '14px',
                          background: '#f53c02 !important',
                          border: 'none',
                          position: 'relative',
                        }
                      }}
                      onClick={() => {
                        setTrainModel({
                          ...trainModel,
                          step: 2
                        })
                      }}>
                      Next step
                    </Button> : null}
                  {
                    trainModel.images.length > 0 ?
                      <Flex gap={12} justify={'center'} wrap={'wrap'}>
                        {trainModel.images.map((itm, index) => {
                          return <Flex
                            key={index}
                            h="100px"
                            w={'100px'}
                            onClick={() => {
                              // setBacgroundSrc({
                              //   url: itm
                              // })
                            }}
                          >
                            <ImageObserved
                              width={'100px'}
                              height={'100px'}
                              src={itm}
                            />
                          </Flex>
                        })}
                      </Flex> :
                      <Widget
                        imagesOnly
                        // multipleMax={50}
                        multipleMin={10}
                        localeTranslations={{
                          buttons: {
                            choose: {
                              images: {
                                one: 'Select images'
                              },
                            },
                          },
                        }}
                        clearable={true}
                        multiple={true}
                        onFileSelect={async (res) => {
                          const imgs: string[] = [];
                          if (res) {
                            const files = (res as FilesUpload).files();
                            for (const file of files) {
                              // @ts-ignore
                              const img = await file.promise();
                              imgs.push(img.cdnUrl);
                            }
                          }
                          setTrainModel({
                            ...trainModel,
                            step: 1,
                            images: imgs
                          })
                          console.log(imgs)
                        }}
                        tabs={"file camera url"}
                        publicKey="35626f59762b63c4244c"
                        previewStep={true}
                        crop="free, 16:9, 4:3, 5:4, 1:1"
                      />
                  }

                </Flex> : null
              }
              {
                trainModel.step === 2 ? <Flex direction={'column'} justify='center' align="center" gap={24}>
                  <Text >Step 2/2</Text>
                  <Text >Final step: Name your model & start training.</Text>
                  <Textarea
                    w="300px"
                    minRows={1}
                    size="sm"
                    label=""
                    value={trainModel.name}
                    placeholder=""
                    onChange={(e) => {
                      setTrainModel({
                        ...trainModel,
                        name: e.currentTarget.value
                      })

                    }}
                  />


                  <Select
                    w="300px"
                    size="sm"
                    description=""
                    label="Select type of your images"
                    placeholder="Pick value"
                    value={trainModel.description}
                    onChange={(value) => {
                      setTrainModel({
                        ...trainModel,
                        description: value!
                      })

                    }}
                    mb={10}
                    data={[
                      { value: 'photograph', label: 'Photograph' },
                      { value: 'illustration', label: 'Illustration' },
                      { value: '3D render', label: '3D Render' },
                      { value: 'pixel art', label: 'Pixel Art' },
                      { value: 'abstract', label: 'Abstract' },
                    ]}
                  />


                  {/* <Checkbox
                    mt={10}
                    mb={10}
                    w="300px"
                    color="dimmed"
                    size={'sm'}
                    labelPosition="left"
                    styles={{
                      body: {
                        alignItems: 'center',
                      },
                      label: {
                        fontSize: 12,
                      },
                      input: {
                        borderRadius: 2,
                        borderColor: '#464646',
                        backgroundColor: 'transparent',
                      }
                    }}
                    label={'I confirm that I own the rights to all images used for training this AI model'}
                    checked={trainModel.consent}
                    onChange={(e) => {
                      setTrainModel({
                        ...trainModel,
                        consent: e.currentTarget.checked
                      })
                    }}
                  /> */}

                  {trainModel.images.length > 0 ?

                    <Button
                      loading={trainLoading}
                      mb={20}
                      disabled={!trainModel.consent || trainModel.name.length === 0}
                      styles={{
                        root: {
                          color: 'rgba(230, 230, 230, 1)',
                          height: rem(36),

                          fontSize: '14px',
                          background: '#f53c02 !important',
                          border: 'none',
                          position: 'relative',
                        }
                      }}
                      variant="default"
                      onClick={async () => {

                        // if (authStore.currentUser?.credits < 60) {
                        //   NOTIFICATION_SERVICE.errorNotification(
                        //     "Not enough credits"
                        //   );
                        //   setBillingModalOpen(true)
                        // } else {
                        await train({
                          images: trainModel.images,
                          name: trainModel.name,
                          description: trainModel.description
                        })

                        NOTIFICATION_SERVICE.successNotification('Training in progress. It can take up to 20 minutes')

                        const r: Model[] = await fetchModels();
                        console.log(r);
                        setModels(r)

                        setTrainModel({
                          open: false,
                          step: 1,
                          images: [

                          ],
                          name: '',
                          consent: true,
                          description: '',
                        })
                        // }
                      }}
                    >
                      Begin training
                    </Button>
                    : null}
                  {
                    trainModel.images.length > 0 ?
                      <Flex gap={12} justify={'center'} wrap={'wrap'}>
                        {trainModel.images.map((itm, index) => {
                          return <Flex
                            key={index}
                            h="100px"
                            w={'100px'}
                            onClick={() => {
                              // setBacgroundSrc({
                              //   url: itm
                              // })
                            }}
                          >
                            <ImageObserved
                              width={'100px'}
                              height={'100px'}
                              src={itm}
                            />
                          </Flex>
                        })}
                      </Flex> :
                      null
                  }

                </Flex> : null
              }
            </Flex>
          </Flex>
        </Modal>
        <BillingModal opened={billingModalOpen} close={() => setBillingModalOpen(false)} ></BillingModal>
        <LoadingOverlay visible={loading || customLoading || runAsyncConvertBase64Loading || runAsyncConvertLoading || runAsyncRepairLoading || runAsyncVisionLoading || createPipelineLoading || addToFeaturedLoading || featuredDataLoading || fetchModelsLoading || fetchGalleryLoading || addCustomModelLoading || searchInMagroundLoading} loaderProps={{ children: <Flex gap={8}><Loader color="orange" /><Text>Generating...</Text></Flex> }} />
        <Flex w={'100%'} pos="relative" bg="#f0f2f1" style={{ borderRadius: '8px', overflowY: 'scroll', height: '100%' }} p={'0.8rem'} direction={'column'}>

          {
            mode === MODE.GALLERY && (
              <Flex direction={'column'}>
                <div style={{ position: 'absolute', right: '20px', top: '12px' }}>

                  <Steps active={1} steps={4} />
                </div>
                {/* {
                  models.length > 0 && (
                    <>
                      <Text mb={10} mt={10} >Custom models:</Text>
                      <Flex gap={12} wrap={'wrap'}>
                        {
                          models.map((model, index) => {
                            return <Flex w={'100%'}
                              key={index}
                              direction={'column'}
                              gap={12}
                              bg={
                                '#ffffff'
                              }
                              style={{
                                border: '2px solid #ffffff', borderRadius: '2px',
                                width: '200px',

                              }} p={12} align={'center'}>
                              <Text  fz={16}>{model.data.name}</Text>
                              <Flex justify={'space-between'}>
                                {(model.data?.images || [])
                                  .slice(0, 3)
                                  .map((img, index) => {
                                    return <Flex
                                      key={index}
                                      h="auto"
                                      p={2}
                                      w={'33.3%'}
                                    >
                                      <ImageObserved
                                        width={'100%'}
                                        height={'auto'}
                                        src={img}
                                      />
                                    </Flex>
                                  })
                                }
                              </Flex>
                              <Flex gap={6}>

                                <Button
                                  styles={{
                                    root: {
                                      color: 'rgba(230, 230, 230, 1)',
                                      height: rem(36),

                                      fontSize: '14px',
                                      background: '#f53c02 !important',
                                      border: 'none',
                                      position: 'relative',
                                    }
                                  }}
                                  variant="default"
                                  onClick={() => {
                                    setSelectedModel(model)
                                    setMode(MODE.SELECTED_MODEL)
                                  }}
                                >
                                  Select
                                </Button>
                              </Flex>
                            </Flex>
                          }
                          )
                        }
                      </Flex>
                    </>
                  )
                } */}

                <Flex gap={2} justify={'center'} mt={40} mb={40} align={'center'}>


                  <Text fz={16}>To begin, select a location from the gallery below or
                  </Text>
                  <Flex w="140px">
                    <Widget
                      imagesOnly
                      localeTranslations={{
                        buttons: {
                          choose: {
                            images: {
                              one: 'upload local file'
                            },
                          },
                        },
                      }}
                      clearable={false}
                      // @ts-ignore
                      onChange={async (info: {
                        cdnUrl: string, originalImageInfo: {
                          width: number
                          height: number
                        }
                      }) => {
                        //   const id = spaceStore.selected?.id
                        setCustomLoading(true)
                        console.log(info)
                        const res = await runAsyncConvert({ url: info.cdnUrl })
                        setCustomLoading(false)
                        const panorama = info.originalImageInfo.width > info.originalImageInfo.height * 2 ? res.url : null
                        setImageSrc({
                          panoramaImage: panorama,
                          url: '',
                          modelVersion: null
                        })
                        openConfirmUploadCustomImage(res.url)

                        // if (panorama) {
                        //   // setMode(MODE.SELECT_ANGLE)
                        // } else {
                        //   setMode(MODE.SELECT_MODE)
                        // }

                      }}
                      tabs={"file camera url"}
                      publicKey="35626f59762b63c4244c"
                      previewStep={true}
                      crop="free, 16:9, 4:3, 5:4, 1:1"
                    />
                  </Flex>

                </Flex>

                {

                  getRandomizedArrayStart(
                    transformArray(gallery)
                  ).map((folder, index) => (<Flex key={index} gap={16} w={'100%'} bg="#f0f2f1" style={{ overflowX: 'scroll', overflowY: 'hidden', paddingBottom: '12px', height: '300px' }}
                  >
                    {
                      folder.map((item, index) => (
                        <Flex
                          key={index}
                          h="256px"
                          w="340px"
                          className={classes.cardItem}

                          onClick={async () => {
                            console.log(item)
                            setImageSrc({
                              panoramaImage: item.enhancedHdrlFile,
                              url: item.image.url,
                              modelVersion: item.modelVersion || null,
                            })
                            // setMode(MODE.SELECT_MODE)


                            if (item.modelVersion) {
                              const modelVersion = item.modelVersion
                              if (models.find(itm => itm.version === modelVersion)) {
                                setSelectedModel(models.find(itm => itm.version === modelVersion))
                                setMode(MODE.SELECTED_MODEL)
                              } else {
                                await addCustomModel({
                                  version: modelVersion!,
                                })
                                const res: Model[] = await fetchModels();
                                console.log(res);
                                setModels(res)
                                setSelectedModel(res.find(itm => itm.version === modelVersion))
                                setMode(MODE.ADD_CUSTOM_MODEL)
                              }
                            } else {
                              setMode(MODE.SELECT_MODE)
                            }


                          }}

                        >

                          <ImageObserved
                            width={'340px'}
                            height={'300px'}
                            src={item.image.preview}
                          />
                        </Flex>

                      ))
                    }
                  </Flex>))
                }
              </Flex>
            )
          }

          {
            mode === MODE.FEATURED && (
              <Flex direction={'column'}>

                <Flex
                  pb={30}
                  pt={10}
                  style={{ borderRadius: '8px', overflow: 'scroll' }}
                  bg={'#ffffff'}
                  pl={'0.8rem'}
                  pr={'0.8rem'}
                  direction={'column'}
                  w={'100%'}
                  miw={'100%'}>
                  {
                    featured.map((model, index) => {
                      if (model.params.type === 'img2img' || model.params.type === 'img2imgflux' || model.params.type === 'fluxControlnet' || model.params.type === 'customModel360V1' || model.params.type === 'customModel360HDRV2' || model.params.type === 'customModelV2') {
                        return (

                          <>
                            <Flex align={'center'} gap={12} mb={6}>
                              <Text fz={20}>GENERATION</Text>
                            </Flex>

                            <Flex>

                              <Flex direction={'column'} miw={'300px'} w={'300px'} mr={20} gap={6}>
                                <Flex direction={'column'} gap={8}>
                                  <Text fz={12} style={{ color: 'BDBDBD' }}>Reference image:</Text>
                                  <ImageObserved
                                    dark
                                    width={'240px'}
                                    height={'240px'}
                                    src={model.params.inputSrc}
                                  />
                                </Flex>
                                <Spoiler maxHeight={120} showLabel="Show more" hideLabel="Hide">
                                  <Text fz={12} style={{ color: 'BDBDBD' }}>Prompt:</Text>
                                  <Text fz={12} >
                                    {model.params.prompt}
                                  </Text>

                                </Spoiler>

                                <Spoiler maxHeight={120} showLabel="Show more" hideLabel="Hide">
                                  <Text fz={12} style={{ color: 'BDBDBD' }}>Negative prompt:</Text>
                                  <Text fz={12} >
                                    {model.params.negativePrompt}
                                  </Text>
                                </Spoiler>

                                {
                                  secretModeEnabled && <Text fz={10} color="red" onClick={async () => {
                                    await removeFromFeatured({
                                      recordId: model.recordId
                                    })
                                    NOTIFICATION_SERVICE.successNotification('Removed from featured, refresh the page to see the changes')
                                  }}>[beta] Remove from featured </Text>
                                }


                              </Flex>
                              <Flex
                                gap={20}
                              >
                                {model.params.srcset.map((src, index) => (
                                  <Flex direction={'column'}>
                                    <ImageObserved
                                      width={'440px'}
                                      height={'440px'}
                                      dark={true}
                                      src={src}
                                    />
                                  </Flex>
                                ))}
                              </Flex>
                            </Flex>
                          </>

                        )
                      } else if (model.params.type === 'customModelV1') {
                        console.log(model)
                        return <>
                          <Flex align={'center'} gap={12} mb={6}>
                            <Text fz={20}>CUSTOM MODEL GENERATION</Text>
                          </Flex>

                          <Flex>

                            <Flex direction={'column'} miw={'300px'} w={'300px'} mr={20} gap={6}>
                              <Spoiler maxHeight={120} showLabel="Show more" hideLabel="Hide">
                                <Text fz={12} style={{ color: 'BDBDBD' }}>Prompt:</Text>
                                <Text fz={12} >
                                  {model.params.prompt}
                                </Text>

                              </Spoiler>
                              <Button
                                variant="default"
                                size={'xs'}
                                onClick={async () => {
                                  setSelectedModel(models.find(itm => itm.version === model.params.modelVersion))
                                  setMode(MODE.SELECTED_MODEL)
                                }}
                              >
                                Generate from this model
                              </Button>
                              {
                                secretModeEnabled && <Text fz={10} color="red" onClick={async () => {
                                  await removeFromFeatured({
                                    recordId: model.recordId
                                  })
                                  NOTIFICATION_SERVICE.successNotification('Removed from featured, refresh the page to see the changes')
                                }}>[beta] Remove from featured </Text>
                              }

                            </Flex>
                            <Flex
                              gap={20}
                            >
                              {model.params.srcset.map((src, index) => (
                                <Flex direction={'column'}>
                                  <ImageObserved
                                    width={'440px'}
                                    height={'440px'}
                                    dark={true}
                                    src={src}
                                  />
                                </Flex>
                              ))}
                            </Flex>
                          </Flex>
                        </>
                      } else {
                        return null
                      }

                    }
                    )
                  }
                </Flex>
              </Flex>
            )
          }
          {
            mode === MODE.SELECTED_MODEL && (
              <>
                <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>

                  <Flex direction={'column'} miw={'300px'} w={'300px'} gap={12}>
                    <Text fz={20}>Model: {selectedModel?.data?.name}</Text>
                    <Flex justify={'space-between'}>
                      {(selectedModel?.data?.images || [])
                        .slice(0, 6)
                        .map((img, index) => {
                          return <Flex
                            key={index}
                            h="auto"
                            p={2}
                            w={'33.3%'}
                            onClick={() => {
                              setBacgroundSrc({
                                url: img
                              })
                            }}
                          >
                            <ImageObserved
                              width={'100%'}
                              height={'auto'}
                              src={img}
                            />
                          </Flex>
                        })
                      }
                    </Flex>

                    <Select
                      w="300px"
                      size="sm"
                      mt={12}
                      description=""
                      label="Select Demo Car LORA"
                      placeholder="Pick value"
                      value={modelParams.secondLoraName}
                      onChange={(value) => {
                        setModelParams({
                          ...modelParams,
                          secondLoraName: value!
                        })
                      }}
                      mb={10}
                      data={
                        getLoraOptons(authStore.currentUser?.id)}
                    />

                    {
                      modelParams.secondLoraName &&
                      (
                        <ColorInput
                          label="Color of the car"
                          size="sm"
                          // radius={2}
                          value={modelParams.color}
                          withPicker
                          styles={{
                            root: {
                            },
                            input: {
                              // backgroundColor: '#ffffff',
                              // color: 'white',
                              // borderColor: '#464646',
                            }
                          }}
                          onChangeEnd={(e: any) => {
                            setModelParams({
                              ...modelParams,
                              color: e!
                            })
                          }}
                          format="hex"
                        />
                      )
                    }
                    {
                      modelParams.secondLoraName &&
                      (
                        <>
                          <Text
                            fz={14}
                            mt={12}
                            size="xs">
                            Select the car perspectives
                          </Text>
                          {
                            [
                              { value: 'Front View', label: 'Front View' },
                              { value: 'Rear View', label: 'Rear View' },
                              { value: 'Side View (Left)', label: 'Side View (Left)' },
                              { value: 'Side View (Right)', label: 'Side View (Right)' },
                              { value: 'Front 3/4 View (Left)', label: 'Front 3/4 View (Left)' },
                              { value: 'Front 3/4 View (Right)', label: 'Front 3/4 View (Right)' },
                              { value: 'Rear 3/4 View (Left)', label: 'Rear 3/4 View (Left)' },
                              { value: 'Rear 3/4 View (Right)', label: 'Rear 3/4 View (Right)' },
                            ].map((side) => (
                              <Checkbox
                                mt={2}
                                mb={2}
                                color="dimmed"
                                size={'xs'}
                                styles={{
                                  input: {
                                    borderRadius: 2,
                                    borderColor: '#464646',
                                    backgroundColor: 'transparent',
                                  }
                                }}
                                label={side.label}
                                checked={modelParams.carPerspective.includes(side.value)}
                                onChange={(e) => {
                                  if (modelParams.carPerspective.includes(side.value)) {
                                    setModelParams({
                                      ...modelParams,
                                      carPerspective: modelParams.carPerspective.filter(itm => itm !== side.value)
                                    })
                                  } else {
                                    setModelParams({
                                      ...modelParams,
                                      carPerspective: [...modelParams.carPerspective, side.value]
                                    })
                                  }
                                }}
                              />
                            ))

                          }
                        </>
                        // <Select
                        //   w="300px"
                        //   size="sm"
                        //   mt={12}
                        //   description=""
                        //   label="Select Car perspective"
                        //   placeholder="Pick value"
                        //   value={modelParams.carPerspective}
                        //   onChange={(value) => {
                        //     setModelParams({
                        //       ...modelParams,
                        //       carPerspective: value!
                        //     })
                        //   }}
                        //   mb={10}
                        //   data={[
                        //     { value: '', label: 'None' },
                        //     { value: 'Front View', label: 'Front View' },
                        //     { value: 'Rear View', label: 'Rear View' },
                        //     { value: 'Side View (Left)', label: 'Side View (Left)' },
                        //     { value: 'Side View (Right)', label: 'Side View (Right)' },
                        //     { value: 'Top View', label: 'Top View' },
                        //     { value: 'Bottom View', label: 'Bottom View' },
                        //     { value: 'Front 3/4 View (Left)', label: 'Front 3/4 View (Left)' },
                        //     { value: 'Front 3/4 View (Right)', label: 'Front 3/4 View (Right)' },
                        //     { value: 'Rear 3/4 View (Left)', label: 'Rear 3/4 View (Left)' },
                        //     { value: 'Rear 3/4 View (Right)', label: 'Rear 3/4 View (Right)' },
                        //   ]
                        //   }
                        // />
                      )
                    }  {
                      modelParams.secondLoraName &&
                      (
                        <Select
                          w="300px"
                          size="sm"
                          // mt={12}
                          description=""
                          label="Add motion blur"
                          placeholder="Pick value"
                          value={modelParams.motionBlur}
                          onChange={(value) => {
                            setModelParams({
                              ...modelParams,
                              motionBlur: value!
                            })
                          }}
                          mb={10}
                          data={[
                            { value: '', label: 'None' },
                            { value: '20 km/h', label: '20 km/h' },
                            { value: '40 km/h', label: '40 km/h' },
                            { value: '60 km/h', label: '60 km/h' },
                            { value: '80 km/h', label: '80 km/h' },
                            { value: '100 km/h', label: '100 km/h' },
                            { value: '120 km/h', label: '120 km/h' },
                            { value: '140 km/h', label: '140 km/h' },
                            { value: '160 km/h', label: '160 km/h' },
                            { value: '180 km/h', label: '180 km/h' },
                            { value: '200 km/h', label: '200 km/h' },
                          ]
                          }
                        />
                      )
                    }


                    <Textarea
                      data-intercom-target={
                        'promptInfo'
                      }
                      w="100%"
                      minRows={7}
                      size="sm"
                      label="Location Prompt"
                      value={modelParams.prompt}
                      description=""
                      placeholder="Street view"
                      onChange={(e) => {
                        setModelParams({
                          ...modelParams,
                          prompt: e.currentTarget.value
                        })
                      }}
                    />

                    <PromptGeneratorButton
                      image={selectedModel?.data?.images[0]}
                      images={gallery.find(itm => itm.modelVersion === selectedModel?.version)?.images || undefined}
                      onSetPrompt={(prompt) => {
                        setModelParams({
                          ...modelParams,
                          prompt: prompt
                        })
                      }}
                    />

                    <Text fz={16} mt={12}>Choose aspect ratio</Text>
                    <Flex w={'100%'} gap={4} wrap={'wrap'}>
                      {
                        ['1:1', '16:9', '21:9', '2:3', '3:2', '4:5', '5:4', '9:16', '9:21'].map((side) => (
                          <Button
                            size="xs"
                            style={{
                              width: '80px',
                              border: '1px solid black',
                              color: modelParams.aspect_ratio === side ? '#f53c02' : 'black',
                              fontSize: '14px',
                            }}
                            color="black"
                            variant={modelParams.aspect_ratio === side ? "default" : 'outline'}
                            onClick={() => {
                              setModelParams({
                                ...modelParams,
                                aspect_ratio: side,
                              })
                            }}
                          >{side}</Button>
                        ))
                      }
                    </Flex>

                    <Accordion chevronPosition="left" defaultValue="Apples">
                      <Accordion.Item key={'key'} value={'value'}>
                        <Accordion.Control>Advanced settings</Accordion.Control>
                        <Accordion.Panel>
                          <Flex direction={'column'} gap={12}>

                            {
                              modelParams.secondLoraName &&
                              <>
                                <Text
                                  size="xs"
                                  fz={14}
                                  mt={12}
                                >
                                  Generation steps
                                </Text>
                                <Slider
                                  size="xs"
                                  color="#f53c02"
                                  sx={{ maxWidth: 360 }}
                                  mt={0}
                                  min={20}
                                  max={46}
                                  step={1}
                                  label={modelParams.steps}
                                  value={+modelParams.steps}
                                  onChangeEnd={(e) => {
                                    setModelParams((prevState) => ({
                                      ...prevState,
                                      steps: +e.toFixed(2),
                                    }))
                                  }}
                                />
                                <Text
                                  fz={14}
                                  mt={12}
                                  size="xs"
                                >
                                  Guidance Scale
                                </Text>
                                <Slider
                                  size="xs"
                                  color="#f53c02"
                                  sx={{ maxWidth: 360 }}
                                  mt={0}
                                  min={1}
                                  max={4}
                                  step={0.1}
                                  label={modelParams.cfg}
                                  value={+modelParams.cfg}
                                  onChangeEnd={(e) => {
                                    setModelParams((prevState) => ({
                                      ...prevState,
                                      cfg: +e.toFixed(2),
                                    }))
                                  }}
                                />
                              </>
                            }

                            <Text
                              fz={14}
                              mt={12}
                              size="xs"
                              data-intercom-target={
                                'trainStrength'
                              }>
                              Location streight
                            </Text>
                            <Slider
                              size="xs"
                              color="#f53c02"
                              sx={{ maxWidth: 360 }}
                              mt={0}
                              min={0}
                              max={2}
                              step={0.1}
                              label={modelParams.lora_scale}
                              value={+modelParams.lora_scale}
                              onChangeEnd={(e) => {
                                setModelParams((prevState) => ({
                                  ...prevState,
                                  lora_scale: e.toFixed(2),
                                }))
                              }}
                            />
                            <Text fz={12}>Controls AI creativity. Lower values make the AI more creative, using the model less. Higher values make it follow the model more closely, with less variation. Best results between 0.5 and 1.4</Text>

                            {
                              modelParams.secondLoraName &&

                              <Text
                                size="xs"
                                fz={14}
                                mt={12}
                                data-intercom-target={
                                  'trainStrength'
                                }>
                                Car streight
                              </Text>
                            }
                            {
                              modelParams.secondLoraName &&
                              <Slider
                                size="xs"
                                color="#f53c02"
                                sx={{ maxWidth: 360 }}
                                mt={0}
                                min={0}
                                max={2}
                                step={0.1}
                                label={modelParams.secondLoraScale}
                                value={+modelParams.secondLoraScale}
                                onChangeEnd={(e) => {
                                  setModelParams((prevState) => ({
                                    ...prevState,
                                    secondLoraScale: e.toFixed(2),
                                  }))
                                }}
                              />}

                            {
                              modelParams.secondLoraName &&
                              <Text fz={12}>Controls The Custom Car LORA creativity.</Text>
                            }
                          </Flex>
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>

                    <Flex gap={12} mt={32} justify={'end'}>
                      <Button
                        styles={{
                          root: {
                            color: 'rgba(230, 230, 230, 1)',
                            height: rem(36),

                            fontSize: '14px',
                            background: '#f53c02 !important',
                            border: 'none',
                            position: 'relative',
                          }
                        }}
                        disabled={modelParams.prompt.length === 0}
                        variant="default"
                        onClick={async () => {
                          if (modelParams.secondLoraName) {
                            const prompt = modelParams.secondLoraName ? modelParams.prompt : (modelParams.prompt + `, ${selectedModel?.data.description || 'Photograph'} in style of MAGAI`)

                            setLatestCustomModelsRun([])
                            for (const perspective of modelParams.carPerspective) {
                              const pipeline: PipelineParameters = {
                                provider: 'replicate',
                                type: 'customModelV1',
                                modelName: selectedModel!.data.name,
                                modelVersion: selectedModel!.version,
                                prompt: prompt,
                                aspectRation: modelParams.aspect_ratio,
                                loraScale: modelParams.lora_scale,
                                secondLoraScale: modelParams.secondLoraScale,
                                secondLoraName: modelParams.secondLoraName,
                                credits: priceMap.CUSTOM_MODEL_GEN,
                                replicateVersion: selectedModel!.version,
                                steps: modelParams.steps,
                                cfg: modelParams.cfg,
                                srcset: [],
                                color: modelParams.color,
                                carPerspective: perspective,
                                motionBlur: modelParams.motionBlur,
                              }
                              const res = await runCreatePipeline(pipeline);

                              if (res.error) {
                                NOTIFICATION_SERVICE.errorNotification(
                                  "Not enough credits"
                                );
                                setBillingModalOpen(true)
                              } else {
                                setPipelines(ppp => [{
                                  ...res.data,
                                  status: res.status
                                }, ...ppp])

                                setLatestCustomModelsRun(runs => [
                                  ...runs,
                                  {
                                    srcset: [],
                                    jobId: res.data.jobId
                                  }
                                ])
                              }
                            }
                          } else {

                            const prompt = (modelParams.prompt + `, ${selectedModel?.data.description || 'Photograph'} in style of MAGAI`)

                            const pipeline: PipelineParameters = {
                              provider: 'replicate',
                              type: 'customModelV1',
                              modelName: selectedModel!.data.name,
                              modelVersion: selectedModel!.version,
                              prompt: prompt,
                              aspectRation: modelParams.aspect_ratio,
                              loraScale: modelParams.lora_scale,
                              secondLoraScale: modelParams.secondLoraScale,
                              secondLoraName: modelParams.secondLoraName,
                              credits: priceMap.CUSTOM_MODEL_GEN,
                              replicateVersion: selectedModel!.version,
                              steps: modelParams.steps,
                              cfg: modelParams.cfg,
                              srcset: [],
                              color: modelParams.color,
                              carPerspective: '',
                              motionBlur: modelParams.motionBlur,
                            }
                            const res = await runCreatePipeline(pipeline);

                            if (res.error) {
                              NOTIFICATION_SERVICE.errorNotification(
                                "Not enough credits"
                              );
                              setBillingModalOpen(true)
                            } else {
                              setPipelines([{
                                ...res.data,
                                status: res.status
                              }, ...pipelines])

                              setLatestCustomModelsRun([
                                {
                                  srcset: [],
                                  jobId: res.data.jobId
                                }
                              ])
                            }
                          }
                        }}
                      >
                        {generateButtton({ credits: modelParams.secondLoraName ? modelParams.carPerspective.length * priceMap.CUSTOM_MODEL_GEN : priceMap.CUSTOM_MODEL_GEN, intercom: 'customModelGenerateButton' })}
                      </Button>
                    </Flex>
                  </Flex>

                  {
                    latestCustomModelsRun.length > 0 ? (
                      <Flex
                        w={'70%'}
                        gap={20}
                        direction={"column"}
                      >
                        {
                          latestCustomModelsRun.map((run, index) => {
                            if (run.srcset.length > 0) {
                              return (
                                <Flex
                                  gap={20}
                                >
                                  {run.srcset.map((src, index) => (
                                    <Flex direction={'column'}>
                                      <GearMenu
                                        size=""
                                        src={src}
                                        onEnlarge={() => {
                                          setEnlargeParams({
                                            ...enlargeParams,
                                            url: src
                                          })
                                          setMode(MODE.ENLARGE)
                                        }}
                                        onUpscale={() => {
                                          setImageSrc({
                                            panoramaImage: null,
                                            url: src,
                                            modelVersion: null
                                          });
                                          setMode(MODE.UPSCALE)
                                        }}
                                        pickImage={() => {
                                          setImageSrc({
                                            panoramaImage: null,
                                            url: src,
                                            modelVersion: null
                                          });
                                          setMode(MODE.PICK_IMAGE);
                                        }}
                                      />
                                      <ImageObserved
                                        width={'440px'}
                                        height={'440px'}
                                        dark={false}
                                        src={src}
                                      />
                                    </Flex>
                                  ))}
                                </Flex>
                              )
                            } else {
                              return (
                                <Flex w="100%" h="440px" p={32} align={'center'} justify={'center'} direction={'column'}>
                                  <Text color="#f53c02" fz={12}>Generation in progress.</Text>
                                  <Progress radius="lg" size="lg" mt={30} color="#f53c02" value={100} striped animate={true} w={'200px'} />
                                </Flex>
                              )
                            }
                          })
                        }
                      </Flex>
                    )
                      :
                      <Flex
                        gap={20}
                      >
                        {[1, 2, 3, 4].map((src, index) => (
                          <Flex direction={'column'} w={'440px'} h={'440px'} bg={'#ffffff'}>
                          </Flex>
                        ))}
                      </Flex>
                  }
                </Flex>
              </>
            )
          }
          {
            mode === MODE.IMAGE_360 && (
              <>
                <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>

                  <Flex direction={'column'} miw={'300px'} w={'300px'} gap={12}>
                    <Text fz={20}>Create 360 image</Text>

                    <Textarea
                      data-intercom-target={
                        'promptInfo'
                      }
                      w="100%"
                      minRows={7}
                      size="sm"
                      label="Prompt"
                      value={image360Params.prompt}
                      description=""
                      placeholder="Street view"
                      onChange={(e) => {
                        setImage360Params({
                          ...image360Params,
                          prompt: e.currentTarget.value
                        })
                      }}
                    />

                    <Text fz={16} mt={12}>Choose aspect ratio</Text>
                    <Flex w={'100%'} gap={4} wrap={'wrap'}>
                      {
                        ['1:1', '16:9', '21:9', '2:3', '3:2', '4:5', '5:4', '9:16', '9:21'].map((side) => (
                          <Button
                            size="xs"
                            style={{
                              width: '80px',
                              border: '1px solid black',
                              color: image360Params.aspect_ratio === side ? '#f53c02' : 'black',
                              fontSize: '14px',
                            }}
                            color="black"
                            variant={image360Params.aspect_ratio === side ? "default" : 'outline'}
                            onClick={() => {
                              setImage360Params({
                                ...image360Params,
                                aspect_ratio: side,
                              })
                            }}
                          >{side}</Button>
                        ))
                      }
                    </Flex>
                    <Flex gap={12} mt={32} justify={'end'}>
                      <Button
                        styles={{
                          root: {
                            color: 'rgba(230, 230, 230, 1)',
                            height: rem(36),

                            fontSize: '14px',
                            background: '#f53c02 !important',
                            border: 'none',
                            position: 'relative',
                          }
                        }}
                        variant="default"
                        onClick={async () => {
                          const pipeline: PipelineParameters = {
                            provider: 'replicate',
                            type: 'customModel360V1',
                            prompt: image360Params.prompt,
                            aspectRation: image360Params.aspect_ratio,
                            inputSrc: image360Params.url,
                            credits: 10,
                            srcset: []
                          }
                          const res = await runCreatePipeline(pipeline);

                          if (res.error) {
                            NOTIFICATION_SERVICE.errorNotification(
                              "Not enough credits"
                            );
                            setBillingModalOpen(true)
                          } else {
                            setPipelines([{
                              ...res.data,
                              status: res.status
                            }, ...pipelines])

                            setMode(MODE.PIPELINES)
                          }
                        }}
                      >
                        {generateButtton({ credits: priceMap.CUSTOM_MODEL_GEN, intercom: 'customModelGenerateButton' })}
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </>
            )
          }
          {
            mode === MODE.ANY_CUSTOM_MODEL && (
              <>
                <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>

                  <Flex direction={'column'} miw={'300px'} w={'300px'} gap={12}>
                    <Text fz={20}>Create from custom model</Text>

                    <Textarea
                      data-intercom-target={
                        'promptInfo'
                      }
                      w="100%"
                      minRows={7}
                      size="sm"
                      label="Prompt"
                      value={anyCustomModel.prompt}
                      description=""
                      placeholder="Street view"
                      onChange={(e) => {
                        setAnyCustomModel({
                          ...anyCustomModel,
                          prompt: e.currentTarget.value
                        })
                      }}
                    />


                    <Flex gap={12} mt={32} justify={'end'}>
                      <Button
                        styles={{
                          root: {
                            color: 'rgba(230, 230, 230, 1)',
                            height: rem(36),

                            fontSize: '14px',
                            background: '#f53c02 !important',
                            border: 'none',
                            position: 'relative',
                          }
                        }}
                        variant="default"
                        onClick={async () => {
                          const pipeline: PipelineParameters = {
                            provider: 'custom',
                            type: 'customModelV2',
                            parameters: {
                              ...anyCustomModel.parameters,
                              input: {
                                ...anyCustomModel.parameters.input,
                                prompt: anyCustomModel.prompt + anyCustomModel.adding
                              }
                            },
                            credits: 10,
                            srcset: []
                          }
                          const res = await runCreatePipeline(pipeline);

                          if (res.error) {
                            NOTIFICATION_SERVICE.errorNotification(
                              "Not enough credits"
                            );
                            setBillingModalOpen(true)
                          } else {
                            setPipelines([{
                              ...res.data,
                              status: res.status
                            }, ...pipelines])

                            setMode(MODE.PIPELINES)
                          }
                        }}
                      >
                        {generateButtton({ credits: priceMap.CUSTOM_MODEL_GEN, intercom: 'customModelGenerateButton' })}
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </>
            )
          }
          {
            mode === MODE.IMAGE_360_DEEPIX && (
              <>
                <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>

                  <Flex direction={'column'} miw={'300px'} w={'300px'} gap={12}>
                    <Text fz={20}>Create HDRi image</Text>

                    <Flex gap={12} mt={32} justify={'end'}>
                      <Button
                        styles={{
                          root: {
                            color: 'rgba(230, 230, 230, 1)',
                            height: rem(36),

                            fontSize: '14px',
                            background: '#f53c02 !important',
                            border: 'none',
                            position: 'relative',
                          }
                        }}
                        variant="default"
                        onClick={async () => {
                          const pipeline: PipelineParameters = {
                            provider: 'custom',
                            type: 'customModel360HDRV2',
                            prompt: '',
                            inputSrc: image360DeepixParams.url,
                            credits: 10,
                            srcset: []
                          }
                          const res = await runCreatePipeline(pipeline);

                          if (res.error) {
                            NOTIFICATION_SERVICE.errorNotification(
                              "Not enough credits"
                            );
                            setBillingModalOpen(true)
                          } else {
                            setPipelines([{
                              ...res.data,
                              status: res.status
                            }, ...pipelines])

                            // setLatestCustomModelRun({
                            //   srcset: [],
                            //   jobId: res.data.jobId
                            // })
                          }
                        }}
                      >
                        {generateButtton({ credits: priceMap.CUSTOM_MODEL_GEN, intercom: 'customModelGenerateButton' })}
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </>
            )
          }
          {
            mode === MODE.IMAGE_360_BLOCKADE && (
              <>
                <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>

                  <Flex direction={'column'} miw={'300px'} w={'300px'} gap={12}>
                    <Text fz={20}>Create HDRi image Blockade</Text>

                    <Textarea
                      data-intercom-target={
                        'promptInfo'
                      }
                      w="100%"
                      minRows={7}
                      size="sm"
                      label="Prompt"
                      value={blockade.prompt}
                      description=""
                      placeholder="Street view"
                      onChange={(e) => {
                        setBlockade({
                          ...blockade,
                          prompt: e.currentTarget.value
                        })
                      }}
                    />
                    <Flex gap={12} mt={32} justify={'end'}>
                      <Button
                        styles={{
                          root: {
                            color: 'rgba(230, 230, 230, 1)',
                            height: rem(36),

                            fontSize: '14px',
                            background: '#f53c02 !important',
                            border: 'none',
                            position: 'relative',
                          }
                        }}
                        variant="default"
                        onClick={async () => {
                          const pipeline: PipelineParameters = {
                            provider: 'custom',
                            type: 'customModel360HDRV3',
                            prompt: blockade.prompt,
                            id: '',
                            obfuscatedId: '',
                            credits: 10,
                            exportStarted: false,
                            srcset: []
                          }
                          const res = await runCreatePipeline(pipeline);

                          if (res.error) {
                            NOTIFICATION_SERVICE.errorNotification(
                              "Not enough credits"
                            );
                            setBillingModalOpen(true)
                          } else {
                            setPipelines([{
                              ...res.data,
                              status: res.status
                            }, ...pipelines])

                            setMode(MODE.PIPELINES)
                          }
                        }}
                      >
                        {generateButtton({ credits: priceMap.CUSTOM_MODEL_GEN, intercom: 'customModelGenerateButton' })}
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </>
            )
          }
          {
            mode === MODE.SELECT_ANGLE && (

              <>
                <div style={{ position: 'absolute', right: '20px', top: '12px' }}>

                  <Steps active={2} steps={4} />
                </div>
                <Flex gap={12}>

                  <Flex
                    style={{ cursor: 'pointer' }}
                    align={'center'}
                    gap={8}

                    onClick={() => {
                      setImageSrc(null)
                      setMode(MODE.GALLERY)
                    }}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.785 2.965L1.75 6L4.785 9.035" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.25 6H1.83496" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <Text fz={14} >Back</Text>
                  </Flex>
                </Flex>

                <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px', height: 'max-content' }} gap={32}>

                  <Flex direction={'column'} w={'100%'} gap={12}>
                    <Text fz={20}>Generate your new image</Text>

                    <Flex gap={4}>
                      <Flex w={'55%'} gap={4} wrap={'wrap'} justify={'center'}>

                        {
                          (getImageSet(imageSrc!.url)?.images || []).map(itm => (
                            <Flex key={itm.url} justify={'center'} direction={'column'} align={'center'} w={'32%'}
                              style={{
                                border: imageSrc!.url === itm.url ? '2px solid #f53c02' : '2px solid transparent'
                              }}
                              onClick={() => {
                                setImageSrc({
                                  ...imageSrc!,
                                  url: itm.url
                                })
                              }}

                            >
                              <ImageObserved
                                width={'100%'}
                                height={'200px'}
                                dark
                                src={itm.preview}
                              />
                            </Flex>
                          ))
                        }
                        <Button
                          mt={12}
                          size="lg"
                          style={{
                            border: '1px solid black',

                            fontSize: '14px',
                          }}
                          color="rgba(255, 255, 255, 1)"
                          variant={'outline'}
                          c={'black'}
                          onClick={() => {
                            setMode(MODE.DEPTH_MAP)
                            setDepthMapParams({
                              ...depthMapParams,
                              url: imageSrc!.url,
                              url3d: '',
                              repairedUrl3d: '',
                              step: 1,
                            })
                          }}>
                          Choose the image above
                        </Button>

                      </Flex>
                      <Flex w={'44%'}>
                        {
                          imageSrc!.panoramaImage && <Flex justify={'center'} direction={'column'} align={'center'} w={'100%'}>
                            <ImageObserved
                              width={'100%'}
                              height={'300px'}
                              dark
                              src={imageSrc!.panoramaImage!}
                            />
                            <Button
                              mt={12}
                              size="lg"
                              style={{
                                border: '1px solid black',

                                fontSize: '14px',
                              }}
                              color="rgba(255, 255, 255, 1)"
                              variant={'outline'}
                              c={'black'}
                              onClick={() => {
                                setMode(MODE.SELECT_ANGLE_VIEWER)
                              }}>
                              Choose camera Angle from 360
                            </Button>
                          </Flex>
                        }

                      </Flex>
                    </Flex>
                  </Flex>

                </Flex>
              </>

            )
          }  {
            mode === MODE.ADD_CUSTOM_MODEL && (
              <CustomModelLoader back={() => {
                NOTIFICATION_SERVICE.successNotification('Model is being trained')
                setMode(MODE.SELECTED_MODEL)
              }} />
            )
          }
          {
            mode === MODE.SELECT_ANGLE_VIEWER && (

              <>
                <div style={{ position: 'absolute', right: '20px', top: '12px' }}>

                  <Steps active={2} steps={4} />
                </div>
                <Flex gap={12}>

                  <Flex
                    style={{ cursor: 'pointer' }}
                    align={'center'}
                    gap={8}

                    onClick={() => {
                      setImageSrc(null)
                      setMode(MODE.GALLERY)
                    }}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.785 2.965L1.75 6L4.785 9.035" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.25 6H1.83496" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <Text fz={14} >Back</Text>
                  </Flex>
                </Flex>

                <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32} h={'100%'}>

                  <Flex direction={"column"} w={'100%'} justify={'center'} align={'center'}>
                    <Text mb={12} fz={12}>Select the camera angle / scroll mouse to zoom out</Text>
                    <Flex style={{ width: '1000px', height: '800px' }}>
                      <CanvasApp imageSrc={imageSrc!.panoramaImage!}
                      />
                    </Flex>

                    <Flex gap={12} mt={32} justify={'end'}>
                      <Button
                        styles={{
                          root: {
                            color: 'rgba(230, 230, 230, 1)',
                            height: rem(36),

                            fontSize: '14px',
                            background: '#f53c02 !important',
                            border: 'none',
                            position: 'relative',
                          }
                        }}
                        variant="default"
                        onClick={async () => {
                          const canvasParent = document.getElementById('pano') as HTMLCanvasElement
                          const canvas = canvasParent.querySelector('canvas') as HTMLCanvasElement
                          const image = canvas.toDataURL('png')
                          const url = await runAsyncConvertBase64({ url: image })

                          setImageSrc({
                            ...imageSrc!,
                            url: url.url,
                          })

                          setMode(MODE.DEPTH_MAP)
                          setDepthMapParams({
                            ...depthMapParams,
                            url: url.url,
                            url3d: '',
                            repairedUrl3d: '',
                            step: 1,
                          })
                        }}
                      >
                        Select camera angle
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </>

            )
          }
          {
            (mode === MODE.SELECT_MODE || mode === MODE.PICK_IMAGE) && (

              <>
                <div style={{ position: 'absolute', right: '20px', top: '12px' }}>

                  <Steps active={2} steps={4} />
                </div>
                <Flex gap={12}>

                  <Flex
                    style={{ cursor: 'pointer' }}
                    align={'center'}
                    gap={8}


                    onClick={() => {
                      setImageSrc(null)
                      setMode(MODE.GALLERY)
                    }}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.785 2.965L1.75 6L4.785 9.035" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.25 6H1.83496" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <Text fz={14} >Back</Text>
                  </Flex>
                </Flex>

                <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>

                  <Flex direction={'column'} w={'30%'} gap={12}>
                    <Text fz={20}>How do you want to proceed?</Text>

                    {/* <Button
                      size="lg"
                      style={{
                        border: '1px solid black',
                        

                        fontSize: '14px',
                      }}
                      leftIcon={
                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M25.3333 15.9999C25.3333 11.2133 21.4533 7.33325 16.6667 7.33325C11.88 7.33325 8 11.2133 8 15.9999" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M7.32064 6.6534L7.1473 6.48006M26.014 6.6534L26.1873 6.48006L26.014 6.6534ZM16.6673 2.7734V2.66675V2.7734ZM3.44067 16.0001H3.33398H3.44067ZM30.0007 16.0001H29.894H30.0007Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M6 20H27.3333" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M8.66699 24H24.667" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M12.667 28H20.667" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      }
                      color="rgba(255, 255, 255, 1)"
                      variant={'outline'}
c={'black'}
                      onClick={() => {
                        setMode(MODE.RELIGHT)
                        setRelightParams({
                          url: imageSrc?.url,
                          preset: 'sunny',
                          mask: 1,
                        })
                      }}
                    >Relight</Button> */}

                    {
                      imageSrc?.modelVersion && (<>
                        <Button
                          size="lg"

                          style={{
                            border: '1px solid black',
                            height: '58px',

                            fontSize: '14px',
                          }}
                          color="rgba(255, 255, 255, 1)"
                          variant={'outline'}
                          c={'black'}
                          data-intercom-target={
                            'customModelButton'
                          }
                          onClick={async () => {
                            const modelVersion = imageSrc!.modelVersion as string
                            if (models.find(itm => itm.version === modelVersion)) {
                              setSelectedModel(models.find(itm => itm.version === modelVersion))
                              setMode(MODE.SELECTED_MODEL)
                            } else {
                              await addCustomModel({
                                version: modelVersion!,
                              })
                              const res: Model[] = await fetchModels();
                              console.log(res);
                              setModels(res)
                              setSelectedModel(res.find(itm => itm.version === modelVersion))
                              setMode(MODE.ADD_CUSTOM_MODEL)
                            }
                          }}
                        >
                          <Text
                            style={{
                              whiteSpace: 'pre-wrap',
                              lineHeight: '18px',
                              textAlign: 'center',
                            }}>
                            Create new images based on trained location model
                          </Text>

                        </Button>
                        <Text mb={20} fz={12}>
                          The most flexible process that enables you to generate a big variety of images in the same style with the focus on high quality details and textures.
                        </Text>

                      </>)
                    }
                    {
                      mode === MODE.PICK_IMAGE && <><Button
                        size="lg"
                        style={{
                          border: '1px solid black',
                          fontSize: '14px',
                          height: '58px'
                        }}
                        color="rgba(255, 255, 255, 1)"
                        variant={'outline'}
                        c={'black'}
                        data-intercom-target={
                          'generateVariationsButton'
                        }
                        onClick={async () => {
                          const visionRes = await runAsyncVision({ url: imageSrc!.url })
                          setMode(MODE.IMG2IMGFLUX)
                          setImg2imgFluxParams({
                            url: imageSrc!.url,
                            prompt: visionRes?.prompt
                          })
                        }}
                      >
                        <Text
                          style={{
                            whiteSpace: 'pre-wrap',
                            lineHeight: '18px',
                            textAlign: 'center',
                          }}>
                          Generate variations of this image
                        </Text>

                      </Button>
                        <Text mb={20} fz={12}>
                          This process generates 4 variations of the reference image. You can refine the prompt to modify the result.
                        </Text>
                      </>
                    }

                    <Button
                      size="lg"
                      style={{
                        border: '1px solid black',
                        fontSize: '14px',
                        height: '58px'
                      }}
                      color="rgba(255, 255, 255, 1)"
                      variant={'outline'}
                      c={'black'}
                      data-intercom-target={
                        'generateNewButton'
                      }
                      onClick={() => {
                        if (imageSrc?.panoramaImage || imageSrc?.panoramaImage === '') {
                          setMode(MODE.SELECT_ANGLE)
                        } else {
                          setDepthMapParams({
                            ...depthMapParams,
                            url: imageSrc!.url,
                            url3d: '',
                            repairedUrl3d: '',
                            step: 1,
                          })
                          setMode(MODE.DEPTH_MAP)
                        }
                      }}
                    >
                      <Text
                        style={{
                          whiteSpace: 'pre-wrap',
                          lineHeight: '18px',
                          textAlign: 'center',
                        }}>
                        Generate with new camera height & angle (experimental)
                      </Text>
                    </Button>
                    <Text mb={20} fz={12}>
                      Generate new images based on your prompt while keeping the basis composition of the reference image. You can slightly tweak camera heigh and camera angle.
                    </Text>



                    {
                      mode === MODE.PICK_IMAGE && <Button
                        size="lg"
                        style={{
                          border: '1px solid black',


                          fontSize: '14px',
                        }}
                        // leftIcon={
                        //   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        //     <path d="M9.34682 23.9999L4.00016 18.6532C2.21349 16.8666 2.21349 15.0932 4.00016 13.3066L12.9068 4.3999L22.7068 14.2C23.2002 14.6933 23.2002 15.4932 22.7068 15.9866L14.6802 24.0133C12.9202 25.7733 11.1335 25.7733 9.34682 23.9999Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        //     <path d="M11.1338 2.6001L12.9205 4.38672" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        //     <path d="M2.75977 15.8934L22.9198 15.0134" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        //     <path d="M4 29.3333H21.3333" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        //     <path d="M25.1337 20C25.1337 20 22.667 22.68 22.667 24.32C22.667 25.68 23.7737 26.7866 25.1337 26.7866C26.4937 26.7866 27.6003 25.68 27.6003 24.32C27.6003 22.68 25.1337 20 25.1337 20Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        //   </svg>

                        // }

                        color="rgba(255, 255, 255, 1)"
                        variant={'outline'}
                        c={'black'}
                        onClick={() => {
                          setMode(MODE.ENLARGE)
                          setEnlargeParams({
                            url: imageSrc?.url,
                            left: 250,
                            top: 0,
                          })
                        }}
                      >
                        Enlarge
                      </Button>
                    }
                    {
                      mode === MODE.PICK_IMAGE && <Button
                        size="lg"
                        style={{
                          border: '1px solid black',


                          fontSize: '14px',
                        }}
                        // leftIcon={
                        //   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        //     <path d="M9.34682 23.9999L4.00016 18.6532C2.21349 16.8666 2.21349 15.0932 4.00016 13.3066L12.9068 4.3999L22.7068 14.2C23.2002 14.6933 23.2002 15.4932 22.7068 15.9866L14.6802 24.0133C12.9202 25.7733 11.1335 25.7733 9.34682 23.9999Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        //     <path d="M11.1338 2.6001L12.9205 4.38672" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        //     <path d="M2.75977 15.8934L22.9198 15.0134" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        //     <path d="M4 29.3333H21.3333" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        //     <path d="M25.1337 20C25.1337 20 22.667 22.68 22.667 24.32C22.667 25.68 23.7737 26.7866 25.1337 26.7866C26.4937 26.7866 27.6003 25.68 27.6003 24.32C27.6003 22.68 25.1337 20 25.1337 20Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        //   </svg>

                        // }
                        color="rgba(255, 255, 255, 1)"
                        variant={'outline'}
                        c={'black'}
                        onClick={() => {
                          setMode(MODE.UPSCALE)
                          // setEnlargeParams({
                          //   url: imageSrc?.url,
                          //   left: 250,
                          //   top: 0,
                          // })
                        }}
                      >
                        Upscale
                      </Button>
                    }
                    {
                      mode === MODE.PICK_IMAGE && (
                        <Button
                          size="lg"
                          style={{
                            border: '1px solid black',


                            fontSize: '14px',
                          }}
                          color="rgba(255, 255, 255, 1)"
                          variant={'outline'}
                          c={'black'}
                          onClick={() => {
                            setMode(MODE.IMPORT_CAR)
                            setChangeBackgroundParams({
                              backgroundSrc: imageSrc!.url,
                              objectSrc: '',
                              prompt: 'sportcar, day light, high definition, 8K',
                              negativePrompt: 'bad quality, worst quality, blurry',
                              gradientSide: 'left',
                            })
                          }}
                        >Place your car</Button>
                      )
                    }
                    {
                      mode === MODE.PICK_IMAGE && (
                        <Button
                          size="lg"
                          style={{
                            border: '1px solid black',
                            fontSize: '14px',
                          }}
                          color="rgba(255, 255, 255, 1)"
                          variant={'outline'}
                          c={'black'}
                          onClick={async () => {

                            const res = await searchInMaground({ src: imageSrc!.url, })

                            if (res.url) {
                              window.open(res.url, '_blank')
                            } else {
                              NOTIFICATION_SERVICE.errorNotification('Sorry, try again later')
                            }
                          }}
                        >Find similar photography on maground.com
                        </Button>
                      )
                    }



                    {
                      mode === MODE.PICK_IMAGE && secretModeEnabled ?
                        <>
                          <Button
                            size="lg"
                            style={{
                              border: '1px solid black',


                              fontSize: '14px',
                            }}
                            color="rgba(255, 255, 255, 1)"
                            variant={'outline'}
                            c={'black'}
                            onClick={async () => {

                              const visionRes = await runAsyncVision({ url: imageSrc!.url })
                              setImage360Params({
                                prompt: visionRes.prompt + ', camera is 50 meters away, camera is in the center of the image, empty space without objects within a radius of at least 10 meters, 360 view in the style of TOK',
                                url: imageSrc!.url,
                                aspect_ratio: '16:9',
                              })
                              setMode(MODE.IMAGE_360);
                            }}
                          >[beta] Create 360 image</Button>
                          <Button
                            size="lg"
                            style={{
                              border: '1px solid black',
                              fontSize: '14px',
                            }}
                            color="rgba(255, 255, 255, 1)"
                            variant={'outline'}
                            c={'black'}
                            onClick={() => {

                              setImage360DeepixParams({
                                prompt: '',
                                url: imageSrc!.url,
                                aspect_ratio: '1:1',
                              })
                              setMode(MODE.IMAGE_360_DEEPIX);
                            }}
                          >
                            [beta] Сreate HDRi depix
                          </Button>
                        </>
                        : null
                    }


                  </Flex>
                  <Flex justify={'center'} direction={'column'} align={'center'} w={'70%'}>
                    <ImageObserved
                      width={'600px'}
                      height={'600px'}
                      dark={true}
                      src={imageSrc?.url}
                    />
                  </Flex>
                </Flex>
              </>

            )
          }
          {
            mode === MODE.DEPTH_MAP && (
              <>
                <div style={{ position: 'absolute', right: '20px', top: '12px' }}>

                  <Steps active={depthMapParams.step + 2} steps={5} />
                </div>
                <Flex gap={12}>
                  <Flex
                    style={{ cursor: 'pointer' }}
                    align={'center'}
                    gap={8}

                    onClick={() => {
                      setImageSrc(null)
                      setDepthMapParams({
                        url: '',
                        url3d: '',
                        repairedUrl3d: '',
                        prompt: '',
                        negativePrompt: '',
                        step: 1,
                      })
                      setMode(MODE.GALLERY)
                    }}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.785 2.965L1.75 6L4.785 9.035" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.25 6H1.83496" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <Text fz={14} >Back</Text>
                  </Flex>
                </Flex>


                <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>
                  {
                    depthMapParams.step === 1 && (
                      <Flex direction={"column"} w={'100%'} justify={'center'} align={'center'}>
                        <Text mb={12} fz={12}>At this step you can slighly adjust the camera angle. You can skip this step.</Text>
                        <Text mb={12} fz={12}><b>Inside the red frame no large black areas should appear. It would affect the result.</b></Text>
                        <Flex style={{ width: '800px', height: '800px', position: 'relative', background: '#e7e7e7' }}>


                          <div
                            style={{
                              background: '#ff0000',
                              width: '1px',
                              zIndex: '1',
                              height: '400px',
                              left: '50px',
                              top: '200px',
                              position: 'absolute',
                              pointerEvents: 'none',
                            }} />
                          <div
                            style={{
                              background: '#ff0000',
                              width: '1px',
                              zIndex: '1',
                              height: '400px',
                              right: '50px',
                              top: '200px',
                              position: 'absolute',
                              pointerEvents: 'none',
                            }} />

                          <div
                            style={{
                              background: '#ff0000',
                              width: '700px',
                              zIndex: '1',
                              height: '1px',
                              left: '50px',
                              top: '200px',
                              position: 'absolute',
                              pointerEvents: 'none',
                            }} />
                          <div
                            style={{
                              background: '#ff0000',
                              width: '700px',
                              zIndex: '1',
                              height: '1px',
                              left: '50px',
                              bottom: '200px',
                              position: 'absolute',
                              pointerEvents: 'none',
                            }} />


                          <iframe
                            title='preview-frame'
                            name='preview-frame'
                            id="iframeId"
                            src={`https://replicadepth.netlify.app/?url=${depthMapParams.url}`} style={{ width: '100%', height: '100%', border: 'none' }} />
                        </Flex>

                        <Flex gap={12} mt={32} justify={'end'}>
                          <Button
                            styles={{
                              root: {
                                color: 'rgba(230, 230, 230, 1)',
                                height: rem(36),

                                fontSize: '14px',
                                background: '#f53c02 !important',
                                border: 'none',
                                position: 'relative',
                              }
                            }}
                            variant="default"
                            onClick={async () => {
                              setDepthMapParams({
                                ...depthMapParams,
                                url3d: depthMapParams.url,
                                repairedUrl3d: depthMapParams.url,
                                step: 3,
                              })
                            }}
                          >
                            Skip
                          </Button>
                          <Button
                            styles={{
                              root: {
                                color: 'rgba(230, 230, 230, 1)',
                                height: rem(36),

                                fontSize: '14px',
                                background: '#f53c02 !important',
                                border: 'none',
                                position: 'relative',
                              }
                            }}
                            variant="default"
                            onClick={async () => {
                              window.frames['preview-frame']?.postMessage(
                                JSON.stringify({
                                  name: 'GET_SCREENSHOT'
                                }),
                                '*'
                              )
                            }}
                          >
                            Select camera height
                          </Button>
                        </Flex>
                      </Flex>
                    )
                  }
                  {
                    depthMapParams.step === 2 && (
                      <Flex direction={"column"} w={'100%'} justify={'center'} align={'center'}>
                        <Text mb={12} fz={12}>Fill Gaps</Text>
                        <Flex style={{ width: '800px', height: '800px' }}>
                          <ImageObserved
                            width={'800px'}
                            height={'800px'}
                            dark
                            src={depthMapParams.repairedUrl3d || depthMapParams.url3d}
                          />
                        </Flex>

                        <Flex gap={12} mt={32} justify={'end'}>
                          <Button
                            styles={{
                              root: {
                                color: 'rgba(230, 230, 230, 1)',
                                height: rem(36),

                                fontSize: '14px',
                                background: '#f53c02 !important',
                                border: 'none',
                                position: 'relative',
                              }
                            }}
                            variant="default"
                            onClick={async () => {
                              setDepthMapParams({
                                ...depthMapParams,
                                repairedUrl3d: '',
                                url3d: '',
                                step: 1,
                              })
                            }}
                          >
                            Back to camera height
                          </Button>
                          <Button
                            styles={{
                              root: {
                                color: 'rgba(230, 230, 230, 1)',
                                height: rem(36),

                                fontSize: '14px',
                                background: '#f53c02 !important',
                                border: 'none',
                                position: 'relative',
                              }
                            }}
                            variant="default"
                            onClick={async () => {
                              // window.frames['preview-frame']?.postMessage(
                              //   JSON.stringify({
                              //     name: 'GET_SCREENSHOT'
                              //   }),
                              //   '*'
                              // )
                              if (depthMapParams.repairedUrl3d) {
                                // const visionRes = await runAsyncVision({ url: depthMapParams.repairedUrl3d })
                                setDepthMapParams({
                                  ...depthMapParams,
                                  // prompt: visionRes?.prompt,
                                  step: 3,
                                })
                              } else {
                                const res = await runAsyncRepair({ url: depthMapParams.url3d })

                                setDepthMapParams({
                                  ...depthMapParams,
                                  repairedUrl3d: res.url,
                                })
                              }
                            }}
                          >
                            {depthMapParams.repairedUrl3d ? 'Next' : 'Fill Gaps'}
                          </Button>
                        </Flex>
                      </Flex>
                    )
                  }
                  {
                    depthMapParams.step === 3 && (
                      <Flex w={'100%'} gap={12}>

                        <Flex direction={'column'} w={'30%'}>
                          <Textarea
                            w="100%"
                            minRows={7}
                            size="sm"
                            mb={12}
                            label="Prompt"
                            value={depthMapParams.prompt}
                            placeholder="Snow at the mountains"
                            onChange={(e) => {
                              setDepthMapParams({
                                ...depthMapParams,
                                prompt: e.currentTarget.value
                              })
                            }}
                          />
                          {/* <Button
                            variant="default"
                            size={'sm'}
                            onClick={async () => {
                              const visionRes = await runAsyncVision({ url: depthMapParams.repairedUrl3d })
                              setDepthMapParams({
                                ...depthMapParams,
                                prompt: visionRes?.prompt,
                              })
                            }}
                          >
                            Generate prompt from the original
                          </Button> */}

                          <PromptGeneratorButton
                            image={depthMapParams.repairedUrl3d}
                            onSetPrompt={(prompt) => {
                              setDepthMapParams({
                                ...depthMapParams,
                                prompt: prompt,
                              })
                            }}
                          />

                          <Textarea
                            w="100%"
                            mt={24}
                            minRows={5}
                            size="sm"
                            label="Negative prompt"
                            value={depthMapParams.negativePrompt}
                            placeholder="(curves | deformed lines:1.1), people, cars, blurry,(watermark, signature, text font, username, logo, words, letters, digits, trademark), (JPEG artifacts)"
                            onChange={(e) => {
                              setDepthMapParams({
                                ...depthMapParams,
                                negativePrompt: e.currentTarget.value
                              })
                            }}
                          />
                          <Flex gap={6} mt={32} justify={'end'} wrap={'wrap'}>
                            {/* <Button
                              styles={{
                                root: {
                                  color: 'rgba(230, 230, 230, 1)',
                                  height: rem(36),

                                  fontSize: '14px',
                                  background: '#f53c02 !important',
                                  border: 'none',
                                  position: 'relative',
                                }
                              }}
                              variant="default"
                              onClick={() => {
                                setDepthMapParams({
                                  ...depthMapParams,
                                  step: 2,
                                })
                              }}
                            >
                              Back
                            </Button> */}

                            <Button
                              disabled={!depthMapParams.prompt}
                              styles={{
                                root: {
                                  color: 'rgba(230, 230, 230, 1)',
                                  height: rem(36),

                                  fontSize: '14px',
                                  background: '#f53c02 !important',
                                  border: 'none',
                                  position: 'relative',
                                }
                              }}
                              variant="default"
                              onClick={async () => {
                                // const pipeline: PipelineParameters = {
                                //   provider: 'comfy',
                                //   type: 'imgDepthMap',
                                //   prompt: depthMapParams.prompt,
                                //   negativePrompt: depthMapParams.negativePrompt,
                                //   inputSrc: depthMapParams.repairedUrl3d,
                                //   srcset: [],
                                //   credits: 10,
                                // }
                                const pipeline: PipelineParameters = {
                                  provider: 'replicate',
                                  type: 'fluxControlnet',
                                  prompt: depthMapParams.prompt,
                                  negativePrompt: depthMapParams.negativePrompt,
                                  inputSrc: depthMapParams.repairedUrl3d,
                                  srcset: [],
                                  credits: priceMap.CREATE_NEW,
                                }
                                const res = await runCreatePipeline(pipeline);
                                if (res.error) {
                                  NOTIFICATION_SERVICE.errorNotification(
                                    "Not enough credits"
                                  );
                                  setBillingModalOpen(true)
                                } else {
                                  setPipelines([
                                    {
                                      ...res.data,
                                      status: res.status
                                    },
                                    ...pipelines
                                  ])

                                  setDepthMapParams({
                                    prompt: '',
                                    negativePrompt: defaultNegative,
                                    url: '',
                                    url3d: '',
                                    repairedUrl3d: '',
                                    step: 1,
                                  })
                                  setMode(MODE.PIPELINES)
                                }

                              }}
                            >
                              {generateButtton({ credits: priceMap.CREATE_NEW, intercom: 'depthMapGenerateButton' })}
                            </Button>
                            <Button
                              disabled={!depthMapParams.prompt}
                              styles={{
                                root: {
                                  color: 'rgba(230, 230, 230, 1)',
                                  height: rem(36),

                                  fontSize: '14px',
                                  background: '#f53c02 !important',
                                  border: 'none',
                                  position: 'relative',
                                }
                              }}
                              variant="default"
                              onClick={async () => {
                                // const pipeline: PipelineParameters = {
                                //   provider: 'comfy',
                                //   type: 'imgDepthMap',
                                //   prompt: depthMapParams.prompt,
                                //   negativePrompt: depthMapParams.negativePrompt,
                                //   inputSrc: depthMapParams.repairedUrl3d,
                                //   srcset: [],
                                //   credits: 10,
                                // }
                                const pipeline: PipelineParameters = {
                                  provider: 'replicate',
                                  type: 'fluxControlnet',
                                  prompt: depthMapParams.prompt,
                                  negativePrompt: depthMapParams.negativePrompt,
                                  inputSrc: depthMapParams.repairedUrl3d,
                                  srcset: [],
                                  credits: priceMap.CREATE_NEW,
                                }
                                const responses = []
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                for (const itm of Array.from({ length: 5 })) {
                                  const res = await runCreatePipeline(pipeline);
                                  if (res.error) {
                                    NOTIFICATION_SERVICE.errorNotification(
                                      "Not enough credits"
                                    );
                                    setBillingModalOpen(true)
                                  } else {
                                    responses.push({
                                      ...res.data,
                                      status: res.status
                                    })
                                  }
                                }
                                setPipelines([...responses, ...pipelines])
                                setDepthMapParams({
                                  prompt: '',
                                  negativePrompt: defaultNegative,
                                  url: '',
                                  url3d: '',
                                  repairedUrl3d: '',
                                  step: 1,
                                })
                                setMode(MODE.PIPELINES)

                              }}
                            >
                              {generateButtton({ credits: priceMap.CREATE_NEW * 5, text: 'Run 5 generations', intercom: 'depthMapGenerateButton' })}
                            </Button>

                          </Flex>
                        </Flex>
                        <Flex justify={'center'} direction={'column'} align={'center'} w={'70%'}>
                          <ImageObserved
                            width={'600px'}
                            height={'600px'}
                            dark
                            src={depthMapParams.repairedUrl3d}
                          />
                        </Flex>
                      </Flex>
                    )
                  }
                </Flex>
              </>
            )
          }
          {
            mode === MODE.ENLARGE && (
              <>
                <Flex gap={12}>
                  <Flex
                    style={{ cursor: 'pointer' }}
                    align={'center'}
                    gap={8}

                    onClick={() => {
                      setImageSrc(null)
                      setMode(MODE.GALLERY)
                    }}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.785 2.965L1.75 6L4.785 9.035" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.25 6H1.83496" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <Text fz={14} >Back</Text>
                  </Flex>
                </Flex>

                <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>

                  <Flex direction={'column'} w={'30%'} gap={12}>
                    <Text fz={20}>Choose the enlarge option</Text>

                    <Button
                      size="lg"
                      style={{
                        border: '1px solid black',


                        fontSize: '14px',
                      }}
                      leftIcon={
                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="8.61621" y="8.45679" width="15.0866" height="15.0866" stroke="white" />
                          <path d="M2.16003 24.0434V23.5434H1.16699C0.89085 23.5434 0.666992 23.3196 0.666992 23.0434V22.163H0.166992V20.4022H0.666992V18.6414H0.166992V16.8805H0.666992V15.1197H0.166992V13.3589H0.666992V11.598H0.166992V9.8372H0.666992V8.95679C0.666992 8.68065 0.89085 8.45679 1.16699 8.45679H2.16003V7.95679H4.14612V8.45679H6.1322V7.95679H8.11829V8.45679H8.61133V8.9622H9.11133V10.973H8.61133V12.9839H9.11133V14.9947H8.61133V17.0055H9.11133V19.0164H8.61133V21.0272H9.11133V23.038H8.61133V23.5434H8.11829V24.0434H6.1322V23.5434H4.14612V24.0434H2.16003Z" fill="#f85e39" fill-opacity="0.5" stroke="white" stroke-dasharray="2 2" />
                          <path d="M30.1427 24.0434V23.5434H31.1357C31.4119 23.5434 31.6357 23.3196 31.6357 23.0434V22.163H32.1357V20.4022H31.6357V18.6414H32.1357V16.8805H31.6357V15.1197H32.1357V13.3589H31.6357V11.598H32.1357V9.8372H31.6357V8.95679C31.6357 8.68065 31.4119 8.45679 31.1357 8.45679H30.1427V7.95679H28.1566V8.45679H26.1705V7.95679H24.1844V8.45679H23.6914V8.9622H23.1914V10.973H23.6914V12.9839H23.1914V14.9947H23.6914V17.0055H23.1914V19.0164H23.6914V21.0272H23.1914V23.038H23.6914V23.5434H24.1844V24.0434H26.1705V23.5434H28.1566V24.0434H30.1427Z" fill="#f85e39" fill-opacity="0.5" stroke="white" stroke-dasharray="2 2" />
                        </svg>

                      }
                      color="rgba(255, 255, 255, 1)"
                      variant={enlargeParams.left === 250 && enlargeParams.top === 0 ? "default" : 'outline'}
                      onClick={() => {
                        setEnlargeParams({
                          ...enlargeParams,
                          left: 250,
                          top: 0,
                        })
                      }}
                    >On the sides</Button>
                    <Button
                      size="lg"
                      style={{
                        border: '1px solid black',


                        fontSize: '14px',
                      }}
                      leftIcon={
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="23.5273" y="8.4646" width="15.0866" height="15.0866" transform="rotate(90 23.5273 8.4646)" stroke="white" />
                          <path d="M7.9407 2.00891L8.4407 2.00891L8.4407 1.01587C8.4407 0.739726 8.66455 0.515868 8.9407 0.515868L9.82111 0.515869L9.82111 0.0158685L11.5819 0.0158686L11.5819 0.515869L13.3428 0.515869L13.3428 0.0158687L15.1036 0.0158688L15.1036 0.515869L16.8644 0.515869L16.8644 0.0158688L18.6253 0.0158689L18.6253 0.515869L20.3861 0.515869L20.3861 0.015869L22.1469 0.0158691L22.1469 0.515869L23.0273 0.515869C23.3035 0.515869 23.5273 0.739727 23.5273 1.01587L23.5273 2.00891L24.0273 2.00891L24.0273 3.995L23.5273 3.995L23.5273 5.98108L24.0273 5.98108L24.0273 7.96716L23.5273 7.96716L23.5273 8.46021L23.0219 8.46021L23.0219 8.96021L21.0111 8.9602L21.0111 8.4602L19.0003 8.4602L19.0003 8.9602L16.9894 8.9602L16.9894 8.4602L14.9786 8.4602L14.9786 8.9602L12.9678 8.9602L12.9678 8.4602L10.9569 8.4602L10.9569 8.9602L8.94611 8.9602L8.94611 8.4602L8.4407 8.4602L8.4407 7.96716L7.9407 7.96716L7.9407 5.98108L8.4407 5.98108L8.4407 3.99499L7.9407 3.99499L7.9407 2.00891Z" fill="#f85e39" fill-opacity="0.5" stroke="white" stroke-dasharray="2 2" />
                          <path d="M7.9407 29.9913L8.4407 29.9913L8.4407 30.9844C8.4407 31.2605 8.66455 31.4844 8.9407 31.4844L9.82111 31.4844L9.82111 31.9844L11.5819 31.9844L11.5819 31.4844L13.3428 31.4844L13.3428 31.9844L15.1036 31.9844L15.1036 31.4844L16.8644 31.4844L16.8644 31.9844L18.6253 31.9844L18.6253 31.4844L20.3861 31.4844L20.3861 31.9844L22.1469 31.9844L22.1469 31.4844L23.0273 31.4844C23.3035 31.4844 23.5273 31.2605 23.5273 30.9844L23.5273 29.9913L24.0273 29.9913L24.0273 28.0052L23.5273 28.0052L23.5273 26.0192L24.0273 26.0192L24.0273 24.0331L23.5273 24.0331L23.5273 23.54L23.0219 23.54L23.0219 23.04L21.0111 23.04L21.0111 23.54L19.0003 23.54L19.0003 23.04L16.9894 23.04L16.9894 23.54L14.9786 23.54L14.9786 23.04L12.9678 23.04L12.9678 23.54L10.9569 23.54L10.9569 23.04L8.94611 23.04L8.94611 23.54L8.4407 23.54L8.4407 24.0331L7.9407 24.0331L7.9407 26.0192L8.4407 26.0192L8.4407 28.0052L7.9407 28.0052L7.9407 29.9913Z" fill="#f85e39" fill-opacity="0.5" stroke="white" stroke-dasharray="2 2" />
                        </svg>
                      }
                      color="rgba(255, 255, 255, 1)"
                      variant={enlargeParams.left === 0 && enlargeParams.top === 250 ? "default" : 'outline'}
                      onClick={() => {
                        setEnlargeParams({
                          ...enlargeParams,
                          left: 0,
                          top: 250
                        })
                      }}
                    >Above and below</Button>
                    <Button
                      size="lg"
                      style={{
                        border: '1px solid black',


                        fontSize: '14px',
                      }}
                      leftIcon={
                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="0.833984" y="0.5" width="31" height="31" rx="0.5" fill="#f85e39" fill-opacity="0.5" stroke="white" stroke-dasharray="2 2" />
                          <rect x="8.7832" y="8.45654" width="15.0866" height="15.0866" fill="#ffffff" stroke="white" />
                        </svg>

                      }
                      color="rgba(255, 255, 255, 1)"
                      variant={enlargeParams.left === 250 && enlargeParams.top === 250 ? "default" : 'outline'}
                      onClick={() => {
                        setEnlargeParams({
                          ...enlargeParams,
                          left: 250,
                          top: 250,
                        })
                      }}>From all side</Button>
                    <Flex gap={12} mt={32} justify={'end'}>
                      <Button
                        styles={{
                          root: {
                            color: 'rgba(230, 230, 230, 1)',
                            height: rem(36),

                            fontSize: '14px',
                            background: '#f53c02 !important',
                            border: 'none',
                            position: 'relative',
                          }
                        }}
                        variant="default"
                        onClick={async () => {
                          const pipeline: PipelineParameters = {
                            provider: 'replicate',
                            type: 'enlarge',
                            outpaintUp: enlargeParams.top,
                            outpaintDown: enlargeParams.top,
                            outpaintLeft: enlargeParams.left,
                            outpaintRight: enlargeParams.left,
                            inputSrc: enlargeParams.url,
                            outputSrc: '',
                            size: 1,
                            credits: priceMap.OUTPAINT,
                          }
                          const res = await runCreatePipeline(pipeline);

                          if (res.error) {
                            NOTIFICATION_SERVICE.errorNotification(
                              "Not enough credits"
                            );
                            setBillingModalOpen(true)
                          } else {
                            setPipelines([{
                              ...res.data,
                              status: res.status
                            }, ...pipelines])

                            setEnlargeParams({
                              top: 0,
                              left: 250,
                              url: '',
                            })
                            setMode(MODE.PIPELINES)
                          }
                        }}
                      >
                        {generateButtton({ credits: priceMap.OUTPAINT, intercom: 'enlargeGenerateButton' })}
                      </Button>
                    </Flex>
                  </Flex>
                  <Flex justify={'center'} direction={'column'} align={'center'} w={'70%'}>
                    <ImageObserved
                      width={'600px'}
                      height={'600px'}

                      dark
                      src={enlargeParams.url}
                    />
                  </Flex>
                </Flex>
              </>
            )
          }
          {
            mode === MODE.IMPORT_CAR && (
              <>
                <Flex gap={12}>
                  <Flex
                    style={{ cursor: 'pointer' }}
                    align={'center'}
                    gap={8}

                    onClick={() => {
                      setImageSrc(null)
                      setMode(MODE.GALLERY)
                    }}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.785 2.965L1.75 6L4.785 9.035" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.25 6H1.83496" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <Text fz={14} >Back</Text>
                  </Flex>
                </Flex>

                <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>

                  <Flex direction={'column'} w={'30%'} gap={12}>
                    <Text fz={16}>Place your car</Text>
                    <Flex direction={'column'} w={'100%'}>
                      <Textarea
                        w="100%"
                        minRows={7}
                        size="sm"
                        label="Prompt"
                        value={changeBackgroundParams.prompt}
                        placeholder="Snow at the mountains"
                        onChange={(e) => {
                          setChangeBackgroundParams({
                            ...changeBackgroundParams,
                            prompt: e.currentTarget.value
                          })
                        }}
                      />

                      <Textarea
                        w="100%"
                        mt={24}
                        minRows={5}
                        size="sm"
                        label="Negative prompt"
                        value={changeBackgroundParams.negativePrompt}
                        placeholder="(curves | deformed lines:1.1), people, cars, blurry,(watermark, signature, text font, username, logo, words, letters, digits, trademark), (JPEG artifacts)"
                        onChange={(e) => {
                          setChangeBackgroundParams({
                            ...changeBackgroundParams,
                            negativePrompt: e.currentTarget.value
                          })
                        }}
                      />
                    </Flex>
                    <Flex gap={12} mt={32} justify={'end'}>
                      <Button
                        styles={{
                          root: {
                            color: 'rgba(230, 230, 230, 1)',
                            height: rem(36),

                            fontSize: '14px',
                            background: '#f53c02 !important',
                            border: 'none',
                            position: 'relative',
                          }
                        }}
                        variant="default"

                        onClick={async () => {
                          const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
                          setCanvasBig(true)
                          selectShape(null)
                          await sleep(1)
                          const productImage = stageRef.current.toDataURL();
                          setCanvasBig(false)
                          selectShape('rect');
                          await sleep(1)
                          const url = await runAsyncConvertBase64({ url: productImage })
                          const pipeline: PipelineParameters = {
                            provider: 'comfy',
                            type: 'changeBackground3',
                            prompt: changeBackgroundParams.prompt,
                            negativePrompt: changeBackgroundParams.negativePrompt,
                            outputId: '',
                            inputSrc: url.url,
                            backgroundSrc: changeBackgroundParams.backgroundSrc,
                            srcset: [],
                            credits: priceMap.PLACE_CAR,
                          }
                          const res = await runCreatePipeline(pipeline);

                          if (res.error) {
                            NOTIFICATION_SERVICE.errorNotification(
                              "Not enough credits"
                            );
                            setBillingModalOpen(true)
                          } else {
                            setPipelines([{
                              ...res.data,
                              status: res.status
                            }, ...pipelines])

                            setLatestRunId({
                              url: '',
                              jobId: res.data.jobId
                            })
                          }
                        }}
                      >
                        {generateButtton({ credits: priceMap.PLACE_CAR, intercom: 'placeCarGenerateButton' })}
                      </Button>
                    </Flex>
                  </Flex>
                  <Flex justify={'center'} align={'center'} w={'70%'} gap={12}>

                    <Flex w="100%" gap={12} direction={'column'} align={'center'}>
                      <Flex gap={12}>
                        <Flex direction={'column'} gap={6}>
                          <Text color="#f53c02" fz={12}>Object here</Text>
                          <Flex
                            w={`${canvasBig ? canvasSize.big.width : canvasSize.small.width}px`}
                            h={`${canvasBig ? canvasSize.big.height : canvasSize.small.height}px`}
                            style={{
                              border: '2px solid #f53c02',
                              position: 'relative', overflow: 'hidden'
                            }}>
                            <Flex w={'100%'} h={"100%"} style={{ position: 'absolute', top: 0, left: 0, opacity: 0.5 }}>
                              {
                                changeBackgroundParams.backgroundSrc ? <Image

                                  width={'100%'}
                                  height={'100%'}
                                  bg="#000000"
                                  styles={{
                                    root: {
                                      display: 'flex',
                                      alignItems: 'center',
                                    },
                                    image: {
                                      height: '100%'
                                      // borderRadius: '50%',
                                    }
                                  }}
                                  src={changeBackgroundParams.backgroundSrc}
                                  alt=""
                                  // withPlaceholder
                                  fit="contain"
                                /> : null
                              }

                            </Flex>

                            {
                              changeBackgroundParams.objectSrc ? (
                                <Stage
                                  width={canvasBig ? canvasSize.big.width : canvasSize.small.width}
                                  height={canvasBig ? canvasSize.big.height : canvasSize.small.height}
                                  onMouseDown={checkDeselect}
                                  onTouchStart={checkDeselect}
                                  style={{
                                    background: '#000000',
                                  }}
                                  ref={stageRef}
                                >
                                  <Layer>
                                    {
                                      canvasBig && <Rect width={canvasSize.big.width} height={canvasSize.big.height} x={0} y={0} listening={false} fill={'#ffffff'}></Rect>
                                    }

                                    {rectangles.map((rect, i) => {
                                      if (changeBackgroundParams.objectSrc) {
                                        return (
                                          <Rectangle
                                            key={i}
                                            big={canvasBig}
                                            shapeProps={{
                                              ...rect,
                                              height: canvasBig ? rect.height * 5 : rect.height,
                                              width: canvasBig ? rect.width * 5 : rect.width,
                                              x: canvasBig ? rect.x * 5 : rect.x,
                                              y: canvasBig ? rect.y * 5 : rect.y,
                                            }}
                                            image={changeBackgroundParams.objectSrc}
                                            isSelected={rect.id === selectedId}
                                            onSelect={() => {
                                              // selectShape(rect.id);
                                            }}
                                            onChange={(newAttrs: any) => {
                                              const rects = rectangles.slice();
                                              rects[i] = newAttrs;
                                              setRectangles(rects);
                                            }}
                                          />
                                        );
                                      } else {
                                        return null
                                      }
                                    })}

                                  </Layer>
                                </Stage>
                              ) :
                                <Flex gap={2} justify={'center'} w={'100%'} mt={20} mb={20} align={'center'} style={{ zIndex: 10 }}>
                                  <Flex w="100%" justify={'center'} >
                                    <Widget
                                      imagesOnly
                                      localeTranslations={{
                                        buttons: {
                                          choose: {
                                            images: {
                                              one: 'upload object'
                                            },
                                          },
                                        },
                                      }}
                                      clearable={false}
                                      // @ts-ignore
                                      onChange={async (info: {
                                        crop?: {
                                          width: number
                                          height: number
                                        },
                                        cdnUrl: string,
                                        originalImageInfo: {
                                          width: number
                                          height: number
                                        }
                                      }) => {
                                        //   const id = spaceStore.selected?.id
                                        setCustomLoading(true)
                                        console.log(info)
                                        // info.originalImageInfo.width > 
                                        const res = await runAsyncConvert({ url: info.cdnUrl, removeBackground: true })

                                        const width = info.crop?.width || info.originalImageInfo.width
                                        const height = info.crop?.height || info.originalImageInfo.height

                                        const ratio = width > height ? height / width : width / height
                                        // maximum height and width is 400
                                        setRectangles([{
                                          ...initialRectangles[0],
                                          width: width > height ? 390 : 390 * ratio,
                                          height: width > height ? 390 * ratio : 390,
                                          x: 5,
                                          y: 5,
                                        }])
                                        setCustomLoading(false)
                                        setChangeBackgroundParams({
                                          ...changeBackgroundParams,
                                          objectSrc: res.url,
                                        })
                                      }}
                                      tabs={"file camera url"}
                                      publicKey="35626f59762b63c4244c"
                                      previewStep={true}
                                      crop="free, 16:9, 4:3, 5:4, 1:1"
                                    />
                                  </Flex>

                                </Flex>
                            }
                          </Flex>

                        </Flex>

                        <Flex direction={'column'} gap={6}>
                          <Text color="#f53c02" fz={12}>Generation result here</Text>
                          <Flex

                            w={canvasSize.small.width}
                            h={canvasSize.small.height}
                            style={{ border: '2px solid #f53c02' }}>
                            {
                              latestRunId ? (
                                latestRunId?.url ? (
                                  <ImageObserved
                                    width={canvasSize.small.width + 'px'}
                                    height={canvasSize.small.height + 'px'}
                                    src={latestRunId?.url}
                                  />
                                ) : (
                                  <Flex w="100%" h="100%" p={32} align={'center'} justify={'center'} direction={'column'}>

                                    <Text color="#f53c02" fz={12}>Will be ready in 1 minute or less</Text>
                                    <Progress radius="lg" size="lg" color="#f53c02" value={100} striped animate={true} w={'100%'} />
                                  </Flex>
                                )
                              ) : null
                            }
                          </Flex>
                        </Flex>
                      </Flex>
                      <Flex justify={'space-between'} w="100%" style={{ maxWidth: '812px' }}>
                        <Flex>
                          {
                            changeBackgroundParams.objectSrc ? (
                              <Flex justify={'space-between'} align={'center'} w="100%" pl={12} pr={12}>
                                <Flex direction={'column'}>
                                  <Flex w="100px">
                                    <ImageObserved
                                      width={'100px'}
                                      height={'100px'}
                                      src={changeBackgroundParams.objectSrc}
                                    />
                                  </Flex>
                                  <Text fz={14}

                                    align="center"
                                  >
                                    Object image
                                  </Text>
                                  <Button
                                    styles={{
                                      root: {
                                        color: '#f53c02',
                                        height: rem(28),

                                        fontSize: '10px',
                                        background: 'transparent !important',
                                        border: 'none',
                                        position: 'relative',
                                      }
                                    }}
                                    variant="default"
                                    onClick={async () => {
                                      setChangeBackgroundParams({
                                        ...changeBackgroundParams,
                                        objectSrc: ''
                                      })
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </Flex>

                              </Flex>
                            ) : (
                              null
                            )
                          }
                          {
                            changeBackgroundParams.backgroundSrc && (

                              <Flex justify={'space-between'} align={'center'} w="100%" pl={12} pr={12}>
                                <Flex direction={'column'}>
                                  <Flex w="100px">
                                    <ImageObserved
                                      width={'100px'}
                                      height={'100px'}
                                      src={changeBackgroundParams.backgroundSrc}
                                    />
                                  </Flex>
                                  <Text fz={14}
                                    align="center"
                                  >Background
                                  </Text>
                                  <Button
                                    styles={{
                                      root: {
                                        color: '#f53c02',
                                        height: rem(28),

                                        fontSize: '10px',
                                        background: 'transparent !important',
                                        border: 'none',
                                        position: 'relative',
                                      }
                                    }}
                                    variant="default"
                                    onClick={async () => {

                                      setChangeBackgroundParams({
                                        ...changeBackgroundParams,
                                        backgroundSrc: ''
                                      })
                                    }}
                                  >
                                    Delete
                                  </Button>

                                </Flex>

                              </Flex>
                            )
                          }
                        </Flex>
                      </Flex>
                      <Flex>
                      </Flex>

                    </Flex>

                    {/* <Flex w={'44%'} direction={'column'}>
                      <Text fz={14} >Object image</Text>
                      <ImageObserved
                        width={'100%'}
                        height={'500px'}
                        dark
                        src={changeBackgroundParams.objectSrc}
                      />
                    </Flex>
                    <Flex w='44%' direction={'column'}>
                      <Text fz={14} >Background image</Text>
                      <ImageObserved
                        width={'100%'}
                        height={'500px'}
                        dark
                        src={changeBackgroundParams.backgroundSrc}
                      />
                    </Flex> */}
                  </Flex>
                </Flex>
              </>
            )
          }
          {
            mode === MODE.RELIGHT && (
              <>
                <Flex gap={12}>
                  <Flex
                    style={{ cursor: 'pointer' }}
                    align={'center'}
                    gap={8}

                    onClick={() => {
                      setImageSrc(null)
                      setMode(MODE.GALLERY)
                    }}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.785 2.965L1.75 6L4.785 9.035" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.25 6H1.83496" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <Text fz={14} >Back</Text>
                  </Flex>
                </Flex>

                <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>

                  <Flex direction={'column'} w={'30%'} gap={12}>
                    <Text fz={20} >Select the position of the light</Text>
                    {
                      Object.keys(RELIGHT_PROMPS).map((key) => (

                        <Button
                          size="lg"
                          style={{
                            border: '1px solid black',

                            fontSize: '14px',
                          }}
                          color="rgba(255, 255, 255, 1)"
                          variant={relightParams.preset === key ? "default" : 'outline'}
                          onClick={() => {
                            setRelightParams({
                              ...relightParams,
                              preset: key,
                            })
                          }}>{RELIGHT_PROMPS[key]}
                        </Button>

                      ))
                    }

                    <Flex gap={12} mt={32} justify={'end'}>
                      <Button
                        styles={{
                          root: {
                            color: 'rgba(230, 230, 230, 1)',
                            height: rem(36),

                            fontSize: '14px',
                            background: '#f53c02 !important',
                            border: 'none',
                            position: 'relative',
                          },
                        }}
                        variant="default"
                        onClick={async () => {
                          const pipeline: PipelineParameters = {
                            provider: 'comfy',
                            type: 'relightMask',
                            inputSrc: relightParams.url,
                            maskSrc: 'https://ugcr2.klykn.com/eu/Q3kYUdjhkdZf423LZf9kW_J2mCOo90zpK5NWeNlXHpp',
                            // @ts-ignore
                            prompt: RELIGHT_PROMPS[relightParams.preset],
                            negativePrompt: 'bad quality',
                            srcset: [],
                            credits: 10,
                          }
                          const res = await runCreatePipeline(pipeline);

                          if (res.error) {
                            NOTIFICATION_SERVICE.errorNotification(
                              "Not enough credits"
                            );
                            setBillingModalOpen(true)
                          } else {
                            setPipelines([{
                              ...res.data,
                              status: res.status
                            }, ...pipelines])

                            setRelightParams({
                              url: '',
                              preset: 'sunny',
                              mask: 1,
                            })
                            setMode(MODE.PIPELINES)
                          }


                        }}
                      >
                        {generateButtton({ credits: 10, intercom: 'relightGenerateButton' })}
                      </Button>
                    </Flex>
                  </Flex>
                  <Flex justify={'center'} direction={'column'} align={'center'} w={'70%'} pos="relative">
                    <ImageObserved
                      width={'600px'}
                      height={'600px'}

                      dark
                      src={relightParams.url}

                    />
                    <Flex pos="absolute" top={0} left={0} w={'100%'} h="100%" justify={'space-between'} align={'center'} wrap={"wrap"}>
                      {
                        Array.from({ length: 9 }).map((_, idx) => (
                          <Flex
                            w={'33.3%'}
                            h="33.3%"
                            p={12}
                            opacity={0.2}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setRelightParams({
                                ...relightParams,
                                mask: idx + 1,
                              })
                            }}>
                            <Flex w={'100%'} h="100%" style={{ background: relightParams.mask === idx + 1 ? '#f53c02' : 'white' }}></Flex>
                          </Flex>
                        ))
                      }


                    </Flex>
                  </Flex>
                </Flex>
              </>
            )
          }
          {
            mode === MODE.UPSCALE && (
              <>
                <Flex gap={12}>
                  <Flex
                    style={{ cursor: 'pointer' }}
                    align={'center'}
                    gap={8}

                    onClick={() => {
                      setImageSrc(null)
                      setMode(MODE.GALLERY)
                    }}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.785 2.965L1.75 6L4.785 9.035" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.25 6H1.83496" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <Text fz={14} >Back</Text>
                  </Flex>
                </Flex>

                <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>

                  <Flex direction={'column'} w={'30%'} gap={12}>

                    <Text fz={20}>Upscale image</Text>
                    {/* <Flex gap={12} mt={32}>
                      <Button
                        styles={{
                          root: {
                            color: 'rgba(230, 230, 230, 1)',
                            height: rem(36),

                            fontSize: '14px',
                            background: '#f53c02 !important',
                            border: 'none',
                            position: 'relative',
                          },
                        }}
                        variant="default"
                        onClick={async () => {
                          const pipeline: PipelineParameters = {
                            provider: 'replicate',
                            type: 'upscale',
                            inputSrc: imageSrc!.url,
                            outputSrc: '',
                            credits: priceMap.UPSCALE,
                          }
                          const res = await runCreatePipeline(pipeline);

                          if (res.error) {
                            NOTIFICATION_SERVICE.errorNotification(
                              "Not enough credits"
                            );
                            setBillingModalOpen(true)
                          } else {
                            setPipelines([{
                              ...res.data,
                              status: res.status
                            }, ...pipelines])

                            setImageSrc(null)
                            setMode(MODE.PIPELINES)
                          }
                        }}
                      >
                        [clarity] 4k {generateButtton(priceMap.UPSCALE)}
                      </Button>
                    </Flex>

                    <Flex gap={12} mt={32}>
                      <Button
                        styles={{
                          root: {
                            color: 'rgba(230, 230, 230, 1)',
                            height: rem(36),

                            fontSize: '14px',
                            background: '#f53c02 !important',
                            border: 'none',
                            position: 'relative',
                          },
                        }}
                        variant="default"
                        onClick={async () => {
                          const pipeline: PipelineParameters = {
                            provider: 'falai',
                            type: 'upscaleV1',
                            inputSrc: imageSrc!.url,
                            outputSrc: '',
                            credits: priceMap.UPSCALE,
                          }
                          const res = await runCreatePipeline(pipeline);

                          if (res.error) {
                            NOTIFICATION_SERVICE.errorNotification(
                              "Not enough credits"
                            );
                            setBillingModalOpen(true)
                          } else {
                            setPipelines([{
                              ...res.data,
                              status: res.status
                            }, ...pipelines])

                            setImageSrc(null)
                            setMode(MODE.PIPELINES)
                          }
                        }}
                      >
                        [aura sr] 4k {generateButtton(priceMap.UPSCALE)}
                      </Button>
                    </Flex> */}
                    {
                      [{
                        type: '4k',
                        credits: 10
                      }, {
                        type: '6k',
                        credits: 20
                      }, {
                        type: '8k',
                        credits: 40
                      }].map((type) => (
                        <Flex gap={12} mt={32}>
                          <Button
                            styles={{
                              root: {
                                color: 'rgba(230, 230, 230, 1)',
                                height: rem(36),

                                fontSize: '14px',
                                background: '#f53c02 !important',
                                border: 'none',
                                position: 'relative',
                              },
                            }}
                            variant="default"
                            onClick={async () => {
                              const pipeline: PipelineParameters = {
                                provider: 'custom',
                                type: 'upscaleV1',
                                inputSrc: imageSrc!.url,
                                upscaleType: type.type as any,
                                outputSrc: '',
                                credits: type.credits,
                              }
                              const res = await runCreatePipeline(pipeline);

                              if (res.error) {
                                NOTIFICATION_SERVICE.errorNotification(
                                  "Not enough credits"
                                );
                                setBillingModalOpen(true)
                              } else {
                                setPipelines([{
                                  ...res.data,
                                  status: res.status
                                }, ...pipelines])

                                setImageSrc(null)
                                setMode(MODE.PIPELINES)
                              }
                            }}
                          >
                            {generateButtton({ credits: type.credits, text: 'Upscale to ' + type.type, intercom: type.type + 'upscaleGenerateButton' })}
                          </Button>
                        </Flex>))
                    }

                  </Flex>
                  <Flex justify={'center'} direction={'column'} align={'center'} w={'70%'}>
                    <ImageObserved
                      width={'600px'}
                      height={'600px'}
                      dark
                      src={imageSrc?.url}
                    />
                  </Flex>
                </Flex>
              </>
            )
          }

          {
            mode === MODE.IMG2IMGFLUX && (
              <>
                <Flex gap={12}>
                  <Flex
                    style={{ cursor: 'pointer' }}
                    align={'center'}
                    gap={8}

                    onClick={() => {
                      setImageSrc(null)
                      setMode(MODE.GALLERY)
                    }}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.785 2.965L1.75 6L4.785 9.035" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.25 6H1.83496" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <Text fz={14} >Back</Text>
                  </Flex>
                </Flex>

                <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>

                  <Flex direction={'column'} w={'30%'} gap={12}>

                    <Text fz={20}>Make variations</Text>
                    <Flex direction={'column'} w={'100%'}>
                      <Textarea
                        w="100%"
                        minRows={7}
                        size="sm"
                        label="Prompt"
                        value={img2imgFluxParams.prompt}
                        placeholder="Morern villa"
                        onChange={(e) => {
                          setImg2imgFluxParams({
                            ...img2imgFluxParams,
                            prompt: e.currentTarget.value
                          })
                        }}
                      />
                    </Flex>
                    {/* <Button
                      variant="default"
                      size={'sm'}
                      onClick={async () => {
                        const visionRes = await runAsyncVision({ url: img2imgFluxParams.url })
                        setImg2imgFluxParams({
                          ...img2imgFluxParams,
                          prompt: visionRes?.prompt,
                        })
                      }}
                    >
                      Generate prompt from the original
                    </Button> */}
                    <PromptGeneratorButton
                      image={img2imgFluxParams.url}
                      onSetPrompt={(prompt) => {
                        setImg2imgFluxParams({
                          ...img2imgFluxParams,
                          prompt: prompt
                        })
                      }}
                    />
                    <Flex gap={12} mt={32}>
                      <Button
                        disabled={!img2imgFluxParams.prompt}
                        styles={{
                          root: {
                            color: 'rgba(230, 230, 230, 1)',
                            height: rem(36),

                            fontSize: '14px',
                            background: '#f53c02 !important',
                            border: 'none',
                            position: 'relative',
                          },
                        }}
                        variant="default"
                        onClick={async () => {
                          const pipeline: PipelineParameters = {
                            provider: 'falai',
                            type: 'img2imgflux',
                            inputSrc: img2imgFluxParams.url,
                            srcset: [],
                            credits: priceMap.VARIATIONS,
                            prompt: img2imgFluxParams.prompt,
                            negativePrompt: ''
                          }
                          const res = await runCreatePipeline(pipeline);

                          if (res.error) {
                            NOTIFICATION_SERVICE.errorNotification(
                              "Not enough credits"
                            );
                            setBillingModalOpen(true)
                          } else {
                            setPipelines([{
                              ...res.data,
                              status: res.status
                            }, ...pipelines])

                            setImg2imgFluxParams({
                              url: '',
                              prompt: '',
                            })
                            setMode(MODE.PIPELINES)
                          }
                        }}
                      >
                        {generateButtton({ credits: priceMap.VARIATIONS, intercom: 'createVariationsGenerateButton' })}
                      </Button>
                    </Flex>
                  </Flex>
                  <Flex justify={'center'} direction={'column'} align={'center'} w={'70%'}>
                    <ImageObserved
                      width={'600px'}
                      height={'600px'}
                      dark
                      src={img2imgFluxParams.url}
                    />
                  </Flex>
                </Flex>
              </>
            )
          }

          {
            mode === MODE.GEN_SIMILAR && (
              <>
                <div style={{ position: 'absolute', right: '20px', top: '12px' }}>

                  <Steps active={genSimilarParams.step + 2} steps={5} />
                </div>
                <Flex gap={12}>
                  <Flex
                    style={{ cursor: 'pointer' }}
                    align={'center'}
                    gap={8}

                    onClick={() => {
                      setImageSrc(null)
                      setGenSimilarParams({
                        url: '',
                        url3d: '',
                        repairedUrl3d: '',
                        negativePrompt: '',
                        prompt: '',
                        step: 1,
                      })
                      setMode(MODE.GALLERY)
                    }}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.785 2.965L1.75 6L4.785 9.035" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10.25 6H1.83496" stroke="#BEBEBE" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <Text fz={14} >Back</Text>
                  </Flex>
                </Flex>


                <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>
                  {
                    genSimilarParams.step === 1 && (
                      <Flex direction={"column"} w={'100%'} justify={'center'} align={'center'}>
                        <Text mb={12} fz={12}>At this step you can slighly adjust the camera angle. You can skip this step.</Text>
                        <Flex style={{ width: '800px', height: '800px' }}>
                          <iframe
                            title='preview-frame'
                            name='preview-frame'
                            id="iframeId"
                            src={`https://replicadepth.netlify.app/?url=${genSimilarParams.url}`} style={{ width: '100%', height: '100%', border: 'none' }} />
                        </Flex>

                        <Flex gap={12} mt={32} justify={'end'}>
                          <Button
                            styles={{
                              root: {
                                color: 'rgba(230, 230, 230, 1)',
                                height: rem(36),

                                fontSize: '14px',
                                background: '#f53c02 !important',
                                border: 'none',
                                position: 'relative',
                              }
                            }}
                            variant="default"
                            onClick={async () => {
                              setGenSimilarParams({
                                ...genSimilarParams,
                                url3d: genSimilarParams.url,
                                repairedUrl3d: genSimilarParams.url,
                                step: 3,
                              })
                            }}
                          >
                            Skip
                          </Button>
                          <Button
                            styles={{
                              root: {
                                color: 'rgba(230, 230, 230, 1)',
                                height: rem(36),

                                fontSize: '14px',
                                background: '#f53c02 !important',
                                border: 'none',
                                position: 'relative',
                              }
                            }}
                            variant="default"
                            onClick={async () => {
                              window.frames['preview-frame']?.postMessage(
                                JSON.stringify({
                                  name: 'GET_SCREENSHOT'
                                }),
                                '*'
                              )
                            }}
                          >
                            Select camera height
                          </Button>
                        </Flex>
                      </Flex>
                    )
                  }
                  {
                    genSimilarParams.step === 2 && (
                      <Flex direction={"column"} w={'100%'} justify={'center'} align={'center'}>
                        <Text mb={12} fz={12}>Fill Gaps</Text>
                        <Flex style={{ width: '800px', height: '800px' }}>
                          <ImageObserved
                            width={'800px'}
                            height={'800px'}
                            dark
                            src={genSimilarParams.repairedUrl3d || genSimilarParams.url3d}
                          />
                        </Flex>

                        <Flex gap={12} mt={32} justify={'end'}>
                          <Button
                            styles={{
                              root: {
                                color: 'rgba(230, 230, 230, 1)',
                                height: rem(36),

                                fontSize: '14px',
                                background: '#f53c02 !important',
                                border: 'none',
                                position: 'relative',
                              }
                            }}
                            variant="default"
                            onClick={async () => {
                              setGenSimilarParams({
                                ...genSimilarParams,
                                repairedUrl3d: '',
                                url3d: '',
                                step: 1,
                              })
                            }}
                          >
                            Back to camera height
                          </Button>
                          <Button
                            styles={{
                              root: {
                                color: 'rgba(230, 230, 230, 1)',
                                height: rem(36),

                                fontSize: '14px',
                                background: '#f53c02 !important',
                                border: 'none',
                                position: 'relative',
                              }
                            }}
                            variant="default"
                            onClick={async () => {
                              if (genSimilarParams.repairedUrl3d) {
                                setGenSimilarParams({
                                  ...genSimilarParams,
                                  step: 3,
                                })
                              } else {
                                const res = await runAsyncRepair({ url: genSimilarParams.url3d })

                                setGenSimilarParams({
                                  ...genSimilarParams,
                                  repairedUrl3d: res.url,
                                })
                              }
                            }}
                          >
                            {genSimilarParams.repairedUrl3d ? 'Next' : 'Fill Gaps'}
                          </Button>
                        </Flex>
                      </Flex>
                    )
                  }
                  {
                    genSimilarParams.step === 3 && (
                      <Flex w={'100%'} gap={12}>

                        <Flex direction={'column'} w={'30%'}>
                          <Textarea
                            w="100%"
                            minRows={7}
                            size="sm"
                            label="Prompt"
                            value={genSimilarParams.prompt}
                            placeholder="Snow at the mountains"
                            onChange={(e) => {
                              setGenSimilarParams({
                                ...genSimilarParams,
                                prompt: e.currentTarget.value
                              })
                            }}
                          />

                          <Textarea
                            w="100%"
                            mt={24}
                            minRows={5}
                            size="sm"
                            label="Negative prompt"
                            value={genSimilarParams.negativePrompt}
                            placeholder="(curves | deformed lines:1.1), people, cars, blurry,(watermark, signature, text font, username, logo, words, letters, digits, trademark), (JPEG artifacts)"
                            onChange={(e) => {
                              setGenSimilarParams({
                                ...genSimilarParams,
                                negativePrompt: e.currentTarget.value
                              })
                            }}
                          />
                          <Flex gap={6} mt={32} justify={'end'} wrap={'wrap'}>
                            <Button
                              disabled={!genSimilarParams.prompt}

                              styles={{
                                root: {
                                  color: 'rgba(230, 230, 230, 1)',
                                  height: rem(36),

                                  fontSize: '14px',
                                  background: '#f53c02 !important',
                                  border: 'none',
                                  position: 'relative',
                                }
                              }}
                              variant="default"
                              onClick={async () => {
                                const pipeline: PipelineParameters = {
                                  provider: 'replicate',
                                  type: 'fluxControlnet',
                                  prompt: genSimilarParams.prompt,
                                  negativePrompt: genSimilarParams.negativePrompt,
                                  inputSrc: genSimilarParams.repairedUrl3d,
                                  srcset: [],
                                  credits: priceMap.CREATE_NEW,
                                }
                                const res = await runCreatePipeline(pipeline);
                                if (res.error) {
                                  NOTIFICATION_SERVICE.errorNotification(
                                    "Not enough credits"
                                  );
                                  setBillingModalOpen(true)
                                } else {
                                  setPipelines([
                                    {
                                      ...res.data,
                                      status: res.status
                                    },
                                    ...pipelines
                                  ])

                                  setGenSimilarParams({
                                    prompt: '',
                                    negativePrompt: defaultNegative,
                                    url: '',
                                    url3d: '',
                                    repairedUrl3d: '',
                                    step: 1,
                                  })
                                  setMode(MODE.PIPELINES)
                                }

                              }}
                            >
                              {generateButtton({ credits: priceMap.CREATE_NEW, intercom: 'depthMapFluxGenerateButton' })}
                            </Button>
                          </Flex>
                        </Flex>
                        <Flex justify={'center'} direction={'column'} align={'center'} w={'70%'}>
                          <ImageObserved
                            width={'600px'}
                            height={'600px'}
                            dark
                            src={genSimilarParams.repairedUrl3d}
                          />
                        </Flex>
                      </Flex>
                    )
                  }
                </Flex>
              </>
            )
          }


          <Flex
            direction={'column'}
            w="100%"
            gap={8}
            style={{
              opacity: mode === MODE.PIPELINES ? 1 : 0,
              visibility: mode === MODE.PIPELINES ? 'visible' : 'hidden',
              height: mode === MODE.PIPELINES ? undefined : '0',
              overflow: mode === MODE.PIPELINES ? 'visible' : 'hidden',
            }}>
            <Flex justify={'flex-end'}>
              <Text
                style={{ cursor: 'pointer' }}

                fz={14}
                onClick={() => {
                  setBig(!big)
                }}>
                {
                  big ? 'View small preview' : 'View big preview'
                }
              </Text>
            </Flex>

            {
              pipelines
                // .slice(0, 60)
                .map((pipeline, index) => (
                  <Flex direction={'column'} key={pipeline.jobId} >
                    <PipelineItem
                      addFeatured={async (jobId) => {
                        await addToFeatured({
                          id: jobId,
                        })

                        NOTIFICATION_SERVICE.successNotification('Added to featured, refresh page')
                      }}
                      create360={async (image) => {
                        const visionRes = await runAsyncVision({ url: image })
                        setImage360Params({
                          prompt: visionRes.prompt + ', camera is 50 meters away, camera is in the center of the image, empty space without objects within a radius of at least 10 meters, 360 view in the style of TOK',
                          url: image,
                          aspect_ratio: '16:9',
                        })
                        setMode(MODE.IMAGE_360);
                      }}
                      create360V2={async (image) => {
                        const visionRes = await runAsyncVision({ url: image })
                        setImage360Params({
                          prompt: visionRes.prompt + ', camera is 50 meters away, camera is in the center of the image, empty space without objects within a radius of at least 10 meters, 360 view in the style of TOK',
                          url: '',
                          aspect_ratio: '16:9',
                        })
                        setMode(MODE.IMAGE_360);
                      }}
                      create360Blockade={async (image) => {
                        const visionRes = await runAsyncVision({ url: image })
                        setBlockade({
                          prompt: visionRes.prompt,
                        })
                        setMode(MODE.IMAGE_360_BLOCKADE);

                      }}
                      fixWhiteBalance={async (image) => {
                        const pipeline: PipelineParameters = {
                          provider: 'custom',
                          type: 'fixWhiteBalance',
                          inputSrc: image,
                          credits: 10,
                          srcset: []
                        }
                        const res = await runCreatePipeline(pipeline);

                        if (res.error) {
                          NOTIFICATION_SERVICE.errorNotification(
                            "Not enough credits"
                          );
                          setBillingModalOpen(true)
                        } else {
                          setPipelines([{
                            ...res.data,
                            status: res.status
                          }, ...pipelines])

                          setMode(MODE.PIPELINES)
                        }
                      }}
                      searchInMaground={async (image) => {
                        const res = await searchInMaground({ src: image })

                        if (res.url) {
                          window.open(res.url, '_blank')
                        } else {
                          NOTIFICATION_SERVICE.errorNotification('Sorry, try again later')
                        }

                      }}
                      create360DHR={(image) => {
                        setImage360DeepixParams({
                          prompt: '',
                          url: image,
                          aspect_ratio: '1:1',
                        })
                        setMode(MODE.IMAGE_360_DEEPIX);
                      }}
                      secretModeEnabled={secretModeEnabled}
                      openCustomModelVersion={(version) => {
                        setSelectedModel(models.find(itm => itm.version === version))
                        setMode(MODE.SELECTED_MODEL)
                      }}
                      openBillingModal={() => {
                        setBillingModalOpen(true)
                      }}
                      big={big}
                      dark={index % 2 === 0}
                      pipeline={pipeline}
                      update={(pipeline: PipelineData) => onPipelineUpdate(pipeline, index)}
                      onUpscale={(image) => {
                        setImageSrc({
                          panoramaImage: null,
                          url: image,
                          modelVersion: null
                        });
                        setMode(MODE.UPSCALE)
                      }}
                      pickImage={(image) => {
                        setImageSrc({
                          panoramaImage: null,
                          url: image,
                          modelVersion: null
                        });
                        setMode(MODE.PICK_IMAGE);
                      }}
                      makeVariations={async (image) => {
                        const visionRes = await runAsyncVision({ url: image })
                        setMode(MODE.IMG2IMGFLUX)
                        setImg2imgFluxParams({
                          url: image,
                          prompt: visionRes?.prompt
                        })
                      }}
                      placeCar={(image) => {
                        setChangeBackgroundParams({
                          ...changeBackgroundParams,
                          backgroundSrc: image,
                        })
                        setMode(MODE.IMPORT_CAR);
                      }}
                      relightGradient={(image) => {
                        // setRelightParams({
                        //   url: image,
                        //   preset: 'sunny',
                        // });
                        // // setImageSrc(image);
                        // setMode(MODE.RELIGHT);
                        // setPrompt('Concrete building, abstract, soft studio light, masterpiece, (masterpiece:1.2), (best quality, highest quality)');
                        // setNegativePrompt('bad quality, bad picture');
                      }}
                      relightMask={(image) => {
                        console.log(image)
                        setRelightParams({
                          url: image,
                          preset: 'sunny',
                          mask: 1,
                        });
                        // setImageSrc(image);
                        setMode(MODE.RELIGHT);
                      }}
                      onEnlarge={(image) => {
                        setEnlargeParams({
                          ...enlargeParams,
                          url: image
                        })
                        setMode(MODE.ENLARGE)
                      }}

                      create={async (pipeline: PipelineParameters) => {
                        const res = await runCreatePipeline(pipeline);
                        if (res.error) {
                          NOTIFICATION_SERVICE.errorNotification(
                            "Not enough credits"
                          );
                          setBillingModalOpen(true)
                        } else {
                          setPipelines([res.data, ...pipelines,])
                          NOTIFICATION_SERVICE.successNotification('Generation started')
                        }

                      }}
                    />
                  </Flex>
                ))
            }
          </Flex>



        </Flex>
        {
          pipelines.length > 0 && (<Flex w={'200px'} bg="#f0f2f1" style={{ borderRadius: '8px', overflow: 'scroll' }} p={'0.8rem'} direction={'column'} gap={12}>
            <Text fz={20} >Queue</Text>
            {
              pipelines.map(itm => {
                return (
                  <Flex
                    key={itm.jobId}
                    style={{ borderRadius: '4px' }} bg="white" p={'1rem'} direction={'column'} gap={2} onClick={() => {
                      setMode(MODE.PIPELINES)
                    }}>
                    <Text fz={12} style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>RUN {itm.jobId}</Text>

                    <Text fz={10} color="tile" style={{ overflow: 'hidden' }}>Created <RelativeTime time={itm.createdAt} /></Text>
                    {/* <Text fz={10} color="tile" style={{ overflow: 'hidden' }}>{itm.jobId}</Text> */}
                    <Text fz={12} >
                      {itm.status === 'pending' ? 'Running' : ''}
                      {itm.status === 'ending' ? 'Finalizing' : ''}
                      {itm.status === 'completed' ? 'Completed' : ''}
                      {itm.status === 'failed' ? 'Failed' : ''}
                    </Text>
                    {
                      itm.status !== 'completed' && itm.status !== 'failed' && (<Progress radius="lg" size="lg" color="#f53c02" value={100} striped animate={true} w={'100%'} />)
                    }

                  </Flex>
                )
              }
              )
            }


          </Flex>)
        }

      </Flex>
    </AppShell >
  );
};

export const SiteListLayout = observer(Element);
