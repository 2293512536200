import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import createStore, { Environment } from "./helpers/create-store";
import { StoreProvider } from "./helpers/store-provider";
import * as Sentry from "@sentry/react";

import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
dayjs.extend(relativeTime);

const tagManagerArgs = {
  gtmId: "G-CF73X4L487",
};
let envConfig: Environment;
const nodeEnv = process.env.NODE_ENV;

try {
  envConfig = require(`./env/${nodeEnv}.json`);
} catch {
  envConfig = require("./env/development.json");
}

const { rootStore, env } = createStore({ envConfig });

if (nodeEnv === "development") {
  // for debugging
  console.log(rootStore);
} else {
  TagManager.initialize(tagManagerArgs);

  Sentry.init({
    dsn: "https://56dab706da7bf446001ab938eff43b74@o4506520660082688.ingest.sentry.io/4506520661065728",
    release: process.env.COMMIT_REF,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/maground\.ai\/api/],
      }),
      // new Sentry.Feedback({
      //   // Additional SDK configuration goes in here, for example:
      //   colorScheme: "system",
      //   isNameRequired: true,
      //   isEmailRequired: true,
      // }),
      // new Sentry.Replay({
      //   maskAllText: false,
      //   blockAllMedia: false,
      // }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const sendToGoogleAnalytics = ({ name, delta, id }: any) => {
  // Assumes the global `gtag()` function exists, see:
  // https://developers.google.com/analytics/devguides/collection/gtagjs
  // @ts-ignore
  window.gtag &&
    // @ts-ignore
    window.gtag("event", name, {
      event_category: "Web Vitals",
      // The `id` value will be unique to the current page load. When sending
      // multiple values from the same page (e.g. for CLS), Google Analytics can
      // compute a total by grouping on this ID (note: requires `eventLabel` to
      // be a dimension in your report).
      event_label: id,
      // Google Analytics metrics must be integers, so the value is rounded.
      // For CLS the value is first multiplied by 1000 for greater precision
      // (note: increase the multiplier for greater precision if needed).
      value: Math.round(name === "CLS" ? delta * 1000 : delta),
      // Use a non-interaction event to avoid affecting bounce rate.
      non_interaction: true,

      // OPTIONAL: any additional params or debug info here.
      // See: https://web.dev/debug-web-vitals-in-the-field/
      // metric_rating: 'good' | 'ni' | 'poor',
      // debug_info: '...',
      // ...
    });
};

const initApp = async () => {
  // run Mocks on development
  // if (nodeEnv === "development") {
  //   const { worker } = require("./mocks/browser");
  //   await worker.start();
  // }

  await rootStore.uiStore.authStore.loginIfTokenExists();
  await env.translationService.init();

  ReactDOM.render(
    <StoreProvider value={rootStore}>
      <App />
    </StoreProvider>,
    document.getElementById("root")
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

  reportWebVitals(sendToGoogleAnalytics);
};

initApp();
