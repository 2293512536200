import React, { useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import MainRouter from "./main-router";
import { useStore } from "../../hooks/use-store";
import { usePostHog } from "posthog-js/react";
import { observer } from "mobx-react";
import { SiteListLayout } from "../../features/workspace/pipeline/Main";
import PaymentRedirectScreen from "../private/paymentRedirect/PaymentRedirectScreen";
// import WaitlistForm from "../../features/workspace/WaitlistForm";

const LoggedIn = () => {
  const rootStore = useStore();
  const posthog = usePostHog()

  
  const {
    uiStore: { authStore },
  } = rootStore;

  useEffect(() => {
    if (authStore.currentUser?.id && posthog) {
      posthog.identify(authStore.currentUser.email!, {
        email: authStore.currentUser.email,
        name: authStore.currentUser.firstName + ' ' + authStore.currentUser.lastName,
      })
    }
  }, [posthog, authStore.currentUser, authStore.isLoggedIn])

  return (
    <Switch>
      <Route path="/payment/redirect">
        <PaymentRedirectScreen />
      </Route> 
      {/* <Route path="/waitlist">
        <WaitlistForm />
      </Route> */}
      <Route path="/">
        <SiteListLayout />
      </Route>
      <Route exact path="/">
        <MainRouter />
      </Route>
      <Redirect to="/workflows" />
    </Switch>
  );
};

export default observer(LoggedIn);
