/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./editAccount.module.scss";
import { useForm } from "@mantine/form";
import { Widget } from "@uploadcare/react-widget";
import { getInitials } from "../../../helpers/helper";
import { useTranslation } from "react-i18next";
import { NOTIFICATION_SERVICE } from "../../notifications/NotificationService";
import { useStore } from "../../../hooks/use-store";
import { Avatar, Button, Drawer, TextInput } from "@mantine/core";
import { useRequest } from "ahooks";

interface Props {
  onClose: () => void;
  visible: boolean;
}

interface FormProps {
  firstName: string;
  lastName: string;
  email: string;
}

const EditAccount = (props: Props) => {
  const { t } = useTranslation();
  const {
    uiStore: { authStore },
    dataStore: { appStore, spaceStore },
  } = useStore();

  const { runAsync: runAsyncConvert } = useRequest(
    async (model: { url: string }) => appStore.convertImage({
      url: model.url,
      websiteId: spaceStore.siteId,
    }),
    {
      manual: true,
      onSuccess: (res) => {
        if (res.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
        } else {
          return res.url
        }
      },
    }
  );

  const [avatar, setAvatar] = useState<string | null>(null);

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
    },
    validate: {
    },
  });

  // useEffect(() => {
  //   form.reset()
  // }, [form]);

  useEffect(() => {
    if (authStore.currentUser) {
      form.setFieldValue("firstName", authStore.currentUser.firstName);
      form.setFieldValue("lastName", authStore.currentUser.lastName);
      setAvatar(authStore.currentUser.avatar);
    }
  }, [
    authStore.currentUser?.firstName,
    authStore.currentUser?.lastName,
    authStore.currentUser?.avatar,
    authStore.currentUser,
    setAvatar,
  ]);

  const submit = async (data: FormProps) => {
    // await spaceStore.updateParticipant({
    //   identityId: spaceStore.identity,
    //   avatar: avatar!,
    //   firstName: data.firstName,
    //   lastName: data.lastName,
    // });

    await authStore.update({
      avatar: avatar,
      firstName: data.firstName,
      lastName: data.lastName,
    });

    NOTIFICATION_SERVICE.successNotification("Account updated");

    props.onClose();
  };

  return (
    <Drawer opened={props.visible} position='right' onClose={props.onClose} title={t("profile.edit", {
      defaultValue: "Edit profile",
    })}>
      <div className={styles.avatar}>
        <Avatar
          //shape="square"
          style={{ border: "none" }}
          size={98}
          src={avatar}
        >
          {getInitials(authStore.currentUser?.firstName!)}
        </Avatar>
        <Widget
          localeTranslations={{
            buttons: {
              choose: {
                files: {
                  one: "Upload your avatar",
                },
              },
            },
          }}
          clearable={true}
          // @ts-ignore
          onChange={async (info: { cdnUrl: string }) => {
            const res = await runAsyncConvert({ url: info.cdnUrl })
            setAvatar(res.url);
          }}
          tabs={"file camera url"}
          publicKey="35626f59762b63c4244c"
        />
      </div>

      <form onSubmit={form.onSubmit(async (values) => {
        await submit(values)
      })}>
        <TextInput
          required
          type="text"
          label={"First name"}
          name={"firstName"}
          {...form.getInputProps('firstName')}
        />
        <TextInput
          required
          type="text"
          label={"Last name"}
          name={"lastName"}
          {...form.getInputProps('lastName')}
        />

        <Button
          fullWidth
          mt="xl"
          loading={false}
          type="submit"
        // onClick={}
        >
          Save
        </Button>
      </form>
    </Drawer>
  );
};

export default EditAccount;
