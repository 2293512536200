import { action, computed, makeObservable, observable } from "mobx";
import isEqual from "lodash.isequal";
import { ButtonAttributes, ButtonContent, CONTENT_TYPE, CommonAttributes, FormAttributes, FormContent, GroupAttributes, ImageAttributes, ImageContent, LanguageSwitcherAttributes, LanguageSwitcherContent, ShapeAttributes, ShapeContent, TextAttributes, TextContent, WebsiteData, isNumber } from "../../../features/workspace/Site/helper";
import ShapeElement from "./shapeElement";
import TextElement from "./textElement";
import { customAlphabet } from "nanoid";
import ImageElement from "./imageElement";
import GroupElement from "./groupElement";
import FormElement from "./formElement";
import ButtonElement from "./buttonElement";
import LanguageSwitcherElement from "./languageSwitcherElement";

const randomString = () =>
  customAlphabet('abcdefghijklmnopqrstuvwxyz', 6)();

interface StackActionEdit {
  type: 'EDIT'
  id: string,
  edits: {
    key: any
    value: any
  }[]
}

interface StackActionAdd {
  type: 'ADD'
  id: string,
  attributes: any,
  content: any,
}

interface StackActionDelete {
  type: 'DELETE'
  id: string,
  attributes: any,
  content: any,
}

export type StackAction = StackActionEdit | StackActionAdd | StackActionDelete

export class SpaceStore {
  // ux heplers
  @observable lastUsedTypefaceId = null;
  @observable lastUsedFontColor = null;
  @observable lastUsedFontSize = null;

  @observable stack: StackAction[] = []
  @observable redoStack: StackAction[] = []

  @observable billingModalOpen: boolean = false;
  @observable genImageModalOpen: boolean = false;
  @observable statisticsModalOpen: boolean = false;
  @observable langModalOpen: boolean = false;
  @observable genImageModalFunc: (image: string) => void = () => { };
  @observable planType: string = '';
  @observable siteId: string = '';
  @observable freeDomain: string = '';
  @observable domain: string = '';
  @observable zoom: number = 0.5;
  @observable fonts: { provider: string, name: string }[]
  @observable clipView: boolean = false
  @observable lockAspectRatio: boolean = false
  @observable pageId: string
  @observable scrollOffset: number = 0
  @observable viewportScrollHeight: number = 0
  @observable currentViewport: 1440 | 810 | 320 = 1440
  @observable selectedWithShift: string[] = []


  @observable page: {
    backgroundColor: string | null,
    height: string | number | 'auto',
  }
  @observable selected: ShapeElement | TextElement | ImageElement | GroupElement | FormElement | ButtonElement | LanguageSwitcherElement | null = null;
  @observable elementsMap: Map<string, ShapeElement | TextElement | ImageElement | GroupElement | FormElement | ButtonElement | LanguageSwitcherElement> = new Map();

  @observable websiteData: WebsiteData = {} as WebsiteData

  constructor() {
    makeObservable(this);
  }

  @action
  changeClip = () => {
    this.clipView = !this.clipView
  }

  @action
  openBillingModal = () => {
    this.billingModalOpen = true
  }
  @action
  closeBillingModal = () => {
    this.billingModalOpen = false
  }

  @action
  openGenImageModal = (func: (image: string) => void) => {
    this.genImageModalOpen = true
    this.genImageModalFunc = func
  }

  @action
  toggleStatisticModal = () => {
    this.statisticsModalOpen = !this.statisticsModalOpen
  }

  @action
  toggleLangModal = () => {
    this.langModalOpen = !this.langModalOpen
  }

  @action
  closeGenImageModal = () => {
    this.genImageModalOpen = false
  }

  @action
  undo = () => {
    const action = this.stack.pop()

    const redoEdits: { key: string, value: any }[] = []

    if (action) {

      if (action.type === 'EDIT') {
        const element = this.elementsMap.get(action.id)
        if (this.selected?.id === action.id) {
          // @ts-ignore
          action.edits.forEach((edit) => {
            // @ts-ignore
            this.selected[edit.key] = edit.value
          })
        }
        if (element) {
          action.edits.forEach((edit) => {

            redoEdits.push({
              key: edit.key,
              // @ts-ignore
              value: element[edit.key],
            })

            // @ts-ignore
            element[edit.key] = edit.value
            this.updateWebsiteData(action.id, edit.key, edit.value)
          })
        }

        this.redoStack.push({
          type: 'EDIT',
          id: action.id,
          edits: redoEdits,
        })
      } else if (action.type === 'ADD') {
      } else if (action.type === 'DELETE') {
      }
    }
  }

  @action
  addToStack = (action: StackAction) => {
    this.stack.push(action)
    this.redoStack = []
  }

  @action
  redo = () => {
    const action = this.redoStack.pop()

    const undoEdits: { key: string, value: any }[] = []

    if (action) {

      if (action.type === 'EDIT') {
        const element = this.elementsMap.get(action.id)
        if (this.selected?.id === action.id) {
          // @ts-ignore
          action.edits.forEach((edit) => {
            // @ts-ignore
            this.selected[edit.key] = edit.value
          })
        }
        if (element) {
          action.edits.forEach((edit) => {

            undoEdits.push({
              key: edit.key,
              // @ts-ignore
              value: element[edit.key],
            })

            // @ts-ignore
            element[edit.key] = edit.value
            this.updateWebsiteData(action.id, edit.key, edit.value)
          })
        }

        this.addToStack({
          type: 'EDIT',
          id: action.id,
          edits: undoEdits,
        })
      } else if (action.type === 'ADD') {
      } else if (action.type === 'DELETE') {
      }
    }
  }



  setWebsiteData = (websiteData: WebsiteData) => {
    this.websiteData = websiteData

    const pageId = Object.keys(websiteData.pages).find((key) => {
      return websiteData.pages[key].homepage === true
    })

    if (!pageId) {
      throw new Error('No homepage')
    }

    const ids: string[] = []
    Object.keys(this.websiteData.pages).map(itm => {
      ids.push(itm)
      return itm
    })

    this.setElements(this.websiteData, 1440, pageId)
  }

  setDomains = (model: {
    freeDomain: string,
    id: string,
    domain: string,
    planType: string,
  }) => {
    this.freeDomain = model.freeDomain
    this.domain = model.domain
    this.siteId = model.id
    this.planType = model.planType
  }

  setBilling = (model: {
    planType: string,
  }) => {
    this.planType = model.planType
  }

  @action
  setHomepage = (id: string) => {

    Object.keys(this.websiteData.pages).forEach((key) => {
      this.websiteData.pages[key].homepage = false
    })

    this.setPageId(id)

    this.websiteData.pages[id].homepage = true

    // 
  }

  getHightestZIndex = () => {
    let max = 0
    this.elementsMap.forEach((value) => {
      if (value.zIndex > max) {
        max = value.zIndex
      }
    })
    return max
  }

  @action
  deletePage = (id: string) => {
    if (this.pageId === id) {
      const homepageId = Object.keys(this.websiteData.pages).find((key) => {
        return this.websiteData.pages[key].homepage === true
      })
      this.setPageId(homepageId!)
    }
    delete this.websiteData.pages[id]
  }
  @action
  updateZoom = (zoom: number) => {
    this.zoom = zoom
  }

  @action
  getHightestZindex = () => {
    let max = 0
    this.elementsMap.forEach((value) => {
      if (value.zIndex > max) {
        max = value.zIndex
      }
    })
    return max
  }

  @action
  checkZIndexCollisionAndRepair = (zIndex: number) => {
    let exists = false
    this.elementsMap.forEach((value) => {
      if (value.zIndex === zIndex) {
        exists = true
      }
    })

    if (exists) {
      const elements: (ShapeElement | TextElement | ImageElement | FormElement | GroupElement | ButtonElement)[] = []
      this.elementsMap.forEach((value) => {
        if (value.zIndex >= zIndex) {
          elements.push(value)
        }
      })

      elements.forEach((element) => {
        element.zIndex = element.zIndex + 1
        this.websiteData.pages[this.pageId].media[this.currentViewport].components[element.id].attributes.zIndex = element.zIndex
      })
    }
  }

  @action
  addPage = () => {
    const key = randomString()
    const alternativeLangsContent: any = {}
    this.websiteData.alternativeLangs?.forEach((lang) => {
      alternativeLangsContent[lang] = {}
    })

    this.websiteData.pages[key] = {
      homepage: false,
      id: 'home',
      name: 'New page',
      url: key,
      title: '',
      description: '',
      og: '',
      content: {},
      alternativeLangsContent,
      media: {
        320: {
          pageStyle: {
            height: 600,
            backgroundColor: '#ffffffff',
          },
          components: {},
        },
        810: {
          pageStyle: {
            height: 1000,
            backgroundColor: '#ffffffff',
          },
          components: {},
        },
        1440: {
          pageStyle: {
            height: 1440,
            backgroundColor: '#ffffffff',
          },
          components: {},
        },
      },
    }

    this.setPageId(key)
    // this.pageId = key

    // this.setElements(this.websiteData, 1440, key)
    // console.log(this.websiteData)
  }

  setPageId = (pageId: string) => {
    this.pageId = pageId
    this.changeViewport(1440)
    this.setElements(this.websiteData, 1440, pageId)
  }


  setElements = (websiteData: WebsiteData, viewport: 1440 | 810 | 320, pageId: string) => {
    this.selected = null
    this.selectedWithShift = []
    this.pageId = pageId
    this.elementsMap = new Map()
    this.page = (websiteData.pages[this.pageId].media[viewport] as any).pageStyle

    // this.fonts = Object.values(websiteData.typeface).map((font) => ({
    //   provider: font.provider,
    //   name: font.name
    // }))

    Object.keys(websiteData.pages[this.pageId].media[viewport].components).forEach((key) => {
      try {
        if (websiteData.pages[this.pageId].content[key].type === CONTENT_TYPE.TEXT) {
          this.elementsMap.set(key, new TextElement({
            parentId: websiteData.pages[this.pageId].media[viewport].components[key].attributes.parentId,
            id: key,
            x: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.x,
            y: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.y,
            width: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.width,
            height: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.height,

            text: (websiteData.pages[this.pageId].content[key].content as TextContent).text,
            position: websiteData.pages[this.pageId].media[viewport].components[key].attributes.position,
            zIndex: websiteData.pages[this.pageId].media[viewport].components[key].attributes.zIndex,
            opacity: websiteData.pages[this.pageId].media[viewport].components[key].attributes.opacity,
            bgColor: websiteData.pages[this.pageId].media[viewport].components[key].attributes.bgColor,
            fontSize: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as TextAttributes).fontSize,
            fontWeight: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as TextAttributes).fontWeight,
            letterSpacing: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as TextAttributes).letterSpacing,
            lineHeight: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as TextAttributes).lineHeight,
            textDecoration: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as TextAttributes).textDecoration,
            color: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as TextAttributes).color,
            textAlign: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as TextAttributes).textAlign,
            typefaceId: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as TextAttributes).typefaceId,
            animation: websiteData.pages[this.pageId].media[viewport].components[key].attributes.animation,
            link: websiteData.pages[this.pageId].media[viewport].components[key].attributes.link,
            name: websiteData.pages[this.pageId].media[viewport].components[key].attributes.name,
            rotate: websiteData.pages[this.pageId].media[viewport].components[key].attributes.rotate,
            parralax: websiteData.pages[this.pageId].media[viewport].components[key].attributes.parralax,
            anchor: websiteData.pages[this.pageId].media[viewport].components[key].attributes.anchor,
          }))
        } else if (websiteData.pages[this.pageId].content[key].type === CONTENT_TYPE.IMAGE) {
          this.elementsMap.set(key, new ImageElement({
            src: (websiteData.pages[this.pageId].content[key].content as ImageContent).src,
            alt: (websiteData.pages[this.pageId].content[key].content as ImageContent).alt || '',
            type: (websiteData.pages[this.pageId].content[key].content as ImageContent).type || 'IMAGE',
            set: (websiteData.pages[this.pageId].content[key].content as ImageContent).set || [],
            sliderDuration: (websiteData.pages[this.pageId].content[key].content as ImageContent).sliderDuration,
            transitionDuration: (websiteData.pages[this.pageId].content[key].content as ImageContent).transitionDuration,

            parentId: websiteData.pages[this.pageId].media[viewport].components[key].attributes.parentId,
            id: key,
            x: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.x,
            y: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.y,
            width: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.width,
            height: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.height,
            position: websiteData.pages[this.pageId].media[viewport].components[key].attributes.position,
            zIndex: websiteData.pages[this.pageId].media[viewport].components[key].attributes.zIndex,
            opacity: websiteData.pages[this.pageId].media[viewport].components[key].attributes.opacity,
            bgColor: websiteData.pages[this.pageId].media[viewport].components[key].attributes.bgColor,
            borderRadius: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ImageAttributes).borderRadius,
            borderWidth: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ImageAttributes).borderWidth,
            borderColor: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ImageAttributes).borderColor,
            boxShadow: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ImageAttributes).boxShadow,
            objectFit: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ImageAttributes).objectFit,
            animation: websiteData.pages[this.pageId].media[viewport].components[key].attributes.animation,
            link: websiteData.pages[this.pageId].media[viewport].components[key].attributes.link,
            name: websiteData.pages[this.pageId].media[viewport].components[key].attributes.name,
            rotate: websiteData.pages[this.pageId].media[viewport].components[key].attributes.rotate,
            parralax: websiteData.pages[this.pageId].media[viewport].components[key].attributes.parralax,
            anchor: websiteData.pages[this.pageId].media[viewport].components[key].attributes.anchor,
          }))
        } else if (websiteData.pages[this.pageId].content[key].type === CONTENT_TYPE.SHAPE) {
          this.elementsMap.set(key, new ShapeElement({
            parentId: websiteData.pages[this.pageId].media[viewport].components[key].attributes.parentId,
            id: key,
            type: (websiteData.pages[this.pageId].content[key].content as ShapeContent).type,
            code: (websiteData.pages[this.pageId].content[key].content as ShapeContent).code,
            x: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.x,
            y: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.y,
            width: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.width,
            height: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.height,
            position: websiteData.pages[this.pageId].media[viewport].components[key].attributes.position,
            zIndex: websiteData.pages[this.pageId].media[viewport].components[key].attributes.zIndex,
            opacity: websiteData.pages[this.pageId].media[viewport].components[key].attributes.opacity,
            bgColor: websiteData.pages[this.pageId].media[viewport].components[key].attributes.bgColor,
            borderRadius: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ShapeAttributes).borderRadius,
            borderWidth: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ShapeAttributes).borderWidth,
            borderColor: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ShapeAttributes).borderColor,
            boxShadow: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ShapeAttributes).boxShadow,
            animation: websiteData.pages[this.pageId].media[viewport].components[key].attributes.animation,
            link: websiteData.pages[this.pageId].media[viewport].components[key].attributes.link,
            name: websiteData.pages[this.pageId].media[viewport].components[key].attributes.name,
            rotate: websiteData.pages[this.pageId].media[viewport].components[key].attributes.rotate,
            parralax: websiteData.pages[this.pageId].media[viewport].components[key].attributes.parralax,
            anchor: websiteData.pages[this.pageId].media[viewport].components[key].attributes.anchor,
          }))
        } else if (websiteData.pages[this.pageId].content[key].type === CONTENT_TYPE.GROUP) {
          this.elementsMap.set(key, new GroupElement({
            parentId: websiteData.pages[this.pageId].media[viewport].components[key].attributes.parentId,
            id: key,
            x: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.x,
            y: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.y,
            width: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.width,
            height: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.height,
            position: websiteData.pages[this.pageId].media[viewport].components[key].attributes.position,
            zIndex: websiteData.pages[this.pageId].media[viewport].components[key].attributes.zIndex,
            opacity: websiteData.pages[this.pageId].media[viewport].components[key].attributes.opacity,
            bgColor: websiteData.pages[this.pageId].media[viewport].components[key].attributes.bgColor,
            borderRadius: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ShapeAttributes).borderRadius,
            borderWidth: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ShapeAttributes).borderWidth,
            borderColor: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ShapeAttributes).borderColor,
            boxShadow: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ShapeAttributes).boxShadow,
            animation: websiteData.pages[this.pageId].media[viewport].components[key].attributes.animation,
            link: websiteData.pages[this.pageId].media[viewport].components[key].attributes.link,
            name: websiteData.pages[this.pageId].media[viewport].components[key].attributes.name,
            rotate: websiteData.pages[this.pageId].media[viewport].components[key].attributes.rotate,
            parralax: websiteData.pages[this.pageId].media[viewport].components[key].attributes.parralax,
            anchor: websiteData.pages[this.pageId].media[viewport].components[key].attributes.anchor,
          }))
        } else if (websiteData.pages[this.pageId].content[key].type === CONTENT_TYPE.BUTTON) {
          this.elementsMap.set(key, new ButtonElement({
            parentId: websiteData.pages[this.pageId].media[viewport].components[key].attributes.parentId,
            id: key,
            text: (websiteData.pages[this.pageId].content[key].content as ButtonContent).text,
            x: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.x,
            y: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.y,
            width: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.width,
            height: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.height,
            position: websiteData.pages[this.pageId].media[viewport].components[key].attributes.position,
            zIndex: websiteData.pages[this.pageId].media[viewport].components[key].attributes.zIndex,
            opacity: websiteData.pages[this.pageId].media[viewport].components[key].attributes.opacity,
            bgColor: websiteData.pages[this.pageId].media[viewport].components[key].attributes.bgColor,
            fontSize: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ButtonAttributes).fontSize,
            fontWeight: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ButtonAttributes).fontWeight,
            letterSpacing: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ButtonAttributes).letterSpacing,
            lineHeight: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ButtonAttributes).lineHeight,
            textDecoration: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ButtonAttributes).textDecoration,
            color: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ButtonAttributes).color,
            textAlign: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ButtonAttributes).textAlign,
            typefaceId: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ButtonAttributes).typefaceId,
            borderRadius: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ButtonAttributes).borderRadius,
            borderWidth: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ButtonAttributes).borderWidth,
            borderColor: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ButtonAttributes).borderColor,
            hoverColor: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ButtonAttributes).hoverColor,
            hoverBgColor: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as ButtonAttributes).hoverBgColor,
            animation: websiteData.pages[this.pageId].media[viewport].components[key].attributes.animation,
            link: websiteData.pages[this.pageId].media[viewport].components[key].attributes.link,
            name: websiteData.pages[this.pageId].media[viewport].components[key].attributes.name,
            rotate: websiteData.pages[this.pageId].media[viewport].components[key].attributes.rotate,
            showHoverState: false,
            parralax: websiteData.pages[this.pageId].media[viewport].components[key].attributes.parralax,
            anchor: websiteData.pages[this.pageId].media[viewport].components[key].attributes.anchor,
          }))
        }
        else if (websiteData.pages[this.pageId].content[key].type === CONTENT_TYPE.LANGUAGESWITCHER) {
          this.elementsMap.set(key, new LanguageSwitcherElement({
            parentId: websiteData.pages[this.pageId].media[viewport].components[key].attributes.parentId,
            id: key,
            x: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.x,
            y: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.y,
            width: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.width,
            height: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.height,
            position: websiteData.pages[this.pageId].media[viewport].components[key].attributes.position,
            zIndex: websiteData.pages[this.pageId].media[viewport].components[key].attributes.zIndex,
            opacity: websiteData.pages[this.pageId].media[viewport].components[key].attributes.opacity,
            bgColor: websiteData.pages[this.pageId].media[viewport].components[key].attributes.bgColor,
            fontSize: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as LanguageSwitcherAttributes).fontSize,
            fontWeight: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as LanguageSwitcherAttributes).fontWeight,
            letterSpacing: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as LanguageSwitcherAttributes).letterSpacing,
            lineHeight: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as LanguageSwitcherAttributes).lineHeight,
            textDecoration: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as LanguageSwitcherAttributes).textDecoration,
            textColor: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as LanguageSwitcherAttributes).textColor,
            typefaceId: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as LanguageSwitcherAttributes).typefaceId,
            borderRadius: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as LanguageSwitcherAttributes).borderRadius,
            borderWidth: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as LanguageSwitcherAttributes).borderWidth,
            borderColor: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as LanguageSwitcherAttributes).borderColor,
            caretColor: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as LanguageSwitcherAttributes).caretColor,
            caretSize: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as LanguageSwitcherAttributes).caretSize,
            iconColor: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as LanguageSwitcherAttributes).iconColor,
            iconSize: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as LanguageSwitcherAttributes).iconSize,
            animation: websiteData.pages[this.pageId].media[viewport].components[key].attributes.animation,
            // link: websiteData.pages[this.pageId].media[viewport].components[key].attributes.link,
            name: websiteData.pages[this.pageId].media[viewport].components[key].attributes.name,
            rotate: websiteData.pages[this.pageId].media[viewport].components[key].attributes.rotate,
            parralax: websiteData.pages[this.pageId].media[viewport].components[key].attributes.parralax,
            anchor: websiteData.pages[this.pageId].media[viewport].components[key].attributes.anchor,
            hasCaret: (websiteData.pages[this.pageId].content[key].content as LanguageSwitcherContent).hasCaret,
            hasIcon: (websiteData.pages[this.pageId].content[key].content as LanguageSwitcherContent).hasIcon,
          }))
        } else if (websiteData.pages[this.pageId].content[key].type === CONTENT_TYPE.FORM) {
          this.elementsMap.set(key, new FormElement({
            parentId: websiteData.pages[this.pageId].media[viewport].components[key].attributes.parentId,
            id: key,
            x: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.x,
            y: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.y,
            width: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.width,
            height: +websiteData.pages[this.pageId].media[viewport].components[key].attributes.height,
            position: websiteData.pages[this.pageId].media[viewport].components[key].attributes.position,
            zIndex: websiteData.pages[this.pageId].media[viewport].components[key].attributes.zIndex,
            opacity: websiteData.pages[this.pageId].media[viewport].components[key].attributes.opacity,
            bgColor: websiteData.pages[this.pageId].media[viewport].components[key].attributes.bgColor,
            borderRadius: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).borderRadius,
            borderWidth: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).borderWidth,
            borderColor: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).borderColor,
            boxShadow: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).boxShadow,

            buttonText: (websiteData.pages[this.pageId].content[key].content as FormContent).buttonText,
            formSubmitText: (websiteData.pages[this.pageId].content[key].content as FormContent).formSubmitText,
            fields: (websiteData.pages[this.pageId].content[key].content as FormContent).fields,


            backgroundColor: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).backgroundColor,

            labelPaddingLeft: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).labelPaddingLeft,
            labelPaddingTop: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).labelPaddingTop,
            labelFontSize: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).labelFontSize,
            labelFontWeight: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).labelFontWeight,
            labelLetterSpacing: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).labelLetterSpacing,
            labelLineHeight: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).labelLineHeight,
            labelTextDecoration: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).labelTextDecoration,
            labelTextColor: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).labelTextColor,
            labelTextAlign: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).labelTextAlign,
            labelTypefaceId: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).labelTypefaceId,

            fieldPaddingLeft: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).fieldPaddingLeft,
            fieldPaddingTop: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).fieldPaddingTop,
            fieldFontSize: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).fieldFontSize,
            fieldFontWeight: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).fieldFontWeight,
            fieldLetterSpacing: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).fieldLetterSpacing,
            fieldLineHeight: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).fieldLineHeight,
            fieldTextDecoration: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).fieldTextDecoration,
            fieldTextColor: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).fieldTextColor,
            fieldTextAlign: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).fieldTextAlign,
            fieldTypefaceId: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).fieldTypefaceId,
            fieldBackgroundColor: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).fieldBackgroundColor,

            fieldBorderRadius: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).fieldBorderRadius,
            fieldBorderWidth: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).fieldBorderWidth,
            fieldBorderColor: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).fieldBorderColor,

            buttonPaddingLeft: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).buttonPaddingLeft,
            buttonPaddingTop: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).buttonPaddingTop,
            buttonFontSize: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).buttonFontSize,
            buttonFontWeight: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).buttonFontWeight,
            buttonLetterSpacing: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).buttonLetterSpacing,
            buttonLineHeight: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).buttonLineHeight,
            buttonTextDecoration: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).buttonTextDecoration,
            buttonTextColor: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).buttonTextColor,
            buttonBackgroundColor: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).buttonBackgroundColor,
            buttonBorderRadius: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).buttonBorderRadius,
            buttonBorderWidth: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).buttonBorderWidth,
            buttonBorderColor: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).buttonBorderColor,
            buttonTextAlign: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).buttonTextAlign,
            buttonTypefaceId: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).buttonTypefaceId,
            buttonFluid: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).buttonFluid,
            buttonWidth: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).buttonWidth,

            formSubmitTextFontSize: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).formSubmitTextFontSize,
            formSubmitTextFontWeight: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).formSubmitTextFontWeight,
            formSubmitTextLetterSpacing: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).formSubmitTextLetterSpacing,
            formSubmitTextLineHeight: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).formSubmitTextLineHeight,
            formSubmitTextTextDecoration: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).formSubmitTextTextDecoration,
            formSubmitTextTextColor: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).formSubmitTextTextColor,
            formSubmitTextTextAlign: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).formSubmitTextTextAlign,
            formSubmitTextTypefaceId: (websiteData.pages[this.pageId].media[viewport].components[key].attributes as FormAttributes).formSubmitTextTypefaceId,

            animation: websiteData.pages[this.pageId].media[viewport].components[key].attributes.animation,
            link: websiteData.pages[this.pageId].media[viewport].components[key].attributes.link,
            name: websiteData.pages[this.pageId].media[viewport].components[key].attributes.name,
            rotate: websiteData.pages[this.pageId].media[viewport].components[key].attributes.rotate,
            displaySavedState: false,
            parralax: websiteData.pages[this.pageId].media[viewport].components[key].attributes.parralax,
            anchor: websiteData.pages[this.pageId].media[viewport].components[key].attributes.anchor,
          }))
        }
      } catch (error) {
        console.log(error)
        delete websiteData.pages[this.pageId].media[viewport].components[key]
      }

    })
  }

  @action
  updatePageProps = (model: {
    backgroundColor?: string | null,
    height?: number | string,
  }) => {
    this.page = {
      ...this.page,
      ...model,
    }

    this.websiteData.pages[this.pageId].media[this.currentViewport].pageStyle = {
      ...this.websiteData.pages[this.pageId].media[this.currentViewport].pageStyle,
      ...model,
    }
  }

  @action
  updatePageAttributes = (model: {
    id: string,
    name?: string,
    url?: string,
    title?: string,
    description?: string,
    og?: string,
  }) => {
    if (model.url) {
      const existing = Object.keys(this.websiteData.pages).find((key) => {
        return this.websiteData.pages[key].url === model.url
      })
      if (existing) {

      } else {
        this.websiteData.pages[model.id] = {
          ...this.websiteData.pages[model.id],
          ...model,
        }
      }
    } else {
      this.websiteData.pages[model.id] = {
        ...this.websiteData.pages[model.id],
        ...model,
      }
    }
  }

  checkBeforePublish = () => {
    const errors: string[] = []
    if (this.planType === 'FREE' || !this.planType) {
      Object.keys(this.websiteData.typeface).forEach((key) => {
        if (this.websiteData.typeface[key].provider !== 'google') {
          errors.push('Custom fonts')
        }
      })
      Object.keys(this.websiteData.pages).forEach((pageId) => {

        if (this.websiteData.pages[pageId].og) {
          errors.push('OG image')
        }
        if (this.websiteData.pages[pageId].title) {
          errors.push('SEO page title')
        }
        if (this.websiteData.pages[pageId].description) {
          errors.push('SEO page description')
        }

        Object.keys(this.websiteData.pages[pageId].media).forEach((v: any) => {

          const viewport = v as any as 810 | 320 | 1440
          Object.keys(this.websiteData.pages[pageId].media[viewport].components).forEach((key) => {
            const element = this.websiteData.pages[pageId].media[viewport].components[key]
            const content = this.websiteData.pages[pageId].content[key]
            if (content.type === 'FORM') {
              errors.push('Form element')
            }
            if (content.type === 'SHAPE') {
              if (content.content.type === 'CODE') {
                errors.push('Code element')
              }
            }

            if (element.attributes.position === 'fixed') {
              errors.push('Fixed position')
            }
            if (element.attributes.animation.type !== 'none') {
              errors.push('Animation')
            }
          })
        })
      })

      return errors
        .filter((value, index, array) =>
          array.indexOf(value) === index
        )
    } else {
      return []
    }
  }

  checkBillingCapability = () => {
    return true
    // if (this.planType === 'FREE') {
    //   this.openBillingModal()
    //   return false
    // } else {
    //   return true
    // }
  }

  @action
  updateElementProps = (model: any, withSelect = true) => {
    if (!model.id) {
      return
    }

    console.log('updateElementProps', model)
    if (withSelect) {
      if (model.id !== this.selected?.id) {
        if (this.selectedWithShift.length === 0) {
          this.selectElement(model.id, false)
        }
      }
    }

    const edits: { key: string, value: any }[] = []

    Object.keys(model).filter(key => key !== 'id').forEach((key: any) => {
      if (this.selected) {
        if (!isEqual(this.selected[key as any as keyof typeof this.selected], model[key])) {
          edits.push({
            key,
            value: this.selected[key as any as keyof typeof this.selected]
          })
        }
      }
    })

    if (edits.length > 0) {
      this.addToStack({
        type: 'EDIT',
        id: model.id,
        edits,
      })
    }

    Object.keys(model).forEach((key) => {
      if (key && model[key as keyof typeof model] !== undefined) {
        // console.log('updateWebsiteData', model.id, key, model[key as keyof typeof model])
        this.updateWebsiteData(model.id, key, model[key as keyof typeof model])



        if (model.id === this.selected?.id) {
          // @ts-ignore
          this.selected[key] = model[key as keyof typeof model]
        }
      }
    })



    const element = this.elementsMap.get(model.id)
    if (element) {
      if (element instanceof TextElement) {
        const el = { ...element }


        Object.keys(model).forEach((key) => {
          if (key && model[key as keyof typeof model] !== undefined) {
            // @ts-ignore
            el[key] = model[key as keyof typeof model]

            if (model.typefaceId) {
              this.tryUpdateTypeface(model.typefaceId)
            }

            if (key === 'color') {
              this.lastUsedFontColor = model.color
            }
            if (key === 'fontSize') {
              this.lastUsedFontSize = model.fontSize
            }
            if (key === 'typefaceId') {
              this.lastUsedTypefaceId = model.typefaceId
            }
          }
        })
        this.elementsMap.set(model.id, new TextElement(el))

      }
      else if (element instanceof ImageElement) {
        const el = { ...element }

        Object.keys(model).forEach((key) => {
          if (key && model[key as keyof typeof model] !== undefined) {
            // @ts-ignore
            el[key] = model[key as keyof typeof model]

            if (model.src !== undefined) {
              if (model.src === null) {
                // @ts-ignore
                el.src = ''
              } else {
                // @ts-ignore
                el.src = model.src
              }
            }
          }
        })

        console.log('updated', el)
        this.elementsMap.set(model.id, new ImageElement(el))

      }
      else if (element instanceof FormElement) {
        const el = { ...element }

        Object.keys(model).forEach((key) => {
          if (key && model[key as keyof typeof model] !== undefined) {
            // @ts-ignore
            el[key] = model[key as keyof typeof model]

            console.log(model)
            if (model.buttonTypefaceId) {
              this.tryUpdateTypeface(model.buttonTypefaceId)
            }
            if (model.labelTypefaceId) {
              this.tryUpdateTypeface(model.labelTypefaceId)
            }
            if (model.fieldTypefaceId) {
              this.tryUpdateTypeface(model.fieldTypefaceId)
            }
            if (model.formSubmitTextTypefaceId) {
              this.tryUpdateTypeface(model.formSubmitTextTypefaceId)
            }
          }
        })

        console.log('updated', el)
        this.elementsMap.set(model.id, new FormElement(el))

      }
      else if (element instanceof ShapeElement) {

        console.log(model)
        const el = { ...element }

        Object.keys(model).forEach((key) => {
          if (key && model[key as keyof typeof model] !== undefined) {
            // @ts-ignore
            el[key] = model[key as keyof typeof model]

          }
        })

        console.log('updated', el)
        this.elementsMap.set(model.id, new ShapeElement(el))
      }
      else if (element instanceof ButtonElement) {

        console.log(model)
        const el = { ...element }

        Object.keys(model).forEach((key) => {
          if (key && model[key as keyof typeof model] !== undefined) {
            // @ts-ignore
            el[key] = model[key as keyof typeof model]

          }
        })

        console.log('updated', el)
        this.elementsMap.set(model.id, new ButtonElement(el))
      }
      else if (element instanceof LanguageSwitcherElement) {

        console.log(model)
        const el = { ...element }

        Object.keys(model).forEach((key) => {
          if (key && model[key as keyof typeof model] !== undefined) {
            // @ts-ignore
            el[key] = model[key as keyof typeof model]

            if (model.typefaceId) {
              this.tryUpdateTypeface(model.typefaceId)
            }
          }
        })

        console.log('updated', el)
        this.elementsMap.set(model.id, new LanguageSwitcherElement(el))
      }
    }
  };

  tryUpdateTypeface = (typefaceId: string) => {
    const existing = this.websiteData.typeface[typefaceId]
    if (!existing) {
      this.websiteData.typeface[typefaceId] = {
        provider: 'google',
        name: typefaceId,
      }
    }
  }

  @computed
  get selectedId() {
    return this.selected?.id
  }

  @computed
  get websitePages() {
    return Object.keys(this.websiteData.pages).map(itm => ({
      id: itm,
      value: this.websiteData.pages[itm]
    }))
  }

  // @computed
  // get getFonts() {
  //   return this.fonts
  // }

  @computed
  get getActivePageHeight() {
    return this.page.height
  }

  @computed
  get allFonts() {
    return Object.keys(this.websiteData.typeface).map(key => this.websiteData.typeface[key])
  }

  @computed
  get getCustomFonts() {
    const fonts = Object.keys(this.websiteData.typeface).map(key => this.websiteData.typeface[key])
    const typekitFonts = fonts.filter(itm => itm.provider === 'typekit')
    const fontdeskFonts = fonts.filter(itm => itm.provider === 'fontdesk')
    const fontscomFonts = fonts.filter(itm => itm.provider === 'fontscom')
    console.log('spacefonts', [
      ...typekitFonts.map(itm => itm.name),
      ...fontdeskFonts.map(itm => itm.name),
      ...fontscomFonts.map(itm => itm.name),
    ])
    return [
      ...typekitFonts.map(itm => itm.name),
      ...fontdeskFonts.map(itm => itm.name),
      ...fontscomFonts.map(itm => itm.name),
    ]
  }

  @action
  selectElement = (id: string | null, withShift: boolean) => {
    if (id) {
      if (withShift) {

        console.log('whith shift', this.selected?.id, id)
        if (!this.selectedWithShift.includes(id)) {
          this.selectedWithShift.push(id)
        }

        if (this.selected) {
          if (!this.selectedWithShift.includes(this.selected.id)) {
            this.selectedWithShift.push(this.selected.id)
          }
          this.selected = null
        }
      } else {
        this.selectedWithShift = []

        const el = this.elementsMap.get(id)
        if (el) {
          this.selected = el
        }
      }
    } else {
      this.selectedWithShift = []
      this.selected = null
    }
  }

  @action
  duplicate = (id: string, parentId?: string) => {
    const elementAttributes = JSON.parse(JSON.stringify(this.websiteData.pages[this.pageId].media[this.currentViewport].components[id]))
    const elementContent = JSON.parse(JSON.stringify(this.websiteData.pages[this.pageId].content[id]))
    const key = randomString()
    const zIndex = this.getHightestZIndex() + 1


    this.websiteData.pages[this.pageId].media[this.currentViewport].components[key] = {
      attributes: {
        ...elementAttributes.attributes,
        x: parentId ? elementAttributes.attributes.x : elementAttributes.attributes.x + elementAttributes.attributes.width + 10,
        zIndex,
        parentId: parentId ? parentId : elementAttributes.attributes.parentId
      },
    }

    if (elementContent) {
      this.websiteData.pages[this.pageId].content[key] = elementContent
    }

    const element = this.elementsMap.get(id)
    if (element) {
      const newEl = element.toJSON()
      const newElement = {
        ...newEl,
        id: key,
        x: parentId ? newEl.x : (isNumber(newEl.width) ? (newEl.x + (+newEl.width) + 10) : newEl.x),
        zIndex,
        parentId: parentId ? parentId : newEl.parentId
      }

      // @ts-ignore
      if (element instanceof TextElement) {
        // @ts-ignore
        this.elementsMap.set(key, new TextElement(newElement))
      }
      if (element instanceof ButtonElement) {
        // @ts-ignore
        this.elementsMap.set(key, new ButtonElement(newElement))
      }
      if (element instanceof ImageElement) {
        // @ts-ignore
        this.elementsMap.set(key, new ImageElement(newElement))
      }    
      if (element instanceof LanguageSwitcherElement) {
        // @ts-ignore
        this.elementsMap.set(key, new LanguageSwitcherElement(newElement))
      }
      if (element instanceof ShapeElement) {
        // @ts-ignore
        this.elementsMap.set(key, new ShapeElement(newElement))
      }
      if (element instanceof GroupElement) {
        // @ts-ignore
        this.elementsMap.set(key, new GroupElement(newElement))

        const list = Object.keys(this.websiteData.pages[this.pageId].media[this.currentViewport].components).filter((key) => {
          return this.websiteData.pages[this.pageId].media[this.currentViewport].components[key].attributes.parentId === id
        })

        for (const el of list) {
          this.duplicate(el, key)
        }
      }
      if (element instanceof FormElement) {
        // @ts-ignore
        this.elementsMap.set(key, new FormElement(newElement))
      }
    }
  }

  getLatestFont = () => {
    const keys = Object.keys(this.websiteData.typeface)
    if (keys.length > 0) {
      return keys[keys.length - 1]
    } else {
      return 'Inter'
    }
  }
  @action
  addElement = (type: CONTENT_TYPE, model?: {
    type?: "IMAGE" | "VIDEO" | "SLIDER" | "CODE" | "SHAPE",
    x?: number,
    y?: number,
    width?: number,
    height?: number,
  }) => {
    const key = randomString()

    console.log(this.viewportScrollHeight * this.zoom)
    const commonAttributes = {
      position: 'absolute' as 'absolute',
      x: model?.x || this.currentViewport / 2 - 100,
      y: model?.y || Math.max(0, this.scrollOffset + 100),
      width: model?.width || 200,
      height: model?.height || 200,
      opacity: 1,
      bgColor: null,
      parentId: null,
      zIndex: this.getHightestZIndex() + 1,
      link: null,
      name: null,
      rotate: 0,
      parralax: null,
      anchor: null
    }

    if (type === CONTENT_TYPE.TEXT) {
      const content: TextContent = {
        text: 'Select here to edit',
      }
      const attributes: CommonAttributes & TextAttributes = {
        ...commonAttributes,
        fontSize: this.lastUsedFontSize || 32,
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: 0,
        textDecoration: 'none',
        color: this.lastUsedFontColor || '#000000ff',
        textAlign: 'left',
        typefaceId: this.lastUsedTypefaceId || this.getLatestFont(),
        animation: {
          type: 'none',
        },
        ...model,
      }

      this.elementsMap.set(key, new TextElement({
        id: key,
        ...attributes,
        ...content
      }))


      this.websiteData.pages[this.pageId].content[key] = {
        type: CONTENT_TYPE.TEXT,
        content,
      }

      // this.stack.push({
      //   type: 'ADD',
      //   id: key,
      //   attributes,
      //   content,
      // })

      if (this.currentViewport === 1440) {
        this.websiteData.pages[this.pageId].media[1440].components[key] = {
          attributes,
        }
        this.websiteData.pages[this.pageId].media[810].components[key] = {
          attributes,
        }
        this.websiteData.pages[this.pageId].media[320].components[key] = {
          attributes,
        }
      } else if (this.currentViewport === 810) {
        this.websiteData.pages[this.pageId].media[810].components[key] = {
          attributes,
        }
        this.websiteData.pages[this.pageId].media[320].components[key] = {
          attributes,
        }
      } else if (this.currentViewport === 320) {
        this.websiteData.pages[this.pageId].media[320].components[key] = {
          attributes,
        }
      }

    }
    if (type === CONTENT_TYPE.BUTTON) {
      const content: ButtonContent = {
        text: 'Click here',
      }
      const attributes: CommonAttributes & ButtonAttributes = {
        ...commonAttributes,

        width: 200,
        height: 50,
        fontSize: this.lastUsedFontSize || 20,
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: 0,
        textDecoration: 'none',
        color: '#ffffff',
        textAlign: 'center',
        bgColor: '#246bfa',
        typefaceId: this.lastUsedTypefaceId || this.getLatestFont(),
        hoverColor: null,
        hoverBgColor: '#214fac',
        borderRadius: 8,
        borderWidth: 0,
        borderColor: null,

        animation: {
          type: 'none',
        },
        ...model,
      }

      this.elementsMap.set(key, new ButtonElement({
        id: key,
        showHoverState: false,
        ...attributes,
        ...content
      }))

      this.websiteData.pages[this.pageId].content[key] = {
        type: CONTENT_TYPE.BUTTON,
        content,
      }

      // this.stack.push({
      //   type: 'ADD',
      //   id: key,
      //   attributes,
      //   content,
      // })

      if (this.currentViewport === 1440) {
        this.websiteData.pages[this.pageId].media[1440].components[key] = {
          attributes,
        }
        // this.websiteData.pages[this.pageId].media[810].components[key] = {
        //   attributes,
        // }
        // this.websiteData.pages[this.pageId].media[320].components[key] = {
        //   attributes,
        // }
      } else if (this.currentViewport === 810) {
        this.websiteData.pages[this.pageId].media[810].components[key] = {
          attributes,
        }
        // this.websiteData.pages[this.pageId].media[320].components[key] = {
        //   attributes,
        // }
      } else if (this.currentViewport === 320) {
        this.websiteData.pages[this.pageId].media[320].components[key] = {
          attributes,
        }
      }
    }
    if (type === CONTENT_TYPE.LANGUAGESWITCHER) {
      const content: LanguageSwitcherContent = {
        hasCaret: true,
        hasIcon: true
      }
      const attributes: CommonAttributes & LanguageSwitcherAttributes = {
        ...commonAttributes,

        width: 200,
        height: 50,
        fontSize: this.lastUsedFontSize || 20,
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: 0,
        textDecoration: 'none',
        textColor: '#ffffff',
        bgColor: '#246bfa',
        typefaceId: this.lastUsedTypefaceId || this.getLatestFont(),

        borderRadius: 8,
        borderWidth: 0,
        borderColor: null,

        caretColor: '#ffffff',
        iconColor: '#ffffff',

        caretSize: '10',
        iconSize: '10',
        animation: {
          type: 'none',
        },
        ...model,
      }

      this.elementsMap.set(key, new LanguageSwitcherElement({
        id: key,
        ...attributes,
        ...content
      }))

      this.websiteData.pages[this.pageId].content[key] = {
        type: CONTENT_TYPE.LANGUAGESWITCHER,
        content,
      }

      // this.stack.push({
      //   type: 'ADD',
      //   id: key,
      //   attributes,
      //   content,
      // })

      if (this.currentViewport === 1440) {
        this.websiteData.pages[this.pageId].media[1440].components[key] = {
          attributes,
        }
        // this.websiteData.pages[this.pageId].media[810].components[key] = {
        //   attributes,
        // }
        // this.websiteData.pages[this.pageId].media[320].components[key] = {
        //   attributes,
        // }
      } else if (this.currentViewport === 810) {
        this.websiteData.pages[this.pageId].media[810].components[key] = {
          attributes,
        }
        // this.websiteData.pages[this.pageId].media[320].components[key] = {
        //   attributes,
        // }
      } else if (this.currentViewport === 320) {
        this.websiteData.pages[this.pageId].media[320].components[key] = {
          attributes,
        }
      }
    }
    if (type === CONTENT_TYPE.FORM) {
      const content: FormContent = {
        "fields": [
          {
            "type": "text",
            "label": "Name",
            "required": true,
            "placeholder": "enter your name here"
          },
          {
            "type": "email",
            "label": "Email",
            "required": true,
            "placeholder": "corporate or personal"
          }
        ],
        "buttonText": "Contact me",
        "formSubmitText": "Thank you for submit, we will contact you soon"
      }
      const attributes: CommonAttributes & FormAttributes = {
        ...commonAttributes,
        "link": null,
        "name": null,
        "width": 381,
        "height": 324,
        "rotate": 0,
        "bgColor": null,
        "opacity": 1,
        "parentId": null,
        "position": "absolute",
        "animation": {
          "type": "none"
        },
        "boxShadow": null,
        "borderColor": "",
        "borderWidth": 0,
        "buttonFluid": true,
        "buttonWidth": 50,
        "borderRadius": 0,
        "fieldFontSize": 22,
        "labelFontSize": 28,
        "buttonFontSize": 22,
        "fieldTextAlign": "left",
        "fieldTextColor": "#000000ff",
        "labelTextAlign": "left",
        "labelTextColor": "#000000ff",
        "backgroundColor": "",
        "buttonTextAlign": "left",
        "buttonTextColor": "#ffffffff",
        "fieldFontWeight": 500,
        "fieldLineHeight": 0,
        "fieldPaddingTop": 10,
        "fieldTypefaceId": this.getLatestFont(),
        "labelFontWeight": 500,
        "labelLineHeight": 0,
        "labelPaddingTop": 6,
        "labelTypefaceId": this.getLatestFont(),
        "buttonFontWeight": 500,
        "buttonLineHeight": 0,
        "buttonPaddingTop": 14,
        "buttonTypefaceId": this.getLatestFont(),
        "fieldBorderColor": "#000000ff",
        "fieldBorderWidth": 2,
        "fieldPaddingLeft": 12,
        "labelPaddingLeft": 10,
        "buttonBorderColor": "#ffffffff",
        "buttonBorderWidth": 0,
        "buttonPaddingLeft": 10,
        "fieldBorderRadius": 5,
        "buttonBorderRadius": 5,
        "fieldLetterSpacing": 0,
        "labelLetterSpacing": 0,
        "buttonLetterSpacing": 0,
        "fieldTextDecoration": "",
        "labelTextDecoration": "",
        "buttonTextDecoration": "",
        "fieldBackgroundColor": "#ffffffff",
        "buttonBackgroundColor": "#236bfaff",
        "formSubmitTextFontSize": 22,
        "formSubmitTextTextAlign": "center",
        "formSubmitTextTextColor": "black",
        "formSubmitTextFontWeight": 500,
        "formSubmitTextLineHeight": 0,
        "formSubmitTextTypefaceId": this.getLatestFont(),
        "formSubmitTextLetterSpacing": 0,
        "formSubmitTextTextDecoration": "",
        ...model,
      }

      this.elementsMap.set(key, new FormElement({
        id: key,
        ...attributes,
        ...content,
        displaySavedState: false,
      }))

      this.websiteData.pages[this.pageId].content[key] = {
        type: CONTENT_TYPE.FORM,
        content,
      }

      // this.stack.push({
      //   type: 'ADD',
      //   id: key,
      //   attributes,
      //   content,
      // })
      if (this.currentViewport === 1440) {
        this.websiteData.pages[this.pageId].media[1440].components[key] = {
          attributes,
        }
        // this.websiteData.pages[this.pageId].media[810].components[key] = {
        //   attributes,
        // }
        // this.websiteData.pages[this.pageId].media[320].components[key] = {
        //   attributes,
        // }
      } else if (this.currentViewport === 810) {
        this.websiteData.pages[this.pageId].media[810].components[key] = {
          attributes,
        }
        // this.websiteData.pages[this.pageId].media[320].components[key] = {
        //   attributes,
        // }
      } else if (this.currentViewport === 320) {
        this.websiteData.pages[this.pageId].media[320].components[key] = {
          attributes,
        }
      }
    }
    if (type === CONTENT_TYPE.SHAPE) {
      const content = {
        type: model?.type as "CODE" | "SHAPE",
        code: '',
      }
      const attributes: CommonAttributes & ShapeAttributes = {
        ...commonAttributes,
        bgColor: model?.type === 'CODE' ? '' : 'rgba(170, 185, 191, 1)',
        borderRadius: 0,
        borderWidth: 0,
        borderColor: null,
        boxShadow: null,
        animation: {
          type: 'none',
        },
        ...model,
      }
      this.elementsMap.set(key, new ShapeElement({
        id: key,
        ...content,
        ...attributes,
      }))

      this.websiteData.pages[this.pageId].content[key] = {
        type: CONTENT_TYPE.SHAPE,
        content,
      }

      // this.stack.push({
      //   type: 'ADD',
      //   id: key,
      //   attributes,
      //   content,
      // })
      if (this.currentViewport === 1440) {
        this.websiteData.pages[this.pageId].media[1440].components[key] = {
          attributes,
        }
        // this.websiteData.pages[this.pageId].media[810].components[key] = {
        //   attributes,
        // }
        // this.websiteData.pages[this.pageId].media[320].components[key] = {
        //   attributes,
        // }
      } else if (this.currentViewport === 810) {
        this.websiteData.pages[this.pageId].media[810].components[key] = {
          attributes,
        }
        // this.websiteData.pages[this.pageId].media[320].components[key] = {
        //   attributes,
        // }
      } else if (this.currentViewport === 320) {
        this.websiteData.pages[this.pageId].media[320].components[key] = {
          attributes,
        }
      }
    }
    if (type === CONTENT_TYPE.IMAGE) {
      const content: ImageContent = {
        src: '',
        alt: '',
        type: model?.type as "IMAGE" | "VIDEO" | "SLIDER",
        set: [],
        sliderDuration: 2000,
        transitionDuration: 100,
      }
      const attributes: CommonAttributes & ImageAttributes = {
        ...commonAttributes,
        bgColor: '',
        borderRadius: 0,
        borderWidth: 0,
        borderColor: null,
        boxShadow: null,
        objectFit: 'cover',
        animation: {
          type: 'none',
        },
        ...model,
      }
      this.websiteData.pages[this.pageId].content[key] = {
        type: CONTENT_TYPE.IMAGE,
        content,
      }

      // this.stack.push({
      //   type: 'ADD',
      //   id: key,
      //   attributes,
      //   content,
      // })
      this.elementsMap.set(key, new ImageElement({
        id: key,
        ...content,
        ...attributes,
      }))
      if (this.currentViewport === 1440) {
        this.websiteData.pages[this.pageId].media[1440].components[key] = {
          attributes,
        }
        // this.websiteData.pages[this.pageId].media[810].components[key] = {
        //   attributes,
        // }
        // this.websiteData.pages[this.pageId].media[320].components[key] = {
        //   attributes,
        // }
      } else if (this.currentViewport === 810) {
        this.websiteData.pages[this.pageId].media[810].components[key] = {
          attributes,
        }
        // this.websiteData.pages[this.pageId].media[320].components[key] = {
        //   attributes,
        // }
      } else if (this.currentViewport === 320) {
        this.websiteData.pages[this.pageId].media[320].components[key] = {
          attributes,
        }
      }
    }


    if (type === CONTENT_TYPE.GROUP) {

      const attributes: CommonAttributes & GroupAttributes = {
        ...commonAttributes,
        bgColor: '',
        borderRadius: 0,
        borderWidth: 0,
        borderColor: null,
        boxShadow: null,
        animation: {
          type: 'none',
        },
        ...model,
      }
      this.elementsMap.set(key, new GroupElement({
        id: key,
        ...attributes,
      }))
      this.websiteData.pages[this.pageId].content[key] = {
        type: CONTENT_TYPE.GROUP,
        content: null
      }

      // this.stack.push({
      //   type: 'ADD',
      //   id: key,
      //   attributes,
      //   content: null,
      // })
      if (this.currentViewport === 1440) {
        this.websiteData.pages[this.pageId].media[1440].components[key] = {
          attributes,
        }
        // this.websiteData.pages[this.pageId].media[810].components[key] = {
        //   attributes,
        // }
        // this.websiteData.pages[this.pageId].media[320].components[key] = {
        //   attributes,
        // }
      } else if (this.currentViewport === 810) {
        this.websiteData.pages[this.pageId].media[810].components[key] = {
          attributes,
        }
        // this.websiteData.pages[this.pageId].media[320].components[key] = {
        //   attributes,
        // }
      } else if (this.currentViewport === 320) {
        this.websiteData.pages[this.pageId].media[320].components[key] = {
          attributes,
        }
      }
    }

    this.selected = this.elementsMap.get(key)!
    this.selectedWithShift = []

    return key
  }

  @action
  deleteElement = (id: string) => {
    this.selected = null
    this.elementsMap.delete(id)






    if (this.currentViewport === 1440) {
      // this.stack.push({
      //   type: 'DELETE',
      //   id: id,
      //   attributes: this.websiteData.pages[this.pageId].media[1440].components[id].attributes,
      //   content: this.websiteData.pages[this.pageId].content[id]
      // })

      delete this.websiteData.pages[this.pageId].media[1440].components[id]
      delete this.websiteData.pages[this.pageId].media[810].components[id]
      delete this.websiteData.pages[this.pageId].media[320].components[id]
      delete this.websiteData.pages[this.pageId].content[id]
      // TODO
      // Object.keys(this.websiteData.pages[this.pageId].alternativeLangsContent).forEach((key) => {
      //   delete this.websiteData.pages[this.pageId].alternativeLangsContent[key].content[id]
      // })

    } else if (this.currentViewport === 810) {
      // this.stack.push({
      //   type: 'DELETE',
      //   id: id,
      //   attributes: this.websiteData.pages[this.pageId].media[810].components[id].attributes,
      //   content: this.websiteData.pages[this.pageId].content[id]
      // })

      delete this.websiteData.pages[this.pageId].media[810].components[id]
      delete this.websiteData.pages[this.pageId].media[320].components[id]
    } else if (this.currentViewport === 320) {
      // this.stack.push({
      //   type: 'DELETE',
      //   id: id,
      //   attributes: this.websiteData.pages[this.pageId].media[320].components[id].attributes,
      //   content: this.websiteData.pages[this.pageId].content[id]
      // })

      delete this.websiteData.pages[this.pageId].media[320].components[id]
    }
  }
  @action
  changeViewport = (vieport: 1440 | 810 | 320) => {
    this.lastUsedTypefaceId = null
    this.lastUsedFontColor = null
    this.lastUsedFontSize = null
    this.stack = []
    this.redoStack = []

    this.selected = null
    this.elementsMap.clear()
    this.setElements(this.websiteData, vieport, this.pageId)
    this.currentViewport = vieport
  }

  @action
  generateFromVeiport = (vieport: 810 | 320) => {

    const source = vieport === 810 ? 1440 : 810
    const zoom = vieport / source
    this.websiteData.pages[this.pageId].media[vieport] = JSON.parse(JSON.stringify(this.websiteData.pages[this.pageId].media[source]))
    this.websiteData.pages[this.pageId].media[vieport].pageStyle.height = isNumber(this.websiteData.pages[this.pageId].media[vieport].pageStyle.height) ? +this.websiteData.pages[this.pageId].media[vieport].pageStyle.height * zoom : this.websiteData.pages[this.pageId].media[vieport].pageStyle.height

    Object.keys(this.websiteData.pages[this.pageId].media[vieport].components).forEach((key) => {
      const el = this.websiteData.pages[this.pageId].media[vieport].components[key]
      el.attributes.x = el.attributes.x * zoom
      el.attributes.y = el.attributes.y * zoom
      el.attributes.width = isNumber(el.attributes.width) ? +el.attributes.width * zoom : el.attributes.width
      el.attributes.height = isNumber(el.attributes.width) ? +el.attributes.height * zoom : el.attributes.height

      if (el.attributes.parralax) {
        el.attributes.parralax.margin = el.attributes.parralax.margin * zoom
        el.attributes.parralax.width = el.attributes.parralax.width * zoom
        el.attributes.parralax.velocity = el.attributes.parralax.velocity * zoom
      }

      if ((el.attributes as TextAttributes).fontSize) {
        (el.attributes as TextAttributes).fontSize = (el.attributes as TextAttributes).fontSize * zoom
      }

      if ((el.attributes as TextAttributes).lineHeight) {
        (el.attributes as TextAttributes).lineHeight = (el.attributes as TextAttributes).lineHeight * zoom
      }
      if ((el.attributes as TextAttributes).letterSpacing) {
        (el.attributes as TextAttributes).letterSpacing = (el.attributes as TextAttributes).letterSpacing * zoom
      }
      // borderRadius
      if ((el.attributes as ShapeAttributes).borderRadius) {
        (el.attributes as ShapeAttributes).borderRadius = (el.attributes as ShapeAttributes).borderRadius * zoom
      }

      if ((el.attributes as FormAttributes).labelFontSize) {
        (el.attributes as FormAttributes).labelFontSize = (el.attributes as FormAttributes).labelFontSize * zoom
      }
      if ((el.attributes as FormAttributes).buttonFontSize) {
        (el.attributes as FormAttributes).buttonFontSize = (el.attributes as FormAttributes).buttonFontSize * zoom
      }
      if ((el.attributes as FormAttributes).fieldFontSize) {
        (el.attributes as FormAttributes).fieldFontSize = (el.attributes as FormAttributes).fieldFontSize * zoom
      }
      if ((el.attributes as FormAttributes).formSubmitTextFontSize) {
        (el.attributes as FormAttributes).formSubmitTextFontSize = (el.attributes as FormAttributes).formSubmitTextFontSize * zoom
      }
      if ((el.attributes as FormAttributes).fieldPaddingLeft) {
        (el.attributes as FormAttributes).fieldPaddingLeft = (el.attributes as FormAttributes).fieldPaddingLeft * zoom
      }
      if ((el.attributes as FormAttributes).fieldPaddingTop) {
        (el.attributes as FormAttributes).fieldPaddingTop = (el.attributes as FormAttributes).fieldPaddingTop * zoom
      }

      if ((el.attributes as FormAttributes).labelPaddingLeft) {
        (el.attributes as FormAttributes).labelPaddingLeft = (el.attributes as FormAttributes).labelPaddingLeft * zoom
      }
      if ((el.attributes as FormAttributes).labelPaddingTop) {
        (el.attributes as FormAttributes).labelPaddingTop = (el.attributes as FormAttributes).labelPaddingTop * zoom
      }
      if ((el.attributes as FormAttributes).buttonPaddingLeft) {
        (el.attributes as FormAttributes).buttonPaddingLeft = (el.attributes as FormAttributes).buttonPaddingLeft * zoom
      }
      if ((el.attributes as FormAttributes).buttonPaddingTop) {
        (el.attributes as FormAttributes).buttonPaddingTop = (el.attributes as FormAttributes).buttonPaddingTop * zoom
      } 
      if ((el.attributes as LanguageSwitcherAttributes).iconSize) {
        (el.attributes as LanguageSwitcherAttributes).iconSize = (el.attributes as LanguageSwitcherAttributes).iconSize * zoom
      }   
      if ((el.attributes as LanguageSwitcherAttributes).caretSize) {
        (el.attributes as LanguageSwitcherAttributes).caretSize = (el.attributes as LanguageSwitcherAttributes).caretSize * zoom
      }
    })

    this.changeViewport(this.currentViewport)
  }

  @action
  groupElements = () => {
    const ids = this.selectedWithShift

    const elements: (ShapeElement | TextElement | ImageElement | FormElement | ButtonElement | GroupElement)[] = []

    ids.forEach((id) => {
      const element = this.elementsMap.get(id)

      if (element) {
        if (element instanceof GroupElement) {
          const ids = this.ungroupElements(id)
          if (ids) {
            for (const id of ids) {
              const el = this.elementsMap.get(id)
              if (el) {
                elements.push(el)
              }
            }
          }
        } else {
          elements.push(element)
        }
      }
    })

    const minimumX = Math.min(...elements.map((el) => el.x))
    const miminumY = Math.min(...elements.map((el) => el.y))

    const maximumX = Math.max(...elements.map((el) => {
      return isNumber(el.width) ? el.x + +el.width : el.x
    }))
    const maximumY = Math.max(...elements.map((el) => {
      return isNumber(el.height) ? el.y + +el.height : el.y
    }))

    const key = this.addElement(CONTENT_TYPE.GROUP, {
      x: minimumX,
      y: miminumY,
      width: maximumX - minimumX,
      height: maximumY - miminumY,
    })

    for (const element of elements) {

      element.x = element.x - minimumX
      element.y = element.y - miminumY
      element.parentId = key

      this.websiteData.pages[this.pageId].media[this.currentViewport].components[element.id].attributes.x = element.x
      this.websiteData.pages[this.pageId].media[this.currentViewport].components[element.id].attributes.y = element.y
      this.websiteData.pages[this.pageId].media[this.currentViewport].components[element.id].attributes.parentId = key
    }

    this.selectedWithShift = []
    this.selectElement(key, false)
  }

  @action
  setLockAspectRation = (val: boolean) => {
    this.lockAspectRatio = val
  }

  @action
  ungroupElements = (id: string) => {
    if (id) {
      const element = this.elementsMap.get(id)

      if (element instanceof GroupElement) {
        const elX = element.x
        const elY = element.y

        const ids = []
        const elements = Array.from(this.elementsMap.values());

        for (const el of elements) {
          if (el.parentId === id) {

            this.updateElementProps({
              id: el.id,
              parentId: null,
              x: el.x + elX,
              y: el.y + elY,
            }, false)

            ids.push(el.id)

          }
        }


        this.deleteElement(id)
        return ids
      }
    }
  }

  dublicatePage = (pageId: string) => {
    const newPageId = randomString()

    const copy = JSON.parse(JSON.stringify(this.websiteData.pages[pageId]))
    this.websiteData.pages[newPageId] = {
      ...copy,
      homepage: false,
      name: `Copy of ${this.websiteData.pages[pageId].name}`,
      url: `copy-of-${this.websiteData.pages[pageId].url}`,
    }
  }

  updateWebsiteData = (id: string, key: string, value: any) => {
    if (key !== 'id') {
      if (key === 'src') {
        if (value === null) {
          (this.websiteData.pages[this.pageId].content[id].content as ImageContent).src = ''
        } else {
          (this.websiteData.pages[this.pageId].content[id].content as ImageContent).src = value
        }
      }
      if (key === 'alt') {
        if (value === null) {
          (this.websiteData.pages[this.pageId].content[id].content as ImageContent).alt = ''
        } else {
          (this.websiteData.pages[this.pageId].content[id].content as ImageContent).alt = value
        }
      }
      else if (key === 'set') {
        if (value === null) {
          (this.websiteData.pages[this.pageId].content[id].content as ImageContent).set = []
        } else {
          (this.websiteData.pages[this.pageId].content[id].content as ImageContent).set = value
        }
      }
      else if (key === 'code') {
        if (value === null) {
          (this.websiteData.pages[this.pageId].content[id].content as ShapeContent).code = ''
        } else {
          (this.websiteData.pages[this.pageId].content[id].content as ShapeContent).code = value
        }
      }
      else if (key === 'sliderDuration') {
        (this.websiteData.pages[this.pageId].content[id].content as ImageContent).sliderDuration = value
      }
      else if (key === 'transitionDuration') {
        (this.websiteData.pages[this.pageId].content[id].content as ImageContent).transitionDuration = value
      }
      else if (key === 'text') {
        (this.websiteData.pages[this.pageId].content[id].content as TextContent).text = value
      }
      else if (key === 'fields') {
        (this.websiteData.pages[this.pageId].content[id].content as FormContent).fields = value
      }
      else if (key === 'buttonText') {
        (this.websiteData.pages[this.pageId].content[id].content as FormContent).buttonText = value
      } else if (key === 'formSubmitText') {
        (this.websiteData.pages[this.pageId].content[id].content as FormContent).formSubmitText = value
      }
      else if (key === 'typefaceId') {
        this.websiteData.typeface[value] = {
          provider: 'google',
          name: value,
        }
        // @ts-ignore
        this.websiteData.pages[this.pageId].media[this.currentViewport].components[id].attributes[key] = value
      } else {
        // @ts-ignore
        this.websiteData.pages[this.pageId].media[this.currentViewport].components[id].attributes[key] = value
        // @ts-ignore
      }
    }

    if (this.selected) {
      // @ts-ignore
      this.selected[key] = value
    }
  }

  // getElementById(id: string, media: number) {
  //   return this.elementsMap.get(id)
  // }
}
