export type PipelineParameters =
  | {
    provider: 'replicate';
    type: 'enlarge';
    outpaintUp: number;
    outpaintDown: number;
    outpaintLeft: number;
    outpaintRight: number;
    inputSrc: string;
    outputSrc: string;
    size: number;
    credits: number;
  }
  | {
    provider: 'replicate';
    type: 'upscale';
    inputSrc: string;
    outputSrc: string;
    credits: number;
  }
  | {
    provider: 'comfy';
    type: 'img2img';
    inputSrc: string;
    prompt: string;
    negativePrompt: string;
    srcset: string[];
    credits: number;
  }
  | {
    provider: 'comfy';
    type: 'relightMask';
    inputSrc: string;
    maskSrc: string;
    prompt: string;
    negativePrompt: string;
    srcset: string[];
    credits: number;
  }
  | {
    provider: 'comfy';
    type: 'changeBackground2';
    inputSrc: string;
    backgroundSrc: string;
    prompt: string;
    negativePrompt: string;
    srcset: string[];
    credits: number;
  }
  | {
    provider: 'falai';
    type: 'img2imgflux';
    inputSrc: string;
    prompt: string;
    negativePrompt: string;
    srcset: string[];
    credits: number;
  }
  | {
    provider: 'replicate';
    type: 'customModelV1';
    modelVersion: string;
    modelName: string;
    prompt: string;
    aspectRation: string;
    loraScale: string;
    secondLoraScale: string;
    secondLoraName: string;
    replicateVersion: string;
    srcset: string[];
    credits: number;
    steps?: number;
    cfg?: number;
    motionBlur: string;
    carPerspective: string;
    color: string;
  }
  | {
    provider: 'replicate';
    type: 'customModel360V1';
    inputSrc: string;
    prompt: string;
    aspectRation: string;
    srcset: string[];
    credits: number;
  }
  | {
    provider: 'replicate';
    type: 'customModel360V2';

    prompt: string;
    aspectRation: string;
    srcset: string[];
    credits: number;
  }
  | {
    provider: 'replicate';
    type: 'fluxControlnet';
    inputSrc: string;
    prompt: string;
    negativePrompt: string;
    srcset: string[];
    credits: number;
  }
  | {
    provider: 'falai';
    type: 'upscaleV1';
    inputSrc: string;
    outputSrc: string;
    credits: number;
  }
  | {
    provider: 'custom';
    type: 'upscaleV1';
    inputSrc: string;
    upscaleType: '4k' | '6k' | '8k';
    outputSrc: string;
    credits: number;
  }
  | {
    provider: 'custom';
    type: 'customModel360HDRV2';
    inputSrc: string;
    prompt: string;
    srcset: string[];
    credits: number;
  } |
  {
    provider: 'custom';
    type: 'customModelV2';
    parameters: any;
    srcset: string[];
    credits: number;
  }
  | {
    provider: 'custom';
    type: 'customModel360HDRV3';
    prompt: string;
    id: string;
    obfuscatedId: string;
    srcset: string[];
    credits: number;
    exportStarted: boolean;
  }
  | {
    provider: 'custom';
    type: 'fixWhiteBalance';
    inputSrc: string;
    srcset: string[];
    credits: number;
  } |
  {
    provider: 'comfy';
    type: 'changeBackground3';
    inputSrc: string;
    backgroundSrc: string;
    prompt: string;
    negativePrompt: string;
    outputId: string;
    srcset: string[];
    credits: number;
  }
  ;

export interface PipelineData {
  jobId: string;
  status: 'pending' | 'completed' | 'ending' | 'failed';
  createdAt: string;
  endAt: string | null;
  params: PipelineParameters;
}

export const defaultNegative = '(curves | deformed lines:1.1), blurry, out of focus, depth of field, people, cars, (watermark, signature, text font, username, logo, words, letters, digits, trademark), (JPEG artifacts)'