import { Flex, Text, Menu, ActionIcon } from "@mantine/core";

export const GearMenu = (model: {
    size: string,
    src: string
    onUpscale: () => void,
    onEnlarge: () => void,
    pickImage: () => void,
}) => {
    return (
        <Flex
            justify={'space-between'}
            align={'center'}>
            <Text fz={12}>{model.size ? `Size: ${model.size}` : ''}</Text>
            <Menu shadow="md">
                <Menu.Target>
                    <ActionIcon>
                        <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" stroke="#828282" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M1.33301 8.58679V7.41345C1.33301 6.72012 1.89967 6.14679 2.59967 6.14679C3.80634 6.14679 4.29967 5.29345 3.69301 4.24679C3.34634 3.64679 3.55301 2.86679 4.15967 2.52012L5.31301 1.86012C5.83967 1.54679 6.51967 1.73345 6.83301 2.26012L6.90634 2.38679C7.50634 3.43345 8.49301 3.43345 9.09967 2.38679L9.17301 2.26012C9.48634 1.73345 10.1663 1.54679 10.693 1.86012L11.8463 2.52012C12.453 2.86679 12.6597 3.64679 12.313 4.24679C11.7063 5.29345 12.1997 6.14679 13.4063 6.14679C14.0997 6.14679 14.673 6.71345 14.673 7.41345V8.58679C14.673 9.28012 14.1063 9.85345 13.4063 9.85345C12.1997 9.85345 11.7063 10.7068 12.313 11.7535C12.6597 12.3601 12.453 13.1335 11.8463 13.4801L10.693 14.1401C10.1663 14.4535 9.48634 14.2668 9.17301 13.7401L9.09967 13.6135C8.49967 12.5668 7.51301 12.5668 6.90634 13.6135L6.83301 13.7401C6.51967 14.2668 5.83967 14.4535 5.31301 14.1401L4.15967 13.4801C3.55301 13.1335 3.34634 12.3535 3.69301 11.7535C4.29967 10.7068 3.80634 9.85345 2.59967 9.85345C1.89967 9.85345 1.33301 9.28012 1.33301 8.58679Z" stroke="#828282" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Label>Actions</Menu.Label>
                    <Menu.Item icon={
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 6V2H10" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M2 10V14H6" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14 2L9 7" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7 9L2 14" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    }

                        onClick={() => model.onUpscale()}
                    >
                        Upscale
                    </Menu.Item>
                    <Menu.Item icon={
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.33301 6.65325V5.99992C1.33301 2.66659 2.66634 1.33325 5.99967 1.33325H9.99967C13.333 1.33325 14.6663 2.66659 14.6663 5.99992V9.99992C14.6663 13.3333 13.333 14.6666 9.99967 14.6666H9.33301" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8.66699 7.33324L12.007 3.98657H9.33366" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12.0068 3.98657V6.65991" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.33301 10.7667V12.5667C7.33301 14.0667 6.73301 14.6667 5.23301 14.6667H3.43301C1.93301 14.6667 1.33301 14.0667 1.33301 12.5667V10.7667C1.33301 9.26675 1.93301 8.66675 3.43301 8.66675H5.23301C6.73301 8.66675 7.33301 9.26675 7.33301 10.7667Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    }

                        onClick={() => model.onEnlarge()}
                    >
                        Enlarge
                    </Menu.Item>
                    <Menu.Item
                        component={"a"}
                        href={model.src}
                        target={"_blank"}
                        download='test.png'
                        icon={
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 7.33325V11.3333L7.33333 9.99992" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6.00033 11.3333L4.66699 10" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M14.6663 6.66659V9.99992C14.6663 13.3333 13.333 14.6666 9.99967 14.6666H5.99967C2.66634 14.6666 1.33301 13.3333 1.33301 9.99992V5.99992C1.33301 2.66659 2.66634 1.33325 5.99967 1.33325H9.33301" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M14.6663 6.66658H11.9997C9.99967 6.66658 9.33301 5.99992 9.33301 3.99992V1.33325L14.6663 6.66658Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        }
                    >
                        Download
                    </Menu.Item>
                    <Menu.Item
                        icon={
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.99967 14.6666H9.99967C13.333 14.6666 14.6663 13.3333 14.6663 9.99992V5.99992C14.6663 2.66659 13.333 1.33325 9.99967 1.33325H5.99967C2.66634 1.33325 1.33301 2.66659 1.33301 5.99992V9.99992C1.33301 13.3333 2.66634 14.6666 5.99967 14.6666Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M5.16699 7.99995L7.05366 9.88661L10.8337 6.11328" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        }
                        onClick={() => {
                            model.pickImage()
                        }}
                    >
                        Pick image
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </Flex>
    )
}