interface InputItem {
    images: { url: string, preview: string}[];
    previewHdrl: string;
    enhancedHdrlFile: string;
    modelVersion?: string;
  }
  
  interface OutputItem {
    image: {url: string, preview: string};
    enhancedHdrlFile: string;
    modelVersion?: string;
  }

export function transformArray(input: InputItem[]): OutputItem[][] {
    const result: OutputItem[][] = [];
    let currentGroup: OutputItem[] = [];
  
    input.forEach((item, index) => {
      const outputItem: OutputItem = {
        image: item.images[0],
        enhancedHdrlFile: item.enhancedHdrlFile,
        modelVersion: item.modelVersion,
      };
  
      currentGroup.push(outputItem);
  
      if (currentGroup.length === 5 || index === input.length - 1) {
        result.push(currentGroup);
        currentGroup = [];
      }
    });
  
    return result;
  }