/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal, Text, rem, Button } from "@mantine/core";
import { useState } from "react";
import { Widget } from "@typeform/embed-react";
import { useStore } from "../../../hooks/use-store";
import { IconHeart } from "@tabler/icons-react";

export const RateComponent = (model: {
    generationId: string
    srcImage: string
}) => {
    const [open, setOpen] = useState(false);

    const {
        uiStore: { authStore },
    } = useStore();

    const widgetContainerStyle = {
        width: "100%",
        height: "100%",
        margin: "20px auto",
    };

    // return null

    return (
        <>
            <Button
                mt={6}
                w={'auto'}
                title='Rate this generation'
                size={'xs'}
                variant="default"
                onClick={() => setOpen(true)}
            >
                <IconHeart style={{ width: rem(18), height: rem(18), marginRight: '6px' }} />
                <Text>Rate this generation</Text>
            </Button>
            <Modal
                size={'xl'}
                styles={{
                    body: {
                        height: '500px'
                    }
                }}
                onClose={() => setOpen(false)}
                opened={open}
                title={'Rate this generation'}
            >
                <Widget
                    id={'DFWthSI5'}
                    style={widgetContainerStyle}
                    hidden={{
                        email: authStore.currentUser!.email!,
                        generationid: model.generationId,
                        srcimage: model.srcImage,
                    }}
                    onSubmit={() => setOpen(false)}
                />
            </Modal>
        </>

    )
}