/* eslint-disable max-len */
import { useEffect, useState } from 'react';

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<{
    width: number | undefined,
    height: string | number | undefined,
  }>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        // @ts-ignore
        width: window.document.documentElement.clientWidth,
        // @ts-ignore
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

export enum CONTENT_TYPE {
  SHAPE = 'SHAPE',
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  GROUP = 'GROUP',
  FORM = 'FORM',
  BUTTON = 'BUTTON',
  LANGUAGESWITCHER = 'LANGUAGESWITCHER',
}

export type AnimationType = {
  type: 'none'
} | {
  type: 'fadein',
} | {
  type: 'scalein'
} | {
  type: 'slidein'
};

export type ParralaxEffect = {
  width: number,
  velocity: number
  margin: number
};

export type CommonAttributes = {
  parentId: string | null
  x: number;
  y: number;
  position: 'absolute' | 'fixed',
  zIndex: number;
  width: string | number;
  height: string | number;
  opacity: number;
  bgColor: string | null;
  animation: AnimationType
  link: string | null;
  name: string | null;
  rotate: number;

  parralax: ParralaxEffect | null

  anchor: string | null
};

export type TextAttributes = {
  fontSize: number;
  fontWeight: number;
  letterSpacing: number;
  lineHeight: number;
  textDecoration: string;
  color: string;
  textAlign: 'left' | 'center' | 'right';
  typefaceId: string;
};

export type LanguageSwitcherAttributes = {
  fontSize: number;
  fontWeight: number;
  letterSpacing: number;
  lineHeight: number;
  textDecoration: string;

  textColor: string;

  caretColor: string;
  iconColor: string;

  caretSize: number;
  iconSize: number;

  typefaceId: string;
  
  borderRadius: number;
  borderWidth: number;
  borderColor: string | null;

};

export type ButtonAttributes = {
  fontSize: number;
  fontWeight: number;
  letterSpacing: number;
  lineHeight: number;
  textDecoration: string;
  color: string;
  textAlign: 'left' | 'center' | 'right';
  typefaceId: string;
  hoverColor: string | null;
  hoverBgColor: string | null;
  borderRadius: number;
  borderWidth: number;
  borderColor: string | null;
};

export type ShapeAttributes = {
  borderRadius: number;
  borderWidth: number;
  borderColor: string | null;
  boxShadow: string | null;
};

export type ImageAttributes = {
  borderRadius: number;
  borderWidth: number;
  borderColor: string | null;
  boxShadow: string | null;
  objectFit: 'cover' | 'contain';
};

export type GroupAttributes = {
  borderRadius: number;
  borderWidth: number;
  borderColor: string | null;
  boxShadow: string | null;
};

export type FormAttributes = {

  borderRadius: number;
  borderWidth: number;
  borderColor: string | null;
  boxShadow: string | null;

  backgroundColor: string;

  labelPaddingLeft: number;
  labelPaddingTop: number;
  labelFontSize: number;
  labelFontWeight: number;
  labelLetterSpacing: number;
  labelLineHeight: number;
  labelTextDecoration: string;
  labelTextColor: string;
  labelTextAlign: 'left' | 'center' | 'right';
  labelTypefaceId: string;

  fieldPaddingLeft: number;
  fieldPaddingTop: number;
  fieldFontSize: number;
  fieldFontWeight: number;
  fieldLetterSpacing: number;
  fieldLineHeight: number;
  fieldTextDecoration: string;
  fieldTextColor: string;
  fieldTextAlign: 'left' | 'center' | 'right';
  fieldTypefaceId: string;
  fieldBackgroundColor: string;

  fieldBorderRadius: number;
  fieldBorderWidth: number;
  fieldBorderColor: string;

  buttonPaddingLeft: number;
  buttonPaddingTop: number;
  buttonFontSize: number;
  buttonFontWeight: number;
  buttonLetterSpacing: number;
  buttonLineHeight: number;
  buttonTextDecoration: string;
  buttonTextColor: string;
  buttonBackgroundColor: string;
  buttonBorderRadius: number;
  buttonBorderWidth: number;
  buttonBorderColor: string;
  buttonTextAlign: 'left' | 'center' | 'right';
  buttonTypefaceId: string;
  buttonFluid: boolean;
  buttonWidth: number;

  formSubmitTextFontSize: number;
  formSubmitTextFontWeight: number;
  formSubmitTextLetterSpacing: number;
  formSubmitTextLineHeight: number;
  formSubmitTextTextDecoration: string;
  formSubmitTextTextColor: string;
  formSubmitTextTextAlign: 'left' | 'center' | 'right';
  formSubmitTextTypefaceId: string;
};

export type TextContent = {
  text: string;
};

export type ButtonContent = {
  text: string;
};

export type LanguageSwitcherContent = {
  hasCaret: boolean;
  hasIcon: boolean;
};

export type ShapeContent = {
  type: 'SHAPE' | 'CODE',
  code: string
};

export type FormContent = {
  buttonText: string;
  formSubmitText: string;
  fields: {
    label: string;
    required: boolean;
    placeholder: string;
    type: 'text' | 'email' | 'number' | 'tel' | 'url' | 'textarea';
  }[];
};

export type ImageContent = {
  type: 'IMAGE' | 'SLIDER' | 'VIDEO'
  src: string;
  alt: string | null;
  set: string[];
  sliderDuration: number;
  transitionDuration: number;
};

export type PageStyle = {
  height: string | number;
  backgroundColor: string | null;
};

export type Content = {
  [key: string]:
  {
    type: CONTENT_TYPE.TEXT;
    content: TextContent
  } | {
    type: CONTENT_TYPE.SHAPE;
    content: ShapeContent
  } | {
    type: CONTENT_TYPE.IMAGE;
    content: ImageContent
  } | {
    type: CONTENT_TYPE.GROUP;
    content: null
  } | {
    type: CONTENT_TYPE.FORM;
    content: FormContent
  } | {
    type: CONTENT_TYPE.BUTTON;
    content: ButtonContent
  } | {
    type: CONTENT_TYPE.LANGUAGESWITCHER;
    content: LanguageSwitcherContent
  }
}
export type WebsitePage = {
  homepage: boolean;
  id: string;
  name: string;
  url: string;
  title: string;
  description: string;
  og: string;
  media: {
    320: {
      pageStyle: PageStyle,
      components: {
        [key: string]: {
          attributes: CommonAttributes & (LanguageSwitcherAttributes | TextAttributes | ShapeAttributes | ImageAttributes | GroupAttributes | FormAttributes | ButtonAttributes)
        }
      }
    },
    810: {
      pageStyle: PageStyle,
      components: {
        [key: string]: {
          attributes: CommonAttributes & (LanguageSwitcherAttributes | TextAttributes | ShapeAttributes | ImageAttributes | GroupAttributes | FormAttributes | ButtonAttributes)
        }
      }
    },
    1440: {
      pageStyle: PageStyle,
      components: {
        [key: string]: {
          attributes: CommonAttributes & (LanguageSwitcherAttributes | TextAttributes | ShapeAttributes | ImageAttributes | GroupAttributes | FormAttributes | ButtonAttributes)
        }
      }
    },
  },
  alternativeLangsContent: {
    [key: string]: Content
  },
  content: Content
};

export namespace TypefaceConfig {
  export interface Google {
    provider: 'google';
    name: string;
  }

  export interface Typekit {
    provider: 'typekit';
    id: string;
    name: string;
  }
  export interface Fontdesk {
    provider: 'fontdesk';
    id: string;
    name: string;
  }
  export interface FontsCom {
    provider: 'fontscom';
    projectId: string;
    name: string;
  }
}

export type LegalPage = {
  enabled: boolean;
  content: string;
};

export type WebsiteData = {
  pages: {
    [key: string]: WebsitePage
  },
  defaultLang?: {
    label: string;
    value: string;
  };
  alternativeLangs?: {
    label: string;
    value: string;
  }[];
  favicon: string,
  title: string;
  description: string;
  og: string;

  scripts: {
    head: string,
    body: string
  },
  legal: {
    privacy: LegalPage,
    terms: LegalPage,
    imprint: LegalPage,
    cookie: LegalPage,
    licenses: LegalPage,
  }
  typeface: {
    [key: string]: TypefaceConfig.Google | TypefaceConfig.Typekit | TypefaceConfig.Fontdesk | TypefaceConfig.FontsCom
  }
};

export const isNumber = (value: any) => typeof value === 'number';
