import { makeObservable, observable, toJS } from "mobx";
import { AnimationType, ParralaxEffect } from "../../../features/workspace/Site/helper";

export interface FormElementProps {
  displaySavedState: boolean;
  buttonText: string;
  formSubmitText: string;
  fields: {
    label: string;
    required: boolean;
    placeholder: string;
    type: 'text' | 'email' | 'number' | 'tel' | 'url' | 'textarea';
  }[];

  backgroundColor: string;

  labelPaddingLeft: number;
  labelPaddingTop: number;
  labelFontSize: number;
  labelFontWeight: number;
  labelLetterSpacing: number;
  labelLineHeight: number;
  labelTextDecoration: string;
  labelTextColor: string;
  labelTextAlign: 'left' | 'center' | 'right';
  labelTypefaceId: string;

  fieldPaddingLeft: number;
  fieldPaddingTop: number;
  fieldFontSize: number;
  fieldFontWeight: number;
  fieldLetterSpacing: number;
  fieldLineHeight: number;
  fieldTextDecoration: string;
  fieldTextColor: string;
  fieldTextAlign: 'left' | 'center' | 'right';
  fieldTypefaceId: string;
  fieldBackgroundColor: string;

  fieldBorderRadius: number;
  fieldBorderWidth: number;
  fieldBorderColor: string;

  buttonPaddingLeft: number;
  buttonPaddingTop: number;
  buttonFontSize: number;
  buttonFontWeight: number;
  buttonLetterSpacing: number;
  buttonLineHeight: number;
  buttonTextDecoration: string;
  buttonTextColor: string;
  buttonBackgroundColor: string;
  buttonBorderRadius: number;
  buttonBorderWidth: number;
  buttonBorderColor: string;
  buttonTextAlign: 'left' | 'center' | 'right';
  buttonTypefaceId: string;
  buttonFluid: boolean;
  buttonWidth: number;

  formSubmitTextFontSize: number;
  formSubmitTextFontWeight: number;
  formSubmitTextLetterSpacing: number;
  formSubmitTextLineHeight: number;
  formSubmitTextTextDecoration: string;
  formSubmitTextTextColor: string;
  formSubmitTextTextAlign: 'left' | 'center' | 'right';
  formSubmitTextTypefaceId: string;

  parentId: string | null
  id: string;
  x: number;
  y: number;
  width:  string | number;
  height:  string | number;
  position: 'absolute' | 'fixed',
  zIndex: number;
  opacity: number;
  bgColor: string | null;
  borderRadius: number;
  borderWidth: number | string | null;
  borderColor: string | null;
  boxShadow: string | null;
  animation: AnimationType
  parralax: ParralaxEffect | null
  
  link: string | null;
  name: string | null;
  rotate: number;
  anchor: string | null
  
}

export default class FormElement {
  @observable anchor: string | null
  @observable displaySavedState: boolean;

  @observable buttonText: string;
  @observable formSubmitText: string;
  @observable fields: {
    label: string;
    required: boolean;
    placeholder: string;
    type: 'text' | 'email' | 'number' | 'tel' | 'url' | 'textarea';
  }[];

  @observable backgroundColor: string;

  @observable labelPaddingLeft: number;
  @observable labelPaddingTop: number;
  @observable labelFontSize: number;
  @observable labelFontWeight: number;
  @observable labelLetterSpacing: number;
  @observable labelLineHeight: number;
  @observable labelTextDecoration: string;
  @observable labelTextColor: string;
  @observable labelTextAlign: 'left' | 'center' | 'right';
  @observable labelTypefaceId: string;

  @observable fieldPaddingLeft: number;
  @observable fieldPaddingTop: number;
  @observable fieldFontSize: number;
  @observable fieldFontWeight: number;
  @observable fieldLetterSpacing: number;
  @observable fieldLineHeight: number;
  @observable fieldTextDecoration: string;
  @observable fieldTextColor: string;
  @observable fieldTextAlign: 'left' | 'center' | 'right';
  @observable fieldTypefaceId: string;
  @observable fieldBackgroundColor: string;
  @observable fieldBorderRadius: number;
  @observable fieldBorderWidth: number;
  @observable fieldBorderColor: string;

  @observable buttonPaddingLeft: number;
  @observable buttonPaddingTop: number;
  @observable buttonFontSize: number;
  @observable buttonFontWeight: number;
  @observable buttonLetterSpacing: number;
  @observable buttonLineHeight: number;
  @observable buttonTextDecoration: string;
  @observable buttonTextColor: string;
  @observable buttonBackgroundColor: string;
  @observable buttonBorderRadius: number;
  @observable buttonBorderWidth: number;
  @observable buttonBorderColor: string;
  @observable buttonTextAlign: 'left' | 'center' | 'right';
  @observable buttonTypefaceId: string;
  @observable buttonFluid: boolean;
  @observable buttonWidth: number;

  @observable formSubmitTextFontSize: number;
  @observable formSubmitTextFontWeight: number;
  @observable formSubmitTextLetterSpacing: number;
  @observable formSubmitTextLineHeight: number;
  @observable formSubmitTextTextDecoration: string;
  @observable formSubmitTextTextColor: string;
  @observable formSubmitTextTextAlign: 'left' | 'center' | 'right';
  @observable formSubmitTextTypefaceId: string;

  @observable parentId: string | null


  @observable id: string;
  @observable x: number;
  @observable y: number;
  @observable width: string | number;
  @observable height:  string | number;
  @observable position: 'absolute' | 'fixed';
  @observable zIndex: number;
  @observable opacity: number;
  @observable bgColor: string | null;
  @observable borderRadius: number;
  @observable borderWidth: number | string | null;
  @observable borderColor: string | null;
  @observable boxShadow: string | null;
  @observable animation: AnimationType
  @observable parralax: ParralaxEffect | null
  
  @observable link: string | null;
  @observable name: string | null;
  @observable rotate: number;

  

  constructor(props: FormElementProps) {
    Object.assign(this, props);
    makeObservable(this);
  }

  toJSON() {
    return toJS(this);
  }
}
