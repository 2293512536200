import { makeObservable, observable, toJS } from "mobx";
import { AnimationType, ParralaxEffect } from "../../../features/workspace/Site/helper";

export interface TextElementProps {
  parentId: string | null

  text: string;

  id: string;
  x: number;
  y: number;
  width:  string | number;
  height:  string | number;
  position: 'absolute' | 'fixed',
  zIndex: number;
  opacity: number;
  bgColor: string | null;
  fontSize: number | string;
  fontWeight: number;
  letterSpacing: number
  lineHeight: number | string;
  textDecoration: string;
  color: string;
  textAlign: 'left' | 'center' | 'right';
  typefaceId: string;
  animation: AnimationType
  parralax: ParralaxEffect | null

  link: string | null;
  name: string | null;
  rotate: number;
  anchor: string | null
}

export default class TextElement {
  @observable anchor: string | null
  @observable parentId: string | null

  @observable text: string;
  @observable typefaceId: string

  @observable id: string;
  @observable x: number;
  @observable y: number;
  @observable width:  string | number;
  @observable height:  string | number;
  @observable position: 'absolute' | 'fixed';
  @observable zIndex: number;
  @observable opacity: number;
  @observable bgColor: string | null;
  @observable fontSize: number;
  @observable fontWeight: number;
  @observable letterSpacing: number;
  @observable lineHeight: number;
  @observable textDecoration: string;
  @observable color: string;
  @observable textAlign: 'left' | 'center' | 'right';
  @observable animation: AnimationType

  @observable link: string | null;
  @observable name: string | null;
  @observable rotate: number;
  @observable parralax: ParralaxEffect | null

  constructor(props: TextElementProps) {
    Object.assign(this, props);
    makeObservable(this);
  }

  toJSON() {
    return toJS(this);
  }
}
