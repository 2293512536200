import CrudService from "../core/crud-service";

export default class ApiService extends CrudService {
  static route = "api/v1";

  async getDefaultFunnel() {
    return await this.httpService.get<any>(`${this.path}/website/default`);
  }

  async getWebsiteById(siteId: string) {
    return await this.httpService.get<any>(
      `${this.path}/website/private/${siteId}`
    );
  }

  async getPipelineById(jobId: string) {
    return await this.httpService.get<any>(
      `${this.path}/pipeline/status/${jobId}`
    );
  }
  async getPipelines() {
    return await this.httpService.get<any>(
      `${this.path}/pipeline/all`
    );
  }
  async getFeaturedData() {
    return await this.httpService.get<any>(
      `${this.path}/pipeline/featured`
    );
  }

  async getStatisticsByWebsiteId(siteId: string) {
    return await this.httpService.get<any>(
      `${this.path}/website/stat/${siteId}`
    );
  }

  async getFunnelsListByUser() {
    return await this.httpService.get<any>(`${this.path}/website/list`);
  }

  async getVideoId() {
    return await this.httpService.get<any>(`${this.path}/website/get-video-id`);
  }

  async updateFunnel(model: { id: string; data?: any; draft?: any, name: string }) {
    return await this.httpService.post<any, any>(
      `${this.path}/website/update`,
      model
    );
  }

  async translate(model: {
    fromLang: string;
    toLang: string;
    texts: {
      key: string;
      value: string;
    }[];
  }) {
    return await this.httpService.post<any, any>(
      `${this.path}/website/translate`,
      model
    );
  }

  async previewSite(model: { draft?: any }) {
    return await this.httpService.post<any, any>(
      `${this.path}/website/preview`,
      model
    );
  }

  async generateWebsite(model: any) {
    return await this.httpService.post<any, any>(
      `${this.path}/website/generate-website`,
      model
    );
  }

  async genTerms(model: any) {
    return await this.httpService.post<any, any>(
      `${this.path}/website/generate-terms`,
      model
    );
  }

  async getCheckoutLink(model: { credits: number, boosted: boolean }) {
    return await this.httpService.post<any, any>(
      `${this.path}/billing/checkout-link`,
      model
    );
  }

  async getPortalLink() {
    return await this.httpService.post<any, any>(
      `${this.path}/billing/portal-link`, {}
    );
  }

  async getFormSubmissionByWebsiteId(siteId: string) {
    return await this.httpService.get<any>(
      `${this.path}/website/forms/${siteId}`
    );
  }

  async convertImage(model: { url: string, removeBackground?: boolean }) {
    return await this.httpService.post<any, any>(
      `${this.path}/pipeline/convert-image`,
      model
    );
  }
  async repairImage(model: { url: string }) {
    return await this.httpService.post<any, any>(
      `${this.path}/pipeline/repair`,
      model
    );
  }

  async addCustomModel(model: { version: string }) {
    return await this.httpService.post<any, any>(
      `${this.path}/pipeline/add-model`,
      model
    );
  }

  async vision(model: { url: string }) {
    return await this.httpService.post<any, any>(
      `${this.path}/pipeline/vision`,
      model
    );
  }

  async convertImageBase64(model: { url: string }) {
    return await this.httpService.post<any, any>(
      `${this.path}/pipeline/convert-image-base64`,
      model
    );
  }

  async updateFreeDomain(model: { siteId: string, freeDomain: string }) {
    return await this.httpService.post<any, any>(
      `${this.path}/website/update/freeDomain`,
      model
    );
  }

  async fetchModels() {
    return await this.httpService.get<any>(
      `${this.path}/pipeline/models`
    );
  }
  async fetchGallery() {
    return await this.httpService.get<any>(
      `${this.path}/pipeline/gallery`
    );
  }

  async train(model: any) {
    return await this.httpService.post<any, any>(
      `${this.path}/pipeline/train`,
      model
    );
  }
  async searchInMaground(model: any) {
    return await this.httpService.post<any, any>(
      `${this.path}/pipeline/search-maground-similar`,
      model
    );
  }
  async addToFeatured(model: any) {
    return await this.httpService.post<any, any>(
      `${this.path}/pipeline/add-to-featured`,
      model
    );
  }
  async removeFromFeatured(model: any) {
    return await this.httpService.post<any, any>(
      `${this.path}/pipeline/remove-featured`,
      model
    );
  }

  async createPipeline(model: any) {
    return await this.httpService.post<any, any>(
      `${this.path}/pipeline/create`,
      model
    );
  }

  async sendSurveyRequest(model: any) {
    return await this.httpService.post<any, any>(
      `${this.path}/pipeline/survey`,
      model
    );
  }

  async updatePremiumDomain(model: { siteId: string, domain: string }) {
    return await this.httpService.post<any, any>(
      `${this.path}/website/update/premiumDomain`,
      model
    );
  }

  async rewrite(model: { text: string; tone: string }) {
    return await this.httpService.post<any, any>(
      `${this.path}/website/rewrite`,
      model
    );
  }

  async sendCode(model: {
    email: string,
    fbp: string | null,
    fbc: string | null,
  }) {
    return await this.httpService.post<any, any>(
      `${this.path}/auth/sendCode`,
      model
    );
  }

  async generateImage(model: { text: string, ratio: string, websiteId: string }) {
    return await this.httpService.post<any, any>(
      `${this.path}/website/generateImage`,
      model
    );
  }

  async createWebsite(model: { name: string, freeDomain?: string, replaceId?: string }) {
    return await this.httpService.post<any, any>(
      `${this.path}/website/create`,
      model
    );
  }

  async deleteFunnel(id: string) {
    return await this.httpService.post<any, any>(
      `${this.path}/website/delete`,
      {
        id,
      }
    );
  }
}
