import { Anchor, Paper, Title, Text, Container, Button, TextInput } from "@mantine/core";

import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";
import { useStore } from "../../../hooks/use-store";
import styles from "./forgotPassword.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "../../../components/Logo";

interface FormProps {
  email: string;
}

const ForgotPassword = () => {
  const {
    uiStore: { authStore },
  } = useStore();
  const { t } = useTranslation();
  const [success, showSuccess] = useState(false);

  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const login = async (data: FormProps) => {
    const res = await authStore.forgotPassword(data.email);

    if (res.ok) {
      showSuccess(true);
    }
  };

  return (
    <div className={styles.container}>
      <Container size={420} my={40}>
        <div className={styles.logo}>
          <Logo size={120} />
        </div>
        <Title  align="center" sx={(theme) => ({ fontWeight: 700 })}>
          Forgot your password? No worries!
        </Title>
        <Text color="dimmed" size="sm" align="center" mt={5}>
          <Link to={"/login"}>
            <Anchor  size="sm" component="button">
              Login here
            </Anchor>
          </Link>
        </Text>

        {success ? (
          <Paper withBorder shadow="md" p={30} mt={30} radius="md" style={{ background: '#ffffff' }}>
            <span>
              {t("forget.sent", {
                defaultValue: "Email has been sent. Please check your inbox",
              })}
            </span>
          </Paper>
        ) : (
          <Paper withBorder shadow="md" p={30} mt={30} radius="md" style={{ background: '#ffffff' }}>
            <form onSubmit={form.onSubmit(async (values) => {
              await login(values)
            })}>
              <TextInput
                required
                type="email"
                placeholder="mail@maground.ai"
                label={t("forget.email", { defaultValue: "Enter email" })}
                name={"email"}
                {...form.getInputProps('email')}
              />

              <Text mt={10} mb={10} fz={12}>
                {t("forget.will.send", {
                  defaultValue: "We will send the instructions to your email",
                })}
              </Text>
              <Button
                fullWidth
                mt="xl"
                loading={false}
                type="submit"
              >
                {t("forget.send.email", {
                  defaultValue: "Send magic link to email",
                })}
              </Button>
            </form>
          </Paper>
        )}
      </Container>
    </div>
  );
};

export default ForgotPassword;
