import UiStore from "./ui/ui-store";
import DataStore from "./data/data-store";
// import { SpaceStore } from "./data/space-store/space-store";

export default class RootStore {
  uiStore: UiStore = {} as UiStore;
  dataStore: DataStore = {} as DataStore;
  // spaceStore: SpaceStore = {} as SpaceStore;

  init() {
    this.uiStore = new UiStore();
    this.dataStore = new DataStore();
    // this.spaceStore = new SpaceStore();
  }
}
