import React from 'react';
import { Transformer, Image } from 'react-konva';
import useImage from 'use-image';

export const Rectangle = (model: { big: boolean, shapeProps: any, isSelected: any, onSelect: any, onChange: any, image: string }) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  const [image] = useImage(model.image, 'anonymous');

  React.useEffect(() => {
    if (model.isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [model.isSelected]);

  return (
    <React.Fragment>
      <Image
        onClick={model.onSelect}
        onTap={model.onSelect}
        ref={shapeRef}
        image={image}
        zIndex={1}
        opacity={model.big ? 1 : 0.7}
        {...model.shapeProps}
        objectFit="cover"
        draggable
        onDragEnd={(e) => {
          model.onChange({
            ...model.shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          model.onChange({
            ...model.shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
      />
      {/* <Images image={image} /> */}
      {model.isSelected && (
        <Transformer
          anchorStroke={'#f53c02'}
          anchorFill={'#f53c02'}
          borderStroke={'#f53c02'}
          ref={trRef}
          flipEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
};
