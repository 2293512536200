import { Flex } from "@mantine/core";
import { LazyLoadImage } from 'react-lazy-load-image-component';
function ImageObserved(model: { className?: string, dark?: boolean, width: string, height: string, src?: string }) {
  return (
    <Flex
      p={model.dark ? "xs" : undefined}
      bg={model.dark ? "#f0f2f1" : '#ffffff'}
      align={'center'}
      justify={'center'}
      className={model.className}
      style={{
        borderRadius: model.dark ? '4px' : undefined,
      }}
      w="100%"
    // w="max-content"
    >
      <LazyLoadImage
        alt={'image'}
        height={model.height}
        src={model.src}
        width={model.width}
        effect="opacity"
        className="lazy-load-image"
      
        wrapperProps={{
          // If you need to, you can tweak the effect transition using the wrapper style.
          style: {objectFit: "contain"},
      }}
      />
    </Flex>
  )
}

export default ImageObserved