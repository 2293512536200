import { useState } from "react";
import {
  createStyles,
  UnstyledButton,
  Menu,
  Image,
  Group,
  rem,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
// import images from './images';
import english from "./images/english.png";
import german from "./images/german.png";
import french from "./images/french.png";
import brazil from "./images/brazil.png";
import spain from "./images/spain.png";
import china from "./images/china.png";
import japan from "./images/japan.png";
import { Languages } from "../../../stores/ui/ui-store";
import { useStore } from "../../../hooks/use-store";

// English = "en",
// Deutsch = "de",
// Portuguese = "pt",
// Spanish = "es",
// French = "fr",
// Chinese = "zh",
// Japanese = "ja",

const data = [
  { label: "English", image: english, value: "en" },
  { label: "German", image: german, value: "de" },
  { label: "French", image: french, value: "fr" },
  { label: "Portuguese", image: brazil, value: "pt" },
  { label: "Spanish", image: spain, value: "es" },
  { label: "Chinese", image: china, value: "zh" },
  { label: "Japanese", image: japan, value: "ja" },
];

const useStyles = createStyles((theme, { opened }: { opened: boolean }) => ({
  control: {
    width: 'max-content',
    minWidth: rem(130),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `0.4rem ${theme.spacing.xs}`,
    borderRadius: theme.radius.md,
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[2]
    }`,
    transition: "background-color 150ms ease",
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[opened ? 5 : 6]
        : opened
        ? theme.colors.gray[0]
        : theme.white,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },
  },

  label: {
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,
  },

  icon: {
    transition: "transform 150ms ease",
    transform: opened ? "rotate(180deg)" : "rotate(0deg)",
  },
}));

const LanguageSwitcher = () => {
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles({ opened });
  // const [selected, setSelected] = useState(data[0]);

  const { uiStore } = useStore();

  const changeLanguage = (lang: string) =>
    uiStore.updateLanguage(lang as Languages);

  const items = data.map((item) => (
    <Menu.Item
      icon={<Image src={item.image} width={18} height={18} />}
      onClick={() => changeLanguage(item.value)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));

  return (
    <Menu
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="md"
      width="target"
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton className={classes.control}>
          <Group spacing="xs">
            <Image
              src={
                data.find((itm) => itm.value === uiStore.currentLanguage)?.image
              }
              width={22}
              height={22}
            />
            <span className={classes.label}>
              {data.find((itm) => itm.value === uiStore.currentLanguage)?.label}
            </span>
          </Group>
          <IconChevronDown size="1rem" className={classes.icon} stroke={1.5} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>{items}</Menu.Dropdown>
    </Menu>
  );
};

export default LanguageSwitcher;
