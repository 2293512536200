import { toast } from "react-toastify";

class Notification {
  errorNotification = (text: any) => {
    const payload = typeof text === "string" ? text : JSON.stringify(text);
    toast.error(payload, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
    });
  };
  successNotification = (text: any) => {
    toast.success(text, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
    });
  };
}

export const NOTIFICATION_SERVICE = new Notification();
