import * as THREE from 'three'
import { Suspense } from 'react'
import { Canvas, useLoader } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
// import { Canvas, extend, useFrame, useThree, useLoader } from 'react-three-fiber'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
// import { Button } from '@mantine/core'
// import './styles.css'

// extend({ OrbitControls })

// function Controls(props: any) {
//     const { camera, gl } = useThree()
//     const ref = useRef()
//     useFrame(() => ref.current.update())
//     return <orbitControls ref={ref} target={[0, 0, 0]} {...props} args={[camera, gl.domElement]} />
// }

function Dome(model: { imageSrc: string }) {
    console.log(model.imageSrc)
    const texture = useLoader(THREE.TextureLoader, model.imageSrc)
    return (
        <mesh>
            <sphereBufferGeometry attach="geometry" args={[500, 60, 40]} />
            <meshBasicMaterial attach="material" map={texture} side={THREE.BackSide} />
        </mesh>
    )
}


const CanvasApp = (model: {
    imageSrc: string,
    // gl: THREE.WebGLRenderer
}) => {




    return (
        <Canvas camera={{ position: [0, 0, 0.1] }} id="pano" gl={{ preserveDrawingBuffer: true }}>
            <OrbitControls 
            // maxDistance={10000} 
            // minDistance={-1000} 
            minZoom={-200} maxZoom={2000} zoom0={0} enabled enableZoom enableDamping dampingFactor={0.2}  rotateSpeed={-0.5} />
            {/* <Controls enableZoom={false} enablePan={false} enableDamping dampingFactor={0.2} rotateSpeed={-0.5} /> */}
            <Suspense fallback={null}>
                <Dome imageSrc={model.imageSrc} />
            </Suspense>
        </Canvas>
    )
}

export default CanvasApp
