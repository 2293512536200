import { Flex, Text, Progress } from "@mantine/core";
import { useEffect } from "react";

export const CustomModelLoader = (model: {
    back: () => void,
}) => {

    useEffect(() => {
        const interval = setTimeout(() => model.back(), 15000);
        return () => clearTimeout(interval!);
    }, [model])

    return (
        <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px', height: '100%' }} gap={12} justify={'center'} direction={'column'} align={'center'}>
            <Text >AI is being trained with this locaiton</Text>
            <Progress radius="lg" size="lg" color="#f53c02" value={100} striped animate={true} w={'400px'} />
            <Text fz={12} maw={'400px'}>You can prompt anything with a trained model and get consistent results.</Text>
            <Text fz={12} maw={'400px'}>Would you like to train your own model? Contact us at <a href="mailto:info@maground.ai">info@maground.ai</a></Text>
            <Text fz={12} maw={'400px'}>You can use any generaiton result as a starting point for creating variation, placing a car, or
                creating new images from the scratch.</Text>
        </Flex>
    )
}